import React, { useRef, useState, useLayoutEffect, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import CarouselComponent from "./caraousel";
import { Link } from "react-router-dom";
import Card from "./cards";

//Styles for Slider
const useStyles = makeStyles((theme) => ({
  // styles for Slider Container
  sliderContainer: {
    position: "relative",
    top: 0,
    left: 0,
    width: "100%",
    height: "fit-content",
    "@media (min-width:1800px)": {
      height: 500,
    },
    overflow: "hidden",
  },
  sliderItemContainer: {
    position: "relative",
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "fit-content",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    scrollbarWidth: "none",
    paddingLeft: 52,
    "@media (max-width:500px)": {
      paddingLeft: 35,
    },
    overflowY: "hidden",
    overflowX: "scroll",
    display: "flex",
    flexDirection: "row",
    backgroundColor: (props) => props.backgroundColor,
    scrollBehavior: "smooth",
  },
  // styles for single item container
  sliderItem: {
    flexBasis: "20%",
    "@media (max-width:1088px)": {
      flexBasis: "30%",
    },
    "@media (max-width:900px)": {
      flexBasis: "35%",
    },
    "@media (max-width:700px)": {
      flexBasis: "40%",
    },
    "@media (max-width:700px)": {
      flexBasis: "50%",
    },
    flexGrow: 0,
    flexShrink: 0,
    position: "relative",
    top: 0,
    left: 0,
    height: "fit-content",
    overflow: "hidden",
    // maxWidth: 220,
    // "@media (min-width:1800px)": {
    //   maxWidth: 320,
    // },
    // minWidth: 150,
    color: "#000000",
    "&:hover": {
      color: "#000000",
    },
  },
  itemContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translateX(-50%) translateY(-50%)",
    width: "95%",
    "@media (min-width:1800px)": {
      maxWidth: 300,
    },
    maxWidth: 200,
    height: "fit-content",
    borderRadius: 5,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    backgroundColor: "#ffffff",
    overflow: "hidden",
  },
  itemContainerImgCont: {
    position: "relative",
    top: 0,
    left: 0,
    width: "100%",
    "&:after": {
      display: "block",
      content: '" "',
      width: "100%",
      paddingTop: "100%",
    },
    backgroundColor: "#ffffff",
    overflow: "hidden",
  },
  itemContainerImg: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "auto",
  },
  itemContainerDetails: {
    position: "relative",
    top: 0,
    left: 0,
    width: "100%",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& h3": {
      fontSize: 17,
    },
    "& p": {
      fontSize: 12,
      marginBottom: 10,
    },
  },

  // styles for previous and next button on the slider
  sliderNextB: {
    position: "absolute",
    top: 0,
    right: 1,
    height: "100%",
    width: 35,
  },
  sliderNextBIcon: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translateY(-50%) translateX(-50%)",
    width: 50,
    height: 50,
    opacity: 1,
    zIndex: 1,
    "@media (max-width:660px)": {
      width: 25,
    },
  },
  sliderPreviousB: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: 35,
    zIndex: 3,
  },
  sliderPreviousBIcon: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translateY(-50%) translateX(-50%)",
    width: 50,
    height: 50,
    opacity: 1,
    zINdex: 1,
    "@media (max-width:660px)": {
      width: 25,
    },
  },
}));

//scrolls the slider for showing posts behind
function PreviousPosts(slider, scrollAmount, sliderScrollPosition) {
  if (slider && slider.current) {
    if (sliderScrollPosition >= 0 && sliderScrollPosition <= slider.current.scrollWidth) {
      sliderScrollPosition = sliderScrollPosition - scrollAmount;
      slider.current.scrollLeft = sliderScrollPosition;
    } else if (sliderScrollPosition < 0) {
      sliderScrollPosition = 0;
      PreviousPosts(slider);
    } else if (sliderScrollPosition > slider.current.scrollWidth) {
      sliderScrollPosition = slider.current.scrollWidth;
      PreviousPosts(slider);
    }
  }
}

// scroll the slider for showing posts ahead
function NextPosts(
  infinitePosts,
  setData,
  postsNumber,
  scrollAmount,
  slider,
  sliderScrollPosition
) {
  // slider.current.scrollLeft = 300;
  if (slider && slider.current) {
    if (sliderScrollPosition >= 0 && sliderScrollPosition <= slider.current.scrollWidth) {
      sliderScrollPosition = sliderScrollPosition + scrollAmount;
      slider.current.scrollLeft = sliderScrollPosition;
    } else if (sliderScrollPosition < 0) {
      sliderScrollPosition = 0;
      NextPosts(infinitePosts, slider);
    } else if (sliderScrollPosition > slider.current.scrollWidth) {
      sliderScrollPosition = slider.current.scrollWidth;
      NextPosts(infinitePosts, slider);
    }
    if (infinitePosts) {
      updateData(setData, postsNumber);
    }
  }
}

//update data for infinite scroll effect
function updateData(setData, postsNumber) {
  //send an api call and get the data then
  // update the current data through setData function
  // which which re-render the DOM. Use postNumber to get
  // the data of needed posts
}

const SliderDisplay = (props) => {
  // assigning default values so we need not specify everthing while
  // using this element
  let propsD = {
    data: [],
    background: "#ffffff",
    post: true,
    infiniteScroll: false,
    carouselImgs: false,
    // the scroll postion so scroll could be updated on button click
    sliderScrollPosition: 0,
  };

  Object.assign(propsD, props);

  let backgroundColor = propsD.background;

  const classes = useStyles({ backgroundColor });
  const theme = useTheme();

  const SliderReference = useRef();
  let sliderValues = SliderReference;

  const [windowWidth, setwindowWidth] = useState(0);
  const [postsNumber, setpostsNumber] = useState(0);
  const [scrollAmount, setscrollAmount] = useState(0);
  const [data, setData] = useState(propsD.data);
  useEffect(() => {
    setData(propsD.data);

    if (SliderReference && SliderReference.current) {
      SliderReference.current.addEventListener("scroll", () => {
        propsD.sliderScrollPosition = SliderReference.current.scrollLeft;
        if (propsD.infiniteScroll) {
          updateData(setData, postsNumber);
        }
      });
      return SliderReference.current.removeEventListener("scroll", () => {
        propsD.sliderScrollPosition = SliderReference.current.scrollLeft;
        if (propsD.infiniteScroll) {
          updateData(setData, postsNumber);
        }
      });
    }
  });
  useLayoutEffect(() => {
    sliderValues = SliderReference;

    function updatingValues() {
      setwindowWidth(window.innerWidth);
      if (windowWidth > 2000) {
        setpostsNumber(5);
        setscrollAmount(900);
      } else if (windowWidth > 1088 && windowWidth < 2000) {
        setpostsNumber(4);
        setscrollAmount(800);
      } else if (windowWidth > 850 && windowWidth < 1088) {
        setpostsNumber(3);
        setscrollAmount(600);
      } else if (windowWidth > 600 && windowWidth < 850) {
        setpostsNumber(3);
        setscrollAmount(400);
      } else if (windowWidth > 0 && windowWidth < 850) {
        setpostsNumber(3);
        setscrollAmount(200);
      }
    }
    updatingValues();
    window.addEventListener("resize", () => {
      updatingValues();
    });
    return window.removeEventListener("resize", () => {
      updatingValues();
    });
  });
  return (
    <div className={classes.sliderContainer}>
      <div ref={SliderReference} className={classes.sliderItemContainer}>
        {data.map((ele) => (
          <div style={{ textDecoration: "none" }} className={classes.sliderItem}>
            {console.log(
              "url",
              ele.productId ? "/buyproduct/" + ele.user.username + "/" + ele.productId : "/"
            )}
            <Card
              to={ele.productId ? "/buyproduct/" + ele.user.username + "/" + ele.productId : "/"}
              data={ele}
              carouselImgs={{
                display: propsD.carouselImgs,
                arrows: true,
                dots: true,
                swipe: false,
                autoSlide: false,
              }}
              post={propsD.post}
            />
            {/* <div className={classes.sliderItem}>
              <div className={classes.itemContainer}>
                <div className={classes.itemContainerImgCont}>
                  {propsD.carouselImgs ? (
                    <CarouselComponent items={ele.images} />
                  ) : (
                    <img
                      className={classes.itemContainerImg}
                      src={ele.image}
                      alt="A Product Image"
                    />
                  )}
                </div>

                <div className={classes.itemContainerDetails}>
                  <h3 style={{width:'100%'}}>{ele.productName}</h3>
                  {propsD.price ? <p>Price : {ele.price}</p> : ''}
                  {propsD.likes.length ? <p> likes :{ele.likes.length}</p> : <p> likes : 0</p>}
                  {propsD.comments ? <p>Comments : {ele.comments}</p> : ''}
                </div>
              </div>
            </div> */}
          </div>
        ))}
      </div>
      <div
        onClick={() => PreviousPosts(sliderValues, scrollAmount, propsD.sliderScrollPosition)}
        class={classes.sliderPreviousB}
      >
        {/* <NavigateBeforeIcon  /> */}
        <svg
          width="29"
          height="29"
          viewBox="0 0 29 29"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={classes.sliderPreviousBIcon}
        >
          <rect width="28.379" height="28.379" rx="5" fill="#EAEAEA" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M17.7068 8.21976C17.8943 8.36052 17.9996 8.5514 17.9996 8.75043C17.9996 8.94945 17.8943 9.14033 17.7068 9.28109L11.4138 14.0045L17.7068 18.728C17.8889 18.8695 17.9897 19.0591 17.9875 19.2559C17.9852 19.4527 17.88 19.641 17.6946 19.7802C17.5092 19.9193 17.2584 19.9983 16.9962 20C16.734 20.0017 16.4814 19.926 16.2928 19.7893L9.29279 14.5352C9.10532 14.3944 9 14.2036 9 14.0045C9 13.8055 9.10532 13.6146 9.29279 13.4739L16.2928 8.21976C16.4803 8.07905 16.7346 8 16.9998 8C17.265 8 17.5193 8.07905 17.7068 8.21976Z"
            fill="#333333"
          />
        </svg>
      </div>
      <div
        onClick={() =>
          NextPosts(
            propsD.infiniteScroll,
            setData,
            postsNumber,
            scrollAmount,
            sliderValues,
            propsD.sliderScrollPosition
          )
        }
        className={classes.sliderNextB}
      >
        {/* <NavigateNextIcon  /> */}
        <svg
          width="29"
          height="29"
          viewBox="0 0 29 29"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={classes.sliderNextBIcon}
        >
          <rect width="28.379" height="28.379" rx="5" fill="#EAEAEA" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.2928 8.21976C11.4803 8.07905 11.7346 8 11.9998 8C12.265 8 12.5193 8.07905 12.7068 8.21976L19.7068 13.4739C19.8943 13.6146 19.9996 13.8055 19.9996 14.0045C19.9996 14.2036 19.8943 14.3944 19.7068 14.5352L12.7068 19.7893C12.5182 19.926 12.2656 20.0017 12.0034 20C11.7412 19.9983 11.4904 19.9193 11.305 19.7802C11.1196 19.641 11.0144 19.4527 11.0121 19.2559C11.0098 19.0591 11.1106 18.8695 11.2928 18.728L17.5858 14.0045L11.2928 9.28109C11.1053 9.14033 11 8.94945 11 8.75043C11 8.5514 11.1053 8.36052 11.2928 8.21976Z"
            fill="#333333"
          />
        </svg>
      </div>
    </div>
  );
};

export default SliderDisplay;
