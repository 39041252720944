import React from "react";
import { Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Navbar } from "../Navbar";

const useStyles = makeStyles({
  btn: {
    marginLeft: "50%",
    transform: "translateX(-50%)",
    marginTop: "3%",
    width: "20%",
    "@media (max-width: 825px)": {
      width: "30%",
    },
    "@media (max-width: 768px)": {
      width: "40%",
    },
    "@media (max-width: 380px)": {
      width: "50%",
    },
  },
  heading: {
    marginLeft: "50%",
    transform: "translateX(-50%)",
    marginTop: "3%",
    width: "20%",
    "@media (max-width: 825px)": {
      width: "30%",
    },
    "@media (max-width: 768px)": {
      width: "40%",
    },
    "@media (max-width: 380px)": {
      width: "50%",
    },
  },
  paymentDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: "15%",
    border: "1px solid grey",
    width: "80%",
    marginLeft: "50%",
    transform: "translateX(-50%)",
    padding: "1%",
    marginBottom: "2%",
    "@media (max-width: 825px)": {
      width: "85%",
    },
    "@media (max-width: 768px)": {
      padding: "3%",
    },
    "@media (max-width: 768px)": {
      marginTop: "5%",
      padding: "5%",
    },
  },
});

const PaymentFailurePage = () => {
  localStorage.removeItem("totalAmount");
  const classes = useStyles();
  return (
    <div>
      <Navbar />
      <div className={classes.paymentDiv}>
        <Typography className={classes.heading} variant="h4" color="primary">
          Something went wrong..
        </Typography>
        <Button variant="contained" color="primary" className={classes.btn}>
          Continue Shopping!
        </Button>
      </div>
    </div>
  );
};

export default PaymentFailurePage;
