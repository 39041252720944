import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "./font/GT-America-Extended-Regular.ttf";
import "./font/GT-America-Bold.ttf";
import DataProvider from "./redux/store";
import { QueryClient, QueryClientProvider } from "react-query";
const queryClient = new QueryClient();
ReactDOM.render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <DataProvider>
      <App />
    </DataProvider>
  </QueryClientProvider>,
  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// del this line
