import React from "react";
import { makeStyles, useTheme } from "@material-ui/core";
import { Navbar } from "../components/Navbar";

const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: "64px",
    height: "100%",
    width: "100%",
  },
  himWrapper: {
    // marginTop: "10%",
    borderBottom: "1px solid black",
    // borderBottom: "1px solid black",
    padding: "1.5%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "GT-America-Bold",
    letterSpacing: "1px",
    height: "100%",
  },
  subcategoryWrapper: {
    padding: "10%",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center",
    alignItems: "center",
  },
  shirtWrapper: {
    border: "1px solid black",
    width: "100%",
    height: "40vh",
    display: "flex",
    justifyContent: "space-between",
  },
  pic: {
    width: "50vw",
    height: "40vh",
    // // left: "686px",
    // // top: "358px",
    backgroundColor: "black",
    // width: "0",
    // height: "0",
    // borderTop: "25px solid transparent",
  },
  picWhite: {
    borderLeft: "10vh solid white",
    borderBottom: "40vh solid transparent",
  },
  contentWrapper: {
    display: "flex",
    alignItems: "flex-end",
    // justifyContent: "flex-end",
    width: "100%",
    flexDirection: "column",
    // width: "50%",
    // overflow: "scroll",
  },
  contentTxt: {
    display: "flex",
    justifyContent: "flex-end",
  },
  line: {
    display: "flex",
    justifyContent: "flex-start",
    borderBottom: "1px solid black",
    width: "50%",
    alignSelf: "end",
  },
  contentBtn: {
    all: "unset",
    display: "flex",
    justifyContent: "flex-end",
    borderBottom: "1px solid black",
    fontFamily: "GT-America-Extended-Regular",
  },
}));

const Him = () => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <div className={classes.main}>
      <Navbar />
      <div className={classes.himWrapper}>HIM</div>
      <div className={classes.subcategoryWrapper}>
        <div className={classes.shirtWrapper}>
          <div className={classes.contentWrapper}>
            <div
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                padding: "1%",
                marginTop: "4%",
              }}
            >
              <div className={classes.contentTxt}>1</div>
              <div className={classes.line}></div>
              <div className={classes.contentTxt}>
                <div style={{ borderBottom: "1px solid black" }}>shirts</div>
              </div>
              <div>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                unknown
              </div>
              <div className={classes.contentTxt}>
                <button className={classes.contentBtn}>shop now</button>
              </div>
            </div>
          </div>
          <div className={classes.pic}>
            <div className={classes.picWhite}></div>
          </div>
        </div>
        <div>t-shirts</div>
        <div>sweatshirt & hoodies</div>
      </div>
    </div>
  );
};

export default Him;
