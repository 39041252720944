import React from "react";

const Player_1 = () => {
  return (
    <div>
      <iframe
        src="https://www.jiosaavn.com/embed/playlist/85481065"
        width="100%"
        height="380"
        frameBorder="0"
        allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
      ></iframe>
    </div>
  );
};

const Player_2 = () => {
  return (
    <div>
      <iframe
        src="https://www.jiosaavn.com/embed/playlist/3810565"
        width="100%"
        height="380"
        frameBorder="0"
        allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
      ></iframe>
    </div>
  );
};

const Player_3 = () => {
  return (
    <div>
      <iframe
        // style="border-radius:12px"
        src="https://open.spotify.com/embed/playlist/2TSQPSWHnC6oBvNVppi6AQ?utm_source=generator"
        width="100%"
        height="352"
        frameBorder="0"
        allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
      ></iframe>
    </div>
  );
};

export const Data = [
  {
    heading: "Light Mood",
    playlist: <Player_1 />,
  },
  {
    heading: "Hindi Vibe",
    playlist: <Player_2 />,
  },
  {
    heading: "Low Feels",
    playlist: <Player_3 />,
  },
];
