import React from "react";
import { makeStyles, useTheme } from "@material-ui/core";
import { width } from "dom-helpers";

import { useHistory } from "react-router-dom";
import ReactGA from "react-ga";
const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    width: "100%",
    // justifyContent: "space-between",
    backgroundColor: "rgba(141, 150, 110, 0.83)",
  },
  mainTxt: {
    color: "#black",
    fontFamily: "'League Spartan', sans-serif;",
    fontSize: "5rem",
    lineHeight: "4.8rem",

    "@media (max-width:1000px)": {
      fontSize: "4rem",
      lineHeight: "4rem",
    },
    "@media (max-width:910px)": {
      fontSize: "3rem",
      lineHeight: "2.8rem",
    },
    "@media (max-width:850px)": {
      fontSize: "2.3rem",
      lineHeight: "2.2rem",
    },
    "@media (max-width:730px)": {
      fontSize: "1.8rem",
      lineHeight: "1.8rem",
    },
    // "@media (max-width:550px)": {
    //   fontSize: "3.5rem",
    //   lineHeight: "2.5rem",
    // },
    "@media (max-width:500px)": {
      fontSize: "1.3rem",
      lineHeight: "1.2rem",
      //   textShadow: "3px 3px 5px  black",
    },
    "@media (max-width:380px)": {
      fontSize: ".8rem",
      lineHeight: ".8rem",
    },
    // "@media (max-width:380px)": {
    //   fontSize: "1.5rem",
    //   lineHeight: "1.2rem",
    // },
  },
  textDiv: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    // width: "70%",
    padding: "4%",
    gap: "4%",
    width: "60%",
  },
  imageDiv: {
    display: "flex",
    width: "40%",
    justifyContent: "flex-end",
  },
  Btn: {
    backgroundColor: "white",
    padding: "10px",
    width: "200px",
    borderRadius: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "'League Spartan', sans-serif;",
    fontSize: "1.3rem",
    cursor: "pointer",

    marginTop: "8%",
    "@media (max-width:810px)": {
      fontSize: "1.1rem",
      width: "160px",
      marginTop: "5%",
    },
    "@media (max-width:550px)": {
      fontSize: "1rem",
      width: "120px",
      marginTop: "7%",
    },
    "@media (max-width:500px)": {
      fontSize: "0.7rem",
      width: "100px",
      marginTop: "8%",
    },
    "@media (max-width:500px)": {
      fontSize: "0.6rem",
      width: "80px",
      marginTop: "8%",
      padding: "7px",
    },
    "@media (max-width:380px)": {
      fontSize: "0.5rem",
      width: "70px",
      marginTop: "9%",
      padding: "5px",
    },
  },
  image: {
    width: "100%",
    height: "auto",
  },
}));

const MaiaShoes = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  function viewmore() {
    history.push("/profile/maiashoesindia");
    ReactGA.event({ category: "homepage", action: "home page prodigicart", label: "HomePage" });
  }
  return (
    <div className={classes.main}>
      <div className={classes.textDiv}>
        <div className={classes.mainTxt}>GO</div>
        <div className={classes.mainTxt}>VEGAN</div>
        <div className={classes.mainTxt}>HEEAALLLSSS!</div>
        <div className={classes.Btn} onClick={viewmore}>
          view more
        </div>
      </div>
      <div className={classes.imageDiv}>
        <img
          src="https://res.cloudinary.com/ayshubham/image/upload/v1675454874/5ae40ef4-3742-44df-81ab-bdbc660ff7d9-1007_1.jpeg_jic1uq.png"
          alt=""
          className={classes.image}
        />
      </div>
    </div>
  );
};

export default MaiaShoes;
