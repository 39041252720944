import { makeStyles, Typography, Button } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { deleteProduct } from "../../redux/actions/profileAction";
import { useSelector, useDispatch } from "react-redux";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: "bold",
  },
  container: {
    width: "95%",
    height: "70%",
    padding: "2%",
    border: "1px solid #C8C6C6",
    borderRadius: "10px",
    marginTop: "3%",
    marginLeft: "3%",
    "@media (max-width:660px)": {
      marginLeft: "0",
      width: "100%",
    },
  },
  displayProductsDiv: {
    width: "100%",
    height: "90%",
    overflow: "scroll",
  },
  products: {
    backgroundColor: "#F8F0DF",
    padding: "1%",
    height: 90,
    marginTop: "1%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  quantity: {
    fontFamily: "poppins, sans-serif",
  },
  image: {
    height: "100%",
    width: "auto",
  },
  btn: {
    backgroundColor: "red",
    color: "white",
    "@media (max-width:660px)": {
      fontSize: "8px",
      fontWeight: "800",
      height: "100%",
    },
  },
}));

const quantity = 20;
const price = 300;

const YourProductsComponent = ({ products, authP }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleClick = (_id) => {
    dispatch(deleteProduct({ products, authP, _id }));
  };
  return (
    <div className={classes.container}>
      <Typography
        className={classes.bold}
        variant="h6"
        style={{ fontFamily: "poppins, sans-serif" }}
      >
        Manage Your Products
      </Typography>

      {products ? (
        <div className={classes.displayProductsDiv}>
          {products.map((item) => {
            {
              console.log("item", item);
            }
            return (
              <div className={classes.products} key={item.productId}>
                <img src={item.images[0]} className={classes.image} />
                <Typography style={{ fontFamily: "poppins, sans-serif" }}>
                  {item.productName}
                </Typography>
                <Typography className={classes.quantity}>
                  {" "}
                  <div>Price</div>{" "}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontWeight: "700",
                    }}
                  >
                    {item.price}
                  </div>
                </Typography>
                {/* <Typography style={{ fontFamily: "poppins, sans-serif" }}>{item.price}</Typography> */}
                <Button
                  variant="contained"
                  disableElevation
                  className={classes.btn}
                  type="submit"
                  onClick={() => handleClick(item.productId)}
                  style={{ fontFamily: "poppins, sans-serif" }}
                >
                  Delete Product
                </Button>
              </div>
            );
          })}
        </div>
      ) : (
        <div>
          <Skeleton />
        </div>
      )}
    </div>
  );
};

export default YourProductsComponent;
