import React from "react";
import { Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Navbar } from "../components/Navbar";
import { AiFillWindows } from "react-icons/ai";
import { useHistory, Link } from "react-router-dom";

const useStyles = makeStyles({
  wrapper: {
    height: "100vh",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  statusWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    justifyContent: "center",
    alignItems: "center",
  },
  btnWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    // margintTop: "10%",
  },
  btn: {
    all: "unset",
    display: "flex",
    border: "1px solid #333333",
    padding: "2%",
    borderRadius: "10px",
    width: "30%",
    justifyContent: "center",
    alignItems: "center",
  },
});

const SuccessPage = () => {
  localStorage.removeItem("totalAmount");
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.wrapper}>
      <Navbar />

      <div>
        <div className={classes.statusWrapper}>
          <div>
            <svg
              width="100"
              height="100"
              viewBox="0 0 100 100"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="50" cy="50" r="50" fill="#C19DFD" />
              <circle cx="50" cy="50" r="40" fill="white" />
              <path
                d="M74.8332 31.0716C74.5024 30.7048 74.1089 30.4137 73.6754 30.215C73.2418 30.0163 72.7768 29.9141 72.3072 29.9141C71.8375 29.9141 71.3725 30.0163 70.9389 30.215C70.5054 30.4137 70.1119 30.7048 69.7812 31.0716L43.276 60.2647L32.1403 47.977C31.7969 47.6121 31.3915 47.3252 30.9473 47.1327C30.5031 46.9401 30.0287 46.8457 29.5514 46.8548C29.074 46.8639 28.6029 46.9763 28.165 47.1856C27.7271 47.395 27.331 47.6971 26.9993 48.0748C26.6676 48.4525 26.4068 48.8984 26.2317 49.387C26.0567 49.8756 25.9708 50.3973 25.9791 50.9224C25.9873 51.4475 26.0895 51.9657 26.2798 52.4473C26.4702 52.929 26.7449 53.3646 27.0883 53.7295L40.75 68.7565C41.0807 69.1233 41.4742 69.4144 41.9078 69.6131C42.3413 69.8118 42.8063 69.9141 43.276 69.9141C43.7456 69.9141 44.2107 69.8118 44.6442 69.6131C45.0778 69.4144 45.4712 69.1233 45.802 68.7565L74.8332 36.8241C75.1943 36.4577 75.4825 36.0129 75.6796 35.5179C75.8767 35.0229 75.9785 34.4883 75.9785 33.9479C75.9785 33.4074 75.8767 32.8729 75.6796 32.3778C75.4825 31.8828 75.1943 31.4381 74.8332 31.0716Z"
                fill="#C19DFD"
              />
            </svg>
          </div>
          <div>Order Confirmed</div>
        </div>
        <div style={{ textAlign: "center", marginTop: "5%", marginBottom: "5%" }}>
          Your order is confirmed. You will receive an order confirmation email /SMS <br /> shortly
          with expected delivery date for your items{" "}
        </div>

        <div className={classes.btnWrapper}>
          <button
            className={classes.btn}
            onClick={(e) => {
              history.push("/");
            }}
          >
            continue shopping
          </button>
          <button
            className={classes.btn}
            onClick={(e) => {
              history.push("/orderhistory");
            }}
          >
            view order
          </button>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
