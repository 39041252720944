import React from "react";

import { makeStyles, useTheme } from "@material-ui/core";

import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  mainHeading: {
    // padding: "5%",
    paddingBottom: "2%",
  },
  headingPadding: {
    padding: "5%",
  },
  newMain: {
    display: "flex",
    gap: "5px",
    // padding: "5%",
    // width: "fit-content",
  },
  frontMain: {
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center",
    alignItems: "center",
  },
  new: {
    fontFamily: " 'Poppins', sans-serif",
    fontSize: "2rem",
    fontWeight: "500",
    letterSpacing: "1px",
    "@media (max-width:600px)": {
      fontSize: "1.2rem",
    },
    // width: "fit-content",
  },
  newborder: {
    width: "50px",
    borderBottom: "2px solid black",
    height: "50%",
  },

  dealImage: {
    height: "250px",
    border: "1px solid #333333",
    borderRadius: "5px",
    "@media (max-width:1000px)": {
      height: "200px",
    },
    "@media (max-width:750px)": {
      height: "150px",
    },
    "@media (max-width:550px)": {
      height: "130px",
    },
    "@media (max-width:450px)": {
      height: "110px",
    },
    "@media (max-width:380px)": {
      height: "100px",
    },
    "@media (max-width:340px)": {
      height: "90px",
    },
    "@media (max-width:307px)": {
      height: "70px",
    },
    "@media (max-width:240px)": {
      height: "60px",
    },
  },
  front: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    zIndex: "1000",
    position: "absolute",
    // paddingLeft: "5%",
    width: "80%",
    "@media (max-width:1000px)": {
      width: "90%",
    },
    "@media (max-width:1000px)": {
      width: "95%",
    },
    "@media (max-width:307px)": {
      width: "98%",
    },
  },
  backGroundPurple: {
    width: "100%",
    backgroundColor: "#C9BFFE",
    height: "170px",
    "@media (max-width:750px)": {
      height: "130px",
    },
    "@media (max-width:500px)": {
      height: "100px",
    },
    "@media (max-width:375px)": {
      height: "60px",
    },
  },
  backGroundWhite: {
    width: "100%",
    backgroundColor: "white",
    height: "130px",
    "@media (max-width:1000px)": {
      height: "80px",
    },
    "@media (max-width:500px)": {
      height: "80px",
    },
    "@media (max-width:450px)": {
      height: "60px",
    },
    "@media (max-width:450px)": {
      height: "60px",
    },
    "@media (max-width:307px)": {
      height: "30px",
    },
  },
}));

const DealsOfTheDay = () => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  return (
    <div>
      <div>
        <div className={classes.mainHeading}>
          <div className={classes.headingPadding}>
            <div className={classes.newMain}>
              <div className={classes.new}> Highlights</div>
              <div>
                <div className={classes.newborder}></div>
              </div>
            </div>
            <div className={classes.new}>Of The Season</div>
          </div>

          <div>
            <div className={classes.frontMain}>
              <div className={classes.front}>
                <div>
                  <img
                    src="https://res.cloudinary.com/ayshubham/image/upload/v1660940174/dad0e443-4d74-4c59-b912-cc9be390d00e1631873987232-Difference-of-Opinion-Women-Tshirts-9531631873986834-4_h8lgil.jpg"
                    alt=""
                    className={classes.dealImage}
                  />
                </div>
                <div>
                  <img
                    src="https://res.cloudinary.com/ayshubham/image/upload/v1660940174/dad0e443-4d74-4c59-b912-cc9be390d00e1631873987232-Difference-of-Opinion-Women-Tshirts-9531631873986834-4_h8lgil.jpg"
                    alt=""
                    className={classes.dealImage}
                  />
                </div>
                <div>
                  <img
                    src="https://res.cloudinary.com/ayshubham/image/upload/v1660940174/dad0e443-4d74-4c59-b912-cc9be390d00e1631873987232-Difference-of-Opinion-Women-Tshirts-9531631873986834-4_h8lgil.jpg"
                    alt=""
                    className={classes.dealImage}
                  />
                </div>
                <div>
                  <img
                    src="https://res.cloudinary.com/ayshubham/image/upload/v1660940174/dad0e443-4d74-4c59-b912-cc9be390d00e1631873987232-Difference-of-Opinion-Women-Tshirts-9531631873986834-4_h8lgil.jpg"
                    alt=""
                    className={classes.dealImage}
                  />
                </div>
              </div>{" "}
            </div>
            <div className={classes.backGround}>
              <div className={classes.backGroundWhite}></div>
              <div className={classes.backGroundPurple}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DealsOfTheDay;
