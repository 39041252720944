import React from "react";

import { makeStyles, useTheme } from "@material-ui/core";

import { useHistory } from "react-router-dom";
import ReactGA from "react-ga";

const useStyles = makeStyles((theme) => ({
  headingPadding: {
    padding: "5%",
    "@media (max-width:280px)": {
      marginBottom: "10%",
    },
  },
  newMain: {
    display: "flex",
    gap: "5px",
    // padding: "5%",
    // width: "fit-content",
  },
  frontMain: {
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center",
    alignItems: "center",
  },
  new: {
    fontFamily: " 'Poppins', sans-serif",
    fontSize: "2rem",
    fontWeight: "500",
    letterSpacing: "1px",
    "@media (max-width:500px)": {
      fontSize: "1rem",
    },

    // width: "fit-content",
  },
  newborder: {
    width: "50px",
    borderBottom: "2px solid black",
    height: "50%",
  },
  // mainDiv: {
  //   height: "50%",
  // },
  redCard: {
    height: "312.5px",
    width: "250px",
    backgroundColor: "red",
    borderRadius: "5px",
    border: "1px solid #333333",
    position: "absolute",
    margin: "12px 0px 0px -12px",
    paddingRight: "12px",
    "@media (max-width:950px)": {
      height: "250px",
      width: "200px",
      // paddingRight: "0px",
      // paddingLeft: "20px",
    },
    "@media (max-width:800px)": {
      height: "200px",
      width: "160px",
    },
    "@media (max-width:650px)": {
      height: "175px",
      width: "140px",
    },
    "@media (max-width:500px)": {
      height: "140px",
      width: "112px",
    },
    "@media (max-width:400px)": {
      height: "112px",
      width: "89.6px",
    },
    "@media (max-width:350px)": {
      height: "89.6px",
      width: "71.68px",
      margin: "8px 0px 0px -8px",
    },
    "@media (max-width:280px)": {
      height: "71.68px",
      width: "57.34px",
      margin: "2px 0px 0px -2px",
    },
  },
  mainDiv: {
    display: "flex",
    justifyContent: "space-evenly",
    // height: "700px",
  },
  image: {
    height: "200px",
    width: "auto",
  },
  secondaryDiv1: {
    height: "312.5px",
    width: "250px",
    backgroundColor: "#FFA999",
    // backgroundColor: "black",
    border: "1px solid #333333",
    borderRadius: "5px",
    position: "absolute",

    "@media (max-width:950px)": {
      height: "250px",
      width: "200px",
    },
    "@media (max-width:800px)": {
      height: "200px",
      width: "160px",
    },
    "@media (max-width:800px)": {
      height: "200px",
      width: "160px",
    },
    "@media (max-width:650px)": {
      height: "175px",
      width: "140px",
    },
    "@media (max-width:500px)": {
      height: "140px",
      width: "112px",
    },
    "@media (max-width:400px)": {
      height: "112px",
      width: "89.6px",
    },
    "@media (max-width:350px)": {
      height: "89.6px",
      width: "71.68px",
    },
    "@media (max-width:280px)": {
      height: "71.68px",
      width: "57.34px",
      margin: "-8px 0px -0px 8px",
    },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  mainImageDiv: {
    height: "350px",
    width: "300px",
    paddingLeft: "10px",
    // backgroundColor: "#FFA999",
    "@media (max-width:950px)": {
      height: "350px",
      width: "214.28px",
    },
    "@media (max-width:800px)": {
      height: "300px",
      width: "200.28px",
    },
    "@media (max-width:650px)": {
      height: "300px",
      width: "160px",
    },
    "@media (max-width:500px)": {
      height: "170px",
      width: "130px",
    },
    "@media (max-width:400px)": {
      height: "150px",
      width: "100px",
    },
    "@media (max-width:400px)": {
      height: "150px",
      width: "90px",
    },
  },
  image: {
    height: "auto",
    width: "70%",
    //   height: "312.5px",
    //   width: "250px",
    //   // backgroundColor: "black",
    //   border: "1px solid #333333",
    //   borderRadius: "5px",
    //   position: "absolute",
    //   "@media (max-width:950px)": {
    //     height: "250px",
    //     width: "200px",
    //   },
    //   "@media (max-width:800px)": {
    //     height: "200px",
    //     width: "160px",
    //   },
    //   "@media (max-width:800px)": {
    //     height: "200px",
    //     width: "160px",
    //   },
    //   "@media (max-width:650px)": {
    //     height: "175px",
    //     width: "140px",
    //   },
    //   "@media (max-width:500px)": {
    //     height: "140px",
    //     width: "112px",
    //   },
    //   "@media (max-width:400px)": {
    //     height: "112px",
    //     width: "89.6px",
    //   },
    //   "@media (max-width:350px)": {
    //     height: "89.6px",
    //     width: "71.68px",
    //   },
    // },
    // mainImageDiv: {
    //   height: "350px",
    //   width: "300px",
    //   paddingLeft: "10px",
    //   "@media (max-width:950px)": {
    //     height: "350px",
    //     width: "214.28px",
    //   },
    //   "@media (max-width:800px)": {
    //     height: "300px",
    //     width: "200.28px",
    //   },
    //   "@media (max-width:650px)": {
    //     height: "300px",
    //     width: "160px",
    //   },
    //   "@media (max-width:500px)": {
    //     height: "170px",
    //     width: "130px",
    //   },
    //   "@media (max-width:400px)": {
    //     height: "150px",
    //     width: "100px",
    //   },
    //   "@media (max-width:400px)": {
    //     height: "150px",
    //     width: "90px",
    //   },
    // display: "flex",
    // justifyContent: "center",
  },
  rootImageDiv1: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  rootImageDiv2: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  rootImageDiv3: {
    display: "flex",
    justifyContent: "center",

    alignItems: "flex-end",
  },
  yellowCard: {
    height: "312.5px",
    width: "250px",
    backgroundColor: "#EAE341",
    borderRadius: "5px",
    border: "1px solid #333333",
    position: "absolute",
    margin: "-12px 0px -0px -12px",
    paddingRight: "12px",
    "@media (max-width:950px)": {
      height: "250px",
      width: "200px",
    },
    "@media (max-width:800px)": {
      height: "200px",
      width: "160px",
    },
    "@media (max-width:650px)": {
      height: "175px",
      width: "140px",
    },
    "@media (max-width:500px)": {
      height: "140px",
      width: "112px",
    },
    "@media (max-width:400px)": {
      height: "112px",
      width: "89.6px",
    },
    "@media (max-width:350px)": {
      height: "89.6px",
      width: "71.68px",
      margin: "-8px 0px -0px -8px",
    },

    "@media (max-width:280px)": {
      height: "71.68px",
      width: "57.34px",
      margin: "-16px 0px -0px -4px",
    },
  },
  orangeCard: {
    height: "312.5px",
    width: "250px",
    backgroundColor: "#DE901C",
    borderRadius: "5px",
    border: "1px solid #333333",
    position: "absolute",
    margin: "-12px 0px -0px 12px",
    paddingRight: "12px",
    "@media (max-width:950px)": {
      height: "250px",
      width: "200px",
    },
    "@media (max-width:800px)": {
      height: "200px",
      width: "160px",
    },
    "@media (max-width:650px)": {
      height: "175px",
      width: "140px",
    },
    "@media (max-width:500px)": {
      height: "140px",
      width: "112px",
    },
    "@media (max-width:400px)": {
      height: "112px",
      width: "89.6px",
    },
    "@media (max-width:350px)": {
      height: "89.6px",
      width: "71.68px",
      margin: "-8px 0px -0px 8px",
    },
    "@media (max-width:280px)": {
      height: "71.68px",
      width: "57.34px",
      margin: "-18px 0px -0px 16px",
    },
  },
  subCard: {
    backgroundColor: "black",
  },
}));

const TodayInspo = () => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  function product1() {
    history.push("/buyproduct/prodigycart/2023-00-24:19:17:48:993/");
    ReactGA.event({
      category: "homepage",
      action: "home page prodigycart",
      label: "2023-00-24:19:17:48:993",
    });
  }
  function product2() {
    history.push("/buyproduct/prodigycart/2023-00-24:19:13:44:103/");
    ReactGA.event({
      category: "homepage",
      action: "home page prodigycart",
      label: "2023-00-24:19:13:44:103",
    });
  }
  function product3() {
    history.push("/buyproduct/prodigycart/2023-00-24:19:31:39:760/");
    ReactGA.event({
      category: "homepage",
      action: "home page prodigycart",
      label: "2023-00-24:19:13:44:103",
    });
  }

  return (
    <div>
      <div className={classes.headingPadding}>
        <div className={classes.newMain}>
          <div className={classes.new}>Spotlight</div>
          <div>
            <div className={classes.newborder}></div>
          </div>
        </div>
        {/* <div className={classes.new}>Can Also Checkout</div> */}
      </div>

      <div className={classes.mainDiv}>
        <div className={classes.mainImageDiv}>
          <div>
            <div className={classes.redCard} style={{ backgroundColor: "#FFC498" }}></div>
            <div className={classes.secondaryDiv1} onClick={product1}>
              <img
                src="https://res.cloudinary.com/ayshubham/image/upload/v1675308181/sarvhhomepage/HumansCryHoodie-Black_300x_1-removebg-preview_rrmzj0.png"
                alt=""
                className={classes.image}
              />
            </div>
          </div>
        </div>

        <div className={classes.mainImageDiv}>
          <div>
            <div className={classes.yellowCard} style={{ backgroundColor: "#FFA999" }}></div>
            <div
              className={classes.secondaryDiv1}
              style={{ backgroundColor: "#FFC498" }}
              onClick={product2}
            >
              <img
                src="https://res.cloudinary.com/ayshubham/image/upload/v1675308327/sarvhhomepage/AlbertStonerHoodie-Black_300x_1-removebg-preview_jvzlhj.png"
                alt=""
                className={classes.image}
              />
            </div>
          </div>
        </div>

        <div className={classes.mainImageDiv}>
          <div>
            <div className={classes.orangeCard} style={{ backgroundColor: "#FFC498" }}></div>
            <div
              className={classes.secondaryDiv1}
              style={{ backgroundColor: "#FFA999" }}
              onClick={product3}
            >
              <img
                src="https://res.cloudinary.com/ayshubham/image/upload/v1675308443/sarvhhomepage/VHSSneakerheadPremiumHoodie-Black_300x_1-removebg-preview_urmv5k.png"
                alt=""
                className={classes.image}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TodayInspo;
