import React from "react";
import { makeStyles, useTheme } from "@material-ui/core";
import { width } from "dom-helpers";

import { useHistory } from "react-router-dom";
import ReactGA from "react-ga";
const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: "#FEFE06",
    height: "50vw",
    display: "flex",
  },
  Btn: {
    backgroundColor: "white",
    padding: "10px",
    width: "200px",
    borderRadius: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "'League Spartan', sans-serif;",
    fontSize: "1.3rem",
    cursor: "pointer",

    marginTop: "8%",
    "@media (max-width:810px)": {
      fontSize: "1.1rem",
      width: "160px",
      marginTop: "5%",
    },
    "@media (max-width:550px)": {
      fontSize: "1rem",
      width: "120px",
      marginTop: "7%",
    },
    "@media (max-width:500px)": {
      fontSize: "0.7rem",
      width: "100px",
      marginTop: "8%",
    },
    "@media (max-width:500px)": {
      fontSize: "0.6rem",
      width: "80px",
      marginTop: "8%",
      padding: "7px",
    },
    "@media (max-width:380px)": {
      fontSize: "0.5rem",
      width: "70px",
      marginTop: "9%",
      padding: "5px",
    },
  },
  txtContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    transform: "translateX(20%)",
  },
  mainTxt: {
    color: "#black",
    fontFamily: "'League Spartan', sans-serif;",
    fontSize: "5rem",
    lineHeight: "4.8rem",
    "@media (max-width:1440px)": {
      fontSize: "4rem",
      lineHeight: "4rem",
    },

    "@media (max-width:1144px)": {
      fontSize: "3rem",
      lineHeight: "2.8rem",
    },
    "@media (max-width:910px)": {
      fontSize: "3rem",
      lineHeight: "2.8rem",
    },
    "@media (max-width:850px)": {
      fontSize: "2.3rem",
      lineHeight: "2.2rem",
    },
    "@media (max-width:730px)": {
      fontSize: "1.8rem",
      lineHeight: "1.8rem",
    },
    // "@media (max-width:550px)": {
    //   fontSize: "3.5rem",
    //   lineHeight: "2.5rem",
    // },
    "@media (max-width:500px)": {
      fontSize: "1.3rem",
      lineHeight: "1.2rem",
      //   textShadow: "3px 3px 5px  black",
    },
    "@media (max-width:380px)": {
      fontSize: ".8rem",
      lineHeight: ".8rem",
    },
    // "@media (max-width:310px)": {
    //   fontSize: ".7rem",
    //   lineHeight: ".7rem",
    // },
    "@media (max-width:275px)": {
      fontSize: ".65rem",
      lineHeight: ".65rem",
    },
    // "@media (max-width:380px)": {
    //   fontSize: "1.5rem",
    //   lineHeight: "1.2rem",
    // },
  },
  image: {
    height: "auto",
    width: "100%",
    "@media (max-width:910px)": {
      width: "80%",
    },
  },
  imageDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "60%",
  },
  secondaryImageDiv: {
    padding: "5%",
    backgroundColor: "white",
    width: "65%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "@media (max-width:730px)": {
      width: "70%",
      padding: "7%",
    },
  },
}));

const ProdigyCart = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  function viewmore() {
    history.push("/profile/prodigycart");
    ReactGA.event({ category: "homepage", action: "home page prodigicart", label: "HomePage" });
  }
  return (
    <div className={classes.main}>
      <div className={classes.imageDiv}>
        <div className={classes.secondaryImageDiv}>
          <img
            src="https://res.cloudinary.com/ayshubham/image/upload/v1675310205/sarvhhomepage/7a08199a-2afe-40c8-9ad7-0960c3942173-minimal-flat-lay-tee-mockup-featuring-a-plain-background-95-el-2022-07-04T222029.110_1024x1024-removebg-preview-3_c7qgdp.png"
            alt=""
            className={classes.image}
          />
        </div>
      </div>
      <div className={classes.txtContainer}>
        <div className={classes.mainTxt}>GET ALL THE</div>
        <div className={classes.mainTxt}>QUIRKINESS</div>
        <div className={classes.mainTxt}>YOU NEED</div>
        <div className={classes.Btn} onClick={viewmore}>
          view more
        </div>
      </div>
    </div>
  );
};

export default ProdigyCart;
