import { Navbar } from "../components/Navbar";
import { Avatar, makeStyles, Typography, Button } from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Ratings from "../components/ProfilePage/Ratings";
import InformationComponent from "../components/Dashboard/Info";
import TopCategoryComponent from "../components/Dashboard/TopCategory";
import YourProductsComponent from "../components/Dashboard/YourProducts";
import RecentOrderComponent from "../components/Dashboard/RecentOrder";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { getProfileUsers } from "../redux/actions/profileAction";
import Address from "../components/Checkout/Address";
import { Helmet } from "react-helmet";

const image =
  "https://thumbor.forbes.com/thumbor/fit-in/416x416/filters%3Aformat%28jpg%29/https%3A%2F%2Fspecials-images.forbesimg.com%2Fimageserve%2F5bb22ae84bbe6f67d2e82e05%2F0x0.jpg%3Fbackground%3D000000%26cropX1%3D627%26cropX2%3D1639%26cropY1%3D129%26cropY2%3D1142";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    background: "linear-gradient(150deg, rgba(240,217,255,1), rgba(191,162,219,1))",
    height: "100vh",
    width: "100vw",
    overflow: "scroll",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  card: {
    backdropFilter: "blur(25px) saturate(200%)",
    background: "#FFFAFA",
    borderRadius: 14,
    border: "1px solid rgba(209, 213, 219, 0.3)",
    height: "70%",
    width: "80%",
    marginTop: 64,
    "@media (max-width:660px)": {
      marginTop: 128,
      width: "100%",
    },
    padding: "2%",
    overflow: "scroll",
  },
  headingCard: {
    backdropFilter: "blur(25px) saturate(200%)",
    background: "#FFFAFA",
    borderRadius: 14,
    border: "1px solid rgba(209, 213, 219, 0.3)",
    height: "100px",
    width: "80%",
    marginTop: 70,
    overflow: "hidden",
    "@media (max-width:660px)": {
      marginTop: 128,
      height: "fit-content",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "10%",
    },
    padding: "10px",
    // overflow: "scroll",
    fontFamily: "poppins, sans-serif",
    paddingLeft: "30px",
    paddingRight: "30px",
  },
  topBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width:660px)": {
      flexDirection: "column",
      gap: "10px",
    },
  },
  inner: {
    display: "flex",
    width: "fit-content",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  nameSection: {
    display: "flex",
    width: "fit-content",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  large: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  addressClass: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    gap: "20px",
    backgroundColor: "white",
    marginTop: "60px",
  },

  innerLeft: {
    width: "80%",
    height: "100%",
  },
  innerRight: {
    width: "20%",
    height: "60%",
    border: "1px solid #C8C6C6",
    borderRadius: "10px",
    padding: "2%",
  },
  container: {
    display: "flex",
    height: "90%",
    width: "100%",
    justifyContent: "center",

    marginTop: "2%",
    alignItems: "center",
  },
  productcontainer: {
    width: "95%",
    height: "45%",
    padding: "2%",
    border: "1px solid #C8C6C6",
    borderRadius: "10px",
    marginTop: "3%",
    marginLeft: "3%",
  },
}));
const SellerDashboard = () => {
  const { auth, profile } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    if (profile.users.username !== auth.user.username) {
      dispatch(getProfileUsers({ users: profile.users, id: auth.user.username, auth }));
    }
  }, [auth, dispatch, profile.users]);
  useEffect(() => {
    try {
      setProducts(profile.product);
    } catch (err) {
      console.log(err);
    }
  }, [profile.product]);
  console.log("products", products);
  return (
    <div>
      <Navbar />
      {auth.user.address &&
      auth.user.city &&
      auth.user.state &&
      auth.user.mobile &&
      auth.user.pincode ? (
        <div className={classes.mainContainer}>
          <Helmet>
            <title>SARVH : DASHBOARD</title>
          </Helmet>
          <div className={classes.headingCard}>
            {" "}
            <div className={classes.topBar}>
              <div className={classes.inner}>
                <DashboardIcon />
                <Typography variant="h4" style={{ fontFamily: "poppins, sans-serif" }}>
                  Dashboard
                </Typography>
              </div>
              {auth.user && (
                <>
                  <div className={classes.nameSection}>
                    <Avatar src={auth.user.avatar} className={classes.large} />
                    <div>
                      <Typography style={{ fontFamily: "poppins, sans-serif" }}>
                        {auth.user.fullname}
                      </Typography>
                      <Ratings />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className={classes.card}>
            <div className={classes.container}>
              <div className={classes.innerLeft}>
                <InformationComponent auth={auth} />
                <YourProductsComponent products={products} authP={auth} />

                {/* <RecentOrderComponent /> */}
              </div>
              {/* <div className={classes.innerRight}>
              <TopCategoryComponent />
            </div> */}
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.addressClass}>
          <div>Please enter your address so that we can create delivery with you</div>
          <Address></Address>
        </div>
      )}
    </div>
  );
};

export default SellerDashboard;
