import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Avatar,
  Button,
  makeStyles,
  Typography,
  TextField,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
// import StarOutlinedIcon from "@material-ui/icons/StarOutlined";
// import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
// import SendIcon from "@material-ui/icons/Send";
import { FormControl, InputGroup } from "react-bootstrap";
// import Footer from "../components/footer";
import { useParams } from "react-router";
import { getDataAPI } from "../utils/fetchData";
// import ToggleButton from "@material-ui/lab/ToggleButton";
// import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Ratings from "../components/ProfilePage/Ratings";
import Linkshare from "../components/BuyProductPage/Linkshare";
import { addToCart, addToWishlist } from "../redux/actions/profileAction";
import { checkPincode } from "../redux/actions/productAction";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { Navbar } from "../components/Navbar";
import LikeButton from "../components/LikeButton";
import MainWhatsNew from "../components/homePage/MainWhatsNew";
import CustomCarousel from "../components/CustomCarousel";
import Swal from "sweetalert2";
// import { palette } from "../components/UploadProductPage/ColorPalette";
// import { ContactsOutlined, PhotoSizeSelectLargeTwoTone } from "@material-ui/icons";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  buyProducts: {
    position: "relative",
    top: 0,
    left: 0,
    width: "100%",
    height: "fit-content",
  },
  buyProductContainer: {
    position: "relative",
    top: 64,
    "@media (max-width:660px)": {
      top: 128,
    },
    left: 0,
    width: "100%",
    height: "fit-content",
  },
  buyProductDetails: {
    position: "relative",
    top: 0,
    left: 0,
    width: "100%",
    height: "fit-content",
    display: "flex",
    flexDirection: "row",
    "@media (max-width:620px)": {
      flexDirection: "column",
      padding: "4%",
    },
    justifyContent: "flex-start",
    marginBottom: "90px",
  },
  imagesContainer: {
    position: "relative",
    top: 0,
    left: 0,
    width: "60%",
    height: "fit-content",
    display: "flex",
    flexDirection: "row-reverse",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    padding: "3%",
  },
  mainImage: {
    position: "relative",
    top: 0,
    left: 0,
    width: "70%",
    paddingLeft: "5%",
    "&:after": {
      display: "block",
      content: '" "',
      width: "100%",
      paddingTop: "100%",
    },
    "& img": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "auto",
      paddingLeft: "5%",
    },
  },
  imagesList: {
    position: "relative",
    top: 0,
    left: 0,
    width: "20%",
    height: "fit-content",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "& img": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "auto",
    },
  },
  subImage: {
    position: "relative",
    top: 0,
    left: 0,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10%",
    "& img": {
      position: "relative",
      top: 0,
      left: 0,
      width: "80%",
      height: "auto",
    },
    "&:hover": {
      border: "1px solid #999999",
    },
  },
  productCarousel: {
    position: "relative",
    top: 0,
    left: 0,
    paddingLeft: "25%",
    paddingRight: "25%",
    "@media (max-width:620px)": {
      paddingLeft: "0",
      paddingRight: "0",
    },
    width: "100%",
    height: "fit-content",
  },
  rightMain: {
    position: "relative",
    top: 0,
    left: 0,
    width: "32%",
    "@media (max-width:620px)": {
      width: "100%",
    },
    margin: "4%",
    marginLeft: "0px",
    height: "fit-content",
    display: "flex",
    flexDirection: "column",
  },
  userDAndL: {
    position: "relative",
    top: 0,
    left: 0,
    width: "100%",
    height: "fit-content",
    display: "flex",
    justifyContent: "flex-start",
    // alignItems: "center",
    flexDirection: "column",
  },
  userDetails: {
    position: "relative",
    top: 0,
    left: 0,
    width: "100%",
    height: "fit-content",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    maxWidth: 230,
    minWidth: 230,
  },
  userAvatarCont: {
    position: "relative",
    top: 0,
    left: 0,
    width: "25%",
    height: "auto",
  },
  userDetailsAU: {
    display: "flex",
    flexDirection: "row",
  },

  sizeButton: {
    color: "black",
    marginLeft: 13,
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    backgroundColor: "white",
    border: "1px solid #AEAEAE",
    color: "#525252",
    fontFamily: "poppins, sans-serif",
  },

  userAvatar: {
    position: "relative",
    top: 0,
    left: 0,
    width: "50px",
    height: "50px",
    display: "flex",
    justifyContent: "center",
    borderRadius: "50%",
    "& img": {
      position: "relative",
      top: 0,
      left: 0,
      width: "50px",
      height: "50px",
      borderRadius: "50%",
    },
  },
  userInfo: {
    marginLeft: 10,
    position: "relative",
    top: 0,
    left: 0,
    width: "fit-content",
    height: "fit-content",
    display: "flex",
    flexDirection: "column",
  },
  icons: {
    position: "relative",
    top: 0,
    left: 0,
    width: "25%",
    height: "fit-content",
    display: "flex",
    flexDirection: "row",
    // justifyContent:'space-around',
    justifyContent: "space-around",
    gap: "1px",
    marginTop: "6%",
    // marginLeft: "16px",
  },
  iconBtn: {
    position: "relative",
    top: 0,
    left: 0,
    margin: 0,
    padding: 0,
    alignItems: "flex-start",
    all: "unset",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    marginLeft: "5px",
    // fontSize: "1.7rem",
    // height: "15px",
  },
  productText: {
    position: "relative",
    top: 0,
    left: 0,
    width: "100%",
    height: "fit-content",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    "& label": {
      position: "relative",
      top: 0,
      left: 0,
      width: "100%",
      height: "fit-content",
      fontSize: "0.7rem",
    },
    "& p": {
      position: "relative",
      top: 0,
      left: 0,
      width: "100%",
      height: "fit-content",
    },
  },
  productDetialsMain: {
    display: "flex",
    // justifyContent: "center",
    alignItems: "center",
    gap: "10px",
  },
  productDetials: {
    marginTop: "5%",
    textDecoration: "underline",
    fontFamily: "poppins, sans-serif",
  },
  cASCategory: {
    position: "relative",
    top: 0,
    left: 0,
    width: "100%",
    height: "fit-content",
    display: "flex",
    flexDirection: "row",
  },
  pCategory: {
    position: "relative",
    top: 0,
    left: 0,
    width: "500%",
    height: "fit-content",
    display: "flex",
    flexDirection: "column",
    marginTop: "4%",
  },
  rateContainer: {
    position: "relative",
    top: 0,
    left: 0,
    width: "100%",
    height: "fit-content",
    display: "flex",
    flexDirection: "row",
    marginTop: "4%",
    justifyContent: "flex-start",
  },
  yourPrice: {
    position: "relative",
    top: 0,
    left: 0,
    width: "fit-content",
    height: "fit-content",
    margin: 0,
    padding: 0,
    fontSize: "1.5rem",
  },
  MRP: {
    position: "relative",
    top: 0,
    left: 0,
    width: "fit-content",
    height: "fit-content",
    margin: 0,
    marginLeft: "4%",
    padding: 0,
    fontSize: "1rem",
    color: "#f88787",
    textDecorationLine: "line-through",
  },
  quantity: {
    position: "relative",
    top: 0,
    left: 0,
    width: "20%",
    height: "fit-content",
    marginTop: "4%",
    all: "unset",
    width: "40px",
    borderBottom: "1px solid black",
  },
  sizeHeading: {
    position: "relative",
    top: 0,
    left: 0,
    width: "100%",
    height: "fit-content",
    fontSize: "0.7rem",
    fontFamily: "poppins, sans-serif",
  },
  addToCart: {
    position: "relative",
    top: 0,
    // left: "50%",
    // transform: "translateX(-50%)",
    width: "fit-content",
    height: "fit-content",
    display: "flex",
    width: "100%",
  },
  pincode: {
    position: "relative",
    top: 0,
    left: 0,
    width: "60%",
    height: "fit-content",
  },
  description: {
    position: "relative",
    top: 0,
    left: 0,
    width: "100%",
    height: "fit-content",
    fontSize: 10,
    fontFamily: "poppins, sans-serif",
  },
  marginTop: {
    marginTop: "4%",
  },
  //old styles
  left: {
    display: "flex",

    justifyContent: "space-around",
  },
  fontSize: {
    fontSize: "1rem",
  },
  bold: {
    fontWeight: "700",
    fontFamily: "'Poppins', sans-serif;",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },

  divider: {
    height: 1,
    marginTop: "6%",
    backgroundColor: "grey",
    marginBottom: "3%",
  },

  btn1: {
    width: "fit-content",
    backgroundColor: "#333333",
    color: "white",
    fontSize: 15,
    // all: "unset",
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
    border: "none",
    width: "40%",
    padding: "2%",
    borderRadius: "5px",
    fontFamily: "poppins, sans-serif",
  },
  btn2: {
    width: "fir-content",
    backgroundColor: "white",
    color: "#333333",
    marginLeft: 20,
    fontSize: 15,
    border: "none",
    width: "40%",
    border: "solid #333333 3px",
    borderRadius: "5px",
    fontFamily: "poppins, sans-serif",
  },
  description: {
    fontSize: 10,
  },
  colorBtn: {
    marginTop: "15px",
  },
  thingsText: {
    transform: "translateX(50%)",
    marginTop: "3%",
    marginLeft: "-5%",
    fontWeight: "bold",
  },
  imageGrid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    justifyItems: "center",
    alignContent: "center",
    height: 500,
    gap: "10px 30px",
  },
  image: {
    height: 300,
    width: 300,
  },
  selectedColorMain: {
    border: "1px solid #B1B1B1",
    width: "28px",
    height: "28px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  selectedColor: {
    // marginBottom: "30px",
    // border: "1px solid",
    // margin: "10px",
    width: "20px",
    height: "20px",
    borderRadius: "50%",
  },

  //

  ProductName: {
    fontFamily: "'Poppins', sans-serif;",
    textTransform: "capitalize",
    // letterSpacing: "1px",
    fontWeight: "500",
    // marginLeft: "5%",
    letterSpacing: "1px",
    color: "#333333",
  },
  ProductPrice: {
    fontFamily: "'Poppins', sans-serif;",
    textTransform: "capitalize",
    // letterSpacing: "1px",
    fontWeight: "500",
    // marginLeft: "5%",
    marginTop: "3%",
    color: "#333333",
    display: "flex",
    gap: "6px",
  },
  ProductRs: {
    fontFamily: "poppins, sans-serif",
    fontWeight: "700",
    fontSize: "1.1rem",
  },
}));
const BuyProductPage = () => {
  const { auth } = useSelector((state) => state);
  const { product } = useSelector((state) => state);
  const dispatch = useDispatch();
  const history = useHistory();

  const { user, id } = useParams();

  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [imgIndex, setImgIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [couponApplied, setCouponApplied] = useState(false);

  const theme = useTheme();
  // const match = window.matchMedia("(max-width:580px").matches;
  const useMediaQuery = (query) => {
    const [matches, setMatches] = useState(false);

    useEffect(() => {
      const media = window.matchMedia(query);
      if (media.matches !== matches) {
        setMatches(media.matches);
      }
      const listener = () => setMatches(media.matches);
      window.addEventListener("resize", listener);
      return () => window.removeEventListener("resize", listener);
    }, [matches, query]);

    return matches;
  };
  const isPhone = useMediaQuery("(min-width: 620px)");

  useEffect(() => {
    const token = localStorage.getItem("firstLogin");
    if (token) {
      if (auth.token) {
        ReactGA.pageview({ path: window.location.pathname, trackerNames: auth.user.username });
      } else {
        ReactGA.pageview({ path: window.location.pathname });
      }
    } else {
      ReactGA.pageview({ path: window.location.pathname });
    }
  }, [auth.token]);

  useEffect(async () => {
    try {
      const res = await getDataAPI(`byproductid/${user}/${id}`, null);
      if (res.data.product) {
        setValues(res.data.product);
        setLoading(true);
      }
    } catch (err) {
      setLoading(false);
      Swal.fire({
        icon: "error",
        text: err,
      });
      console.log(err);
    }
  }, [user, id]);

  const classes = useStyles();

  const [size, setSize] = useState("");
  const [color, setColor] = useState(0);
  const [avbSize, setAvbSize] = useState([]);
  const [sortSize, setSortSize] = useState();
  const [avbColor, setAvbColor] = useState();
  const [pincodeCheck, setPincodeCheck] = useState("none");
  const [quantity, setQuantity] = useState(1);
  const [pin, setPin] = useState("");
  const [data, dataSet] = useState(null);
  const [pininput, setPininput] = useState("");
  const [activeButton, setActiveButton] = useState("");
  const [sellerData, setSellerData] = useState([]);
  // const [activeButtonSize, setActiveButtonSize] = useState("");
  // const [ deliverable, setDeliverable] = useState(false)
  const handleChangeQuantity = (n) => {
    setQuantity(n);
  };
  const handleDetials = () => {
    setOpen(true);
  };
  const handleDetialsClose = (e) => {
    setOpen(false);
  };

  let productId = id;
  let productUser = user;
  const handleAddToCart = () => {
    // dispatch(addToCart({ size, id, quantity, auth }));
    if (!size) {
      Swal.fire({
        icon: "error",
        text: "please select the size of the product",
      });
    } else {
      dispatch(
        addToCart({ size, user: productUser, id: productId, quantity, auth, color: avbColor })
      );
      history.push("/cart");
    }

    const token = localStorage.getItem("firstLogin");
    if (token) {
      ReactGA.event({
        category: `product page ${id}`,
        action: `cart button from product page ${auth.user.username}`,
        value: values.price * quantity,
      });
    } else {
      ReactGA.event({
        category: `product page ${id}`,
        action: `cart button from product page not logged in`,
        value: values.price * quantity,
      });
    }
  };

  const handleConsole = () => {
    console.log(pincodeCheck, "pincodeCheck");
  };

  const handleAddToWishlist = () => {
    // dispatch(addToWishlist({ size, id, quantity, auth }));
    dispatch(addToWishlist({ size, user: productUser, id: productId, quantity, auth }));
    history.push("/cart");
  };

  // useEffect(() => {
  //   async function fetchMyAPI() {
  //     dispatch(checkPincode(pin));
  //     const derliveryRes = await product.availableproduct;
  //     dataSet(derliveryRes);
  //     console.log(pin);

  //     console.warn(data);
  //     if (data) {
  //       if (data.length > 0) {
  //         if (data[0].postal_code) {
  //           if (data[0].postal_code.repl === "y") {
  //             setPincodeCheck("y");
  //           }
  //         }
  //       } else {
  //         setPincodeCheck("nahi");
  //       }
  //     } else {
  //       setPincodeCheck("none");
  //     }
  //   }
  //   // console.warn(product.availableproduct[0].postal_code, "available product");
  //   fetchMyAPI();
  // }, [pin]);
  console.log(user, "user at 686");
  useEffect(() => {
    async function fetchData() {
      try {
        const res = await getDataAPI(`user/${user}`, auth.token);
        setSellerData(res.data.user);
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [user]);
  const handleCheckPincode = async () => {
    // console.log(pin);
    if (couponApplied === false) {
      if (sellerData.coupon != undefined) {
        if (pininput === sellerData.coupon.couponCode) {
          // console.log(Object.keys(sellerData.coupon.discount)[0] === "all");
          if (Object.keys(sellerData.coupon.discount)[0] === "all") {
            const discountPerc = sellerData.coupon.discount.all;
            console.log(discountPerc);
            Swal.fire({
              icon: "success",
              title: "Coupon Applied",
              text: `you have got ${discountPerc}% discount on your purchase`,
            });
            const newPrice = (values.price * discountPerc) / 100;
            values.price = values.price - newPrice;
            setCouponApplied(true);
          } else {
            if (values.price < 1000) {
              const discountPerc = sellerData.coupon.discount.zero;
              console.log(discountPerc);
              Swal.fire({
                icon: "success",
                title: "Coupon Applied",
                text: `you have got ${discountPerc}% discount on your purchase`,
              });
              const newPrice = (values.price * discountPerc) / 100;
              values.price = values.price - newPrice;
              setCouponApplied(true);
            }
            if (values.price > 1000 && values.price < 2000) {
              const discountPerc = sellerData.coupon.discount.thousand;
              console.log(discountPerc);
              Swal.fire({
                icon: "success",
                title: "Coupon Applied",
                text: `you have got ${discountPerc}% discount on your purchase    `,
              });
              const newPrice = (values.price * discountPerc) / 100;
              values.price = values.price - newPrice;
              setCouponApplied(true);
            }
            if (values.price > 2000) {
              const discountPerc = sellerData.coupon.discount.two_thousand;
              console.log(discountPerc);
              Swal.fire({
                icon: "success",
                title: "Coupon Applied",
                text: `you have got ${discountPerc}% discount on your purchase`,
              });
              const newPrice = (values.price * discountPerc) / 100;
              values.price = values.price - newPrice;
              setCouponApplied(true);
            }
          }
          console.log(sellerData);
        } else {
          Swal.fire({
            icon: "error",
            title: "Wrong coupon",
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Coupon is not valid for this seller",
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Coupon already applied",
      });
    }

    // setPin(pininput);

    // dispatch(checkPincode(pin));

    // const deliverable = await product.availableproduct;
    // console.warn(deliverable);
    // if (deliverable.length > 0) {
    //   deliverable.map((item) => {
    //     // console.log(item);
    //     dataSet(item);
    //   });
    // }

    // console.warn(data);

    // const derliveryRes = product.availableproduct;
    // dataSet(derliveryRes);
    // console.log(pin);

    // console.warn(data);
    // if (data) {
    //   if (data.length > 0) {
    //     if (data[0].postal_code) {
    //       if (data[0].postal_code.repl === "y") {
    //         setPincodeCheck("y");
    //       }
    //     }
    //   } else {
    //     setPincodeCheck("nahi");
    //   }
    // } else {
    //   setPincodeCheck("none");
    // }
    // dispatch(addToCart({ size, id, quantity, auth }));

    // console.log(checkPincode);
    // const deliveryRes = await product.availableproduct.delivery_codes;
    // const reader = new FileReader();
    // reader.readAsDataURL(deliveryRes);
    // reader.onloadend = () => {
    //   setData();
    //   console.log(setData);
    // };

    // dispatch(console.log(product.availableproduct.delivery_codes));
    // const step1 = product.availableproduct.delivery_codes;

    // if (Array.isArray(step1)) {
    //   console.log(step1);
    // } else {
    //   console.log("nice ho gaya bhot");
    // }

    // const step1 = await ;
    // console.log(step1);

    // const DeliveryCheck = product.availableproduct.delivery_codes[0].postal_code.repl
    // history.push("/cart");
  };
  // pincode;
  // useEffect(() => {
  //   dispatch(checkPincode(pin));

  //   const deliverable = product.availableproduct;
  //   console.warn(deliverable);
  //   if (deliverable.length > 0) {
  //     deliverable.map((item) => {
  //       // console.log(item);
  //       dataSet(item);
  //     });
  //   }
  // }, [pin]);

  // useEffect(() => {
  //   console.log(
  //     size,
  //     "size",
  //     productUser,
  //     "user",
  //     productId,
  //     "productId",
  //     quantity,
  //     "quantitiy",
  //     avbColor,
  //     "avbColor"
  //   );
  // }, [avbColor, productUser]);
  useEffect(() => {
    if (values.stock) {
      setAvbSize(values.stock[color].stock);
      setAvbColor(values.stock[color].color);

      // console.log(avbColor);
    }
  }, [color, size, values.stock, couponApplied]);

  useEffect(() => {
    if (avbSize) {
      var newArr = Object.keys(avbSize);
      console.log(newArr, "newArr without sorting");
      var newArrRef = ["XXS", "XS", "S", "M", "L", "XL", "XXL", "XXXL", "XXXXL", "XXXXXL"];
      newArr = newArrRef.filter((v) => newArr.includes(v));
      console.log(newArr, "sorted");
      setSortSize(newArr);
    }
  }, [avbSize]);

  const [deliveryCheck, setdeliveryCheck] = useState("");
  // console.log(size);
  // useEffect(() => {
  //   console.log(product.availableproduct === undefined);
  //   if (product.availableproduct === undefined) {
  //     setdeliveryCheck("empty");
  //   } else {
  //     // setdeliveryCheck(product.availableproduct[0].postal_code.repl);
  //     // console.log("product");
  //     setdeliveryCheck("not deliverable");
  //     if (product.availableproduct[0] === undefined) {
  //       setdeliveryCheck("Deliverable");
  //       // alert("delivereable");
  //       console.log("hai bhai");
  //     } else if (product.availableproduct[0].postal_code.repl === "y") {
  //       setdeliveryCheck("Deliverable");
  //       // alert("delivereable");
  //       console.log("hai bhai");
  //     }
  //   }
  // }, [product.availableproduct]);
  // const handleLike = () => {
  //   if (auth.user) {
  //     liked ? setLikes(likes - 1) : setLikes(likes + 1);
  //     liked
  //       ? dispatch(unlikeProduct({user: productUser, id: productId, auth  }))
  //       :     dispatch(likeProduct({ user: productUser, id: productId, auth }));

  //     liked ? setLiked(false) : setLiked(true);
  //   }
  // };
  // const  DeliveryCheck = async { console.log(product.availableproduct.delivery_codes[0].postal_code.repl);}
  // console.log(DeliveryCheck);

  const handleImgClick = (e) => {
    setImgIndex(e.target.attributes.index.nodeValue);
  };

  // (values.stock.map((item)=>{
  //   console.log(item.color)
  //     }))
  //     // if(values.stock)
  //     for(let i=0; i<values.stock; i++){

  //       stockWithColor.color(values.stock.color[i])
  //       console.log(stockWithColor)

  //     }

  const colorValue = [];
  const colorName = [];

  // console.log(...colorValue);
  // console.log(values);

  return (
    <div className={classes.buyProducts}>
      <Navbar />
      {loading && (
        <div className={classes.buyProductContainer}>
          <div className={classes.buyProductDetails}>
            {isPhone && values ? (
              values.images && (
                <div className={classes.imagesContainer}>
                  <Helmet>
                    <title>{"SARVH : " + values.productName}</title>
                    <meta name="description" content={values.productName} />
                  </Helmet>
                  <div className={classes.mainImage}>
                    <img src={values.images && values.images[imgIndex]} />
                  </div>
                  <div className={classes.imagesList}>
                    {values.images &&
                      values.images.map((image, index) => (
                        <div
                          index={index}
                          onClick={(e) => {
                            handleImgClick(e);
                          }}
                          className={classes.subImage}
                        >
                          <img index={index} src={image} alt="product image" />
                        </div>
                      ))}
                  </div>
                </div>
              )
            ) : (
              <div className={classes.productCarousel}>
                <CustomCarousel
                  arrows={true}
                  dots={true}
                  autoSlide={false}
                  swipe={true}
                  to={""}
                  items={values.images}
                />
                {/* <button
                  className={classes.iconBtn}
                  style={{
                    position: "absolute",
                    left: "87%",
                    top: "80%",
                    backgroundColor: "white",
                    borderRadius: "50%",
                    width: "35px",
                    height: "35px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={handleAddToWishlist}
                >
                  <BookmarkBorderIcon />
                </button> */}
              </div>
            )}
            <div className={classes.rightMain}>
              <div className={classes.userDAndL}>
                <div className={classes.userDetails}>
                  <div className={classes.userDetails}>
                    <div className={classes.userDetailsAU}>
                      <Link
                        className={classes.userAvatarCont}
                        to={"/profile/" + values.user.username}
                        style={{ textDecoration: "none" }}
                      >
                        <Avatar
                          src={values.user.avatar}
                          alt="profile image"
                          className={classes.userAvatar}
                        />
                      </Link>

                      <div className={classes.userInfo}>
                        <Link
                          to={"/profile/" + values.user.username}
                          style={{ textDecoration: "none" }}
                        >
                          <Typography
                            className={classes.bold}
                            style={{ fontFamily: "'Poppins', sans-serif;" }}
                          >
                            {values.user.username}
                          </Typography>
                        </Link>
                        <div>
                          <Ratings ratingNum={values.user.rating} />
                        </div>
                      </div>
                      {/* {console.log(values.user.rating, "rating")} */}
                    </div>
                  </div>
                </div>
                <div>
                  <div className={classes.ProductName}>{values.productName}</div>
                  <div className={classes.ProductPrice}>
                    <div className={classes.ProductRs}>Rs</div>
                    <div className={classes.ProductRs}> {values.price}</div>
                  </div>
                  <div>
                    <div className={classes.icons}>
                      {/* {console.log(user, "like user")}
                      {console.log(auth, "like auth")}
                      {console.log(id, "like id")}
                      {console.log(values.likes, "like values.likes")} */}
                      <LikeButton
                        fontSize={"1.6rem"}
                        likesData={values.likes}
                        auth={auth}
                        id={id}
                        user={user}
                      />
                      {/* {console.log(user, id, auth, values.likes, values)}
                      {console.log(values.user)} */}
                      {/* {console.log(isPhone, "phone view check")} */}

                      {/* {console.log(window.matchMedia("(max-width: 500px)").matches, "hi")} */}
                      {/* <button className={classes.iconBtn} onClick={handleAddToWishlist}>
                        <BookmarkBorderIcon />
                      </button> */}
                      <button className={classes.iconBtn}>
                        <Linkshare />
                      </button>
                    </div>
                    <div
                      className={classes.productText}
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        flexDirection: "row",
                        marginTop: "10px",
                      }}
                    >
                      {/* {values.stock.map((color, index) => {
                        // <div
                        //   index={index}
                        //   onClick={(e) => {
                        //     handleImgClick(e);
                        //   }}
                        //   className={classes.subImage}
                        // >
                        // <img index={index} src={image} alt="product image" />
                        // </div>
                        const something = color;
                        return <div>{(color, index)}</div>;
                      })} */}
                      {/* {console.log(size)} */}

                      {/* <label>Colors:</label> */}
                      {/* {values.stock.map((item) => {
                        palette.filter((itm) => {
                          if (itm.name === item.color) {
                            colorValue.push(itm.color);
                            colorName.push(itm.name);
                          }
                        });

                        return (
                          <div>
                            <div
                              style={{
                                display: "flex",
                                flexdirection: "column",
                                gap: "8px",
                                alignItems: "center",
                                fontFamily: "Poppins', sans-serif",
                              }}
                            >
                              {" "}
                              <div
                                style={{
                                  fontFamily: "Poppins, sans-serif",
                                  textTransform: "capitalize",
                                  fontSize: "1rem",
                                  fontWeight: "500",
                                }}
                              >
                                {colorName}
                              </div>
                            </div>
                            <div className={classes.selectedColorMain}>
                              <div
                                className={classes.selectedColor}
                                style={{ backgroundColor: colorValue }}
                              ></div>
                            </div>
                          </div>
                        );
                      })} */}
                    </div>
                    <input
                      className={classes.quantity}
                      type="number"
                      defaultValue={1}
                      inputProps={{ min: 1 }}
                      onChange={(e) => {
                        handleChangeQuantity(e.target.value);
                      }}
                      style={{ marginRight: "13px" }}
                    />
                    {values.stock ? (
                      <div>
                        {values.stock.map((item, i) => {
                          return (
                            <button
                              value={i}
                              key={item}
                              onClick={(e) => {
                                setColor(e.target.value);
                                setActiveButton(item);
                              }}
                              // style={{border: "1px solid black", backgroundColor: "white", padding: "5px", marginLeft: "4px"}}
                              style={{
                                border: activeButton
                                  ? activeButton === item
                                    ? "3px solid black"
                                    : "1px solid grey"
                                  : color === i
                                  ? "3px solid black"
                                  : "1px solid grey",
                                padding: "5px",
                                marginLeft: "4px",
                                // border: "1px solid black",
                                backgroundColor: "white",
                                marginLeft: "5px",
                              }}
                              className={classes.colorBtn}
                            >
                              {item.color}
                            </button>
                          );
                        })}
                      </div>
                    ) : (
                      <div></div>
                    )}

                    <Typography
                      gutterBottom
                      className={`${classes.sizeHeading} ${classes.marginTop}`}
                    >
                      SELECT SIZE
                    </Typography>

                    {sortSize.map((item, i) => {
                      return (
                        <button
                          value={item}
                          onClick={(e) => {
                            setSize(e.target.value);
                          }}
                          key={i}
                          style={{
                            border: size === item ? "3px solid black" : "1px solid grey",
                            padding: "5px",
                            marginLeft: "4px",
                            // border: "1px solid black",
                            backgroundColor: "white",
                          }}
                        >
                          {item}
                        </button>
                      );
                    })}

                    <div className={`${classes.marginTop} ${classes.addToCart}`}>
                      <button
                        className={classes.btn1}
                        onClick={() => {
                          const token = localStorage.getItem("firstLogin");
                          if (token) {
                            if (auth.token) {
                              if (values.price && quantity && user && id && avbColor && size) {
                                localStorage.setItem("totalAmount", values.price * quantity);
                                window.location.href = `/payment`;
                                // localStorage.removeItem("orderFrom");
                                localStorage.setItem("orderFrom", "buyProduct");
                                localStorage.setItem("user", user);
                                localStorage.setItem("ID", id);
                                localStorage.setItem("quantity", quantity);
                                localStorage.setItem("color", avbColor);
                                localStorage.setItem("size", size);
                                localStorage.setItem("totalDelivery", 60);

                                ReactGA.event({
                                  category: `product page ${id}`,
                                  action: `buy now button from product page ${auth.user.username}`,
                                  value: values.price * quantity,
                                });
                              }

                              if (!quantity) {
                                Swal.fire({
                                  icon: "error",
                                  text: "please enter quantity of the product",
                                });
                              }
                              if (!avbColor) {
                                Swal.fire({
                                  icon: "error",
                                  text: "please select colour of the product",
                                });
                              }
                              if (!size) {
                                Swal.fire({
                                  icon: "error",
                                  text: "please select size of the product",
                                });
                              }
                            } else {
                              ReactGA.event({
                                category: `product page ${id}`,
                                action: `buy now button from product page not logged in`,
                                value: values.price * quantity,
                              });
                            }
                          } else {
                            ReactGA.event({
                              category: `product page ${id}`,
                              action: `buy now button from product page not logged in`,
                              value: values.price * quantity,
                            });
                            history.push("/login");
                            Swal.fire({
                              icon: "error",
                              text: "please login first",
                            });
                          }
                        }}
                        // onClick={() => {
                        //   window.alert("Please return and purchange product on launch");
                        // }}
                      >
                        Buy Now
                      </button>
                      <button className={classes.btn2} onClick={handleAddToCart}>
                        Add to Cart
                      </button>
                      {/* <button className={classes.btn2} onClick={handleConsole}>
                        console
                      </button> */}
                    </div>
                    <InputGroup className={`${classes.marginTop} ${classes.pincode}`}>
                      <FormControl
                        placeholder="Coupon code"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        // value={}
                        onChange={(e) => setPininput(e.target.value)}
                        // onClick={() => {
                        //   window.alert("Please return and purchange product on launch");
                        // }}
                        style={{ fontFamily: "poppins, sans-serif" }}
                      />
                      <button
                        onClick={handleCheckPincode}
                        style={{
                          fontFamily: "poppins, sans-serif",
                          fontSize: "1rem",
                          padding: "8px",

                          border: "1px solid #ced4da",
                          padding: "8px",
                          borderLeft: "none",
                          cursor: "pointer",
                          backgroundColor: "white",
                        }}
                      >
                        CHECK
                      </button>
                    </InputGroup>
                  </div>
                  {/* <div>
                    {pincodeCheck === "y" && pincodeCheck != "nahi" ? (
                      <div>derlivery hai</div>
                    ) : (
                      <div>no</div>
                    )}
                    {pincodeCheck === "nahi" && pincodeCheck != "y" ? (
                      <div>nahi delivery</div>
                    ) : (
                      <div>no </div>
                    )}
                  </div> */}
                </div>

                {/* <div className={classes.icons}>
                  <LikeButton
                    fontSize={"1.6rem"}
                    likesData={values.likes}
                    auth={auth}
                    id={id}
                    user={user}
                  />
                  {console.log(user, id, auth, values.likes, values)}
                  {console.log(values.user)}
                  <Button className={classes.iconBtn} onClick={handleAddToWishlist}>
                    <BookmarkBorderIcon />
                  </Button>
                  <Button className={classes.iconBtn}>
                    <Linkshare />
                  </Button>
                </div> */}
              </div>

              {/* <div className={classes.divider}></div> */}

              <div className={classes.productText}>
                <div className={classes.productDetialsMain}>
                  <div className={classes.productDetials}>Product Detials</div>
                  {open ? (
                    <div
                      onClick={handleDetialsClose}
                      style={{ marginTop: "5%", cursor: "pointer" }}
                    >
                      <svg
                        width="14"
                        height="8"
                        viewBox="0 0 14 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.7071 7.70711C13.3166 8.09763 12.6834 8.09763 12.2929 7.70711L7 2.41421L1.70711 7.70711C1.31658 8.09763 0.683417 8.09763 0.292893 7.70711C-0.0976315 7.31658 -0.0976315 6.68342 0.292893 6.29289L6.29289 0.292893C6.68342 -0.0976315 7.31658 -0.0976315 7.70711 0.292893L13.7071 6.29289C14.0976 6.68342 14.0976 7.31658 13.7071 7.70711Z"
                          fill="#333333"
                        />
                      </svg>
                    </div>
                  ) : (
                    <div style={{ marginTop: "5%", cursor: "pointer" }} onClick={handleDetials}>
                      <svg
                        width="14"
                        height="8"
                        viewBox="0 0 14 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.292893  0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L7 5.58579L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893C14.0976 0.683418 14.0976 1.31658 13.7071 1.70711L7.70711 7.70711C7.31658 8.09763 6.68342 8.09763 6.29289 7.70711L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683418 0.292893 0.292893Z"
                          fill="#333333"
                        />
                      </svg>
                    </div>
                  )}{" "}
                </div>
                {/* <label>Product Name</label>
                {console.log(values.productName)}
                <Typography>{values.productName}</Typography> */}
                <div className={classes.cASCategory}>
                  {open ? (
                    <div>
                      <div className={classes.pCategory}>
                        <label style={{ fontFamily: "poppins, sans-serif" }}>
                          Product Category
                        </label>
                        <Typography style={{ fontFamily: "poppins, sans-serif" }}>
                          {values.category}
                        </Typography>
                      </div>
                      <div className={classes.pCategory}>
                        <label style={{ fontFamily: "poppins, sans-serif" }}>
                          Product subCategory
                        </label>
                        <Typography style={{ fontFamily: "poppins, sans-serif" }}>
                          {values.subCategory}
                        </Typography>
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>

              {/* <div className={classes.divider}></div>

              <div className={classes.rateContainer}>
                <Typography variant="h6" className={classes.yourPrice}>
                  Rs. {values.price * quantity}
                </Typography>
                <Typography gutterBottom variant="h6" className={classes.MRP}>
                  Rs. {values.mrp * quantity}
                </Typography>
              </div> */}

              {/* <div className={classes.divider}></div> */}

              {/* <div
                className={classes.productText}
                style={{ display: "flex", flexWrap: "wrap", flexDirection: "row" }}
              >
                <label>Colors:</label>
                {values.stock.map((item) => {
                  palette.filter((itm) => {
                    if (itm.name === item.color) {
                      colorValue = itm.color;
                    }
                  });
                  return (
                    <div>
                      <div
                        className={classes.selectedColor}
                        style={{ backgroundColor: colorValue }}
                      ></div>
                    </div>
                  );
                })}
              </div> */}

              {/* <div className={classes.divider}></div> */}

              {/* <TextField
                className={classes.quantity}
                type="number"
                defaultValue={1}
                inputProps={{ min: 1 }}
                onChange={(e) => {
                  handleChangeQuantity(e.target.value);
                }}
              />
              <Typography gutterBottom className={`${classes.sizeHeading} ${classes.marginTop}`}>
                SELECT SIZE
              </Typography>
              <ToggleButtonGroup exclusive onChange={handleSize} value={size}>
                <ToggleButton value="s">S</ToggleButton>
                <ToggleButton value="m">M</ToggleButton>
                <ToggleButton value="l">L</ToggleButton>
                <ToggleButton value="xl">XL</ToggleButton>
                <ToggleButton value="xxl">XXL</ToggleButton>
              </ToggleButtonGroup> */}

              {/* <div className={classes.divider}></div> */}

              {/* <div className={`${classes.marginTop} ${classes.addToCart}`}>
                <Button size="large" className={classes.btn1}>
                  Buy Now
                </Button>
                <Button size="large" className={classes.btn2} onClick={handleAddToCart}>
                  Add to Cart
                </Button>
              </div>
              <InputGroup className={`${classes.marginTop} ${classes.pincode}`}>
                <FormControl
                  placeholder="Enter Pincode"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  // value={}
                  onChange={(e) => setPin(e.target.value)}
                />
                <Button variant="outlined" onClick={handleCheckPincode}>
                  CHECK
                </Button>
              </InputGroup> */}

              {/* <div>
                {product.availableproduct.delivery_codes[0].postal_code.repl === "y" ? (
                  <div>product deliverable </div>
                ) : (
                  <div>product not deliverable </div>
                )}
              </div> */}
              {/* <div className={classes.divider}></div> */}
              <div>{deliveryCheck}</div>
              <Typography
                className={classes.description}
                style={{ fontFamily: "poppins, sans-serif" }}
              >
                {values.productDescription}
              </Typography>
            </div>
          </div>
          <MainWhatsNew />
          <div style={{ position: "relative", top: 0, left: 0, width: "100%", height: 40 }}></div>
        </div>
      )}
    </div>
  );
};

export default BuyProductPage;
