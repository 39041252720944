import { makeStyles } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { accountsetup } from "../redux/actions/authAction";
import { Navbar } from "../components/Navbar";
import Footer from "../components/footer";
import { validatePassword } from "../helper/validator";
import Swal from "sweetalert2";

const useStyles = makeStyles({
  main: {
    position: "relative",
    top: 100,
    left: "50%",
    transform: "translateX(-50%)",
    width: 400,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    border: "1px solid #2c2c2c",
    padding: "5%",
    marginBottom: 130,
    "@media (max-width:400px)": {
      width: "100%",
    },
  },
  input: {
    position: "relative",
    top: 0,
    left: 0,
    width: "100%",
    margin: "1%",
    fontSize: "1rem",
    padding: "2%",
  },
  label: {
    position: "relative",
    top: 0,
    left: 0,
    width: "100%",
    fontSize: "0.8rem",
    marginTop: "5%",
  },
  button: {
    position: "relative",
    top: 0,
    left: "50%",
    transform: "translateX(-50%)",
    width: "60%",
    fontSize: "0.8rem",
    marginTop: "5%",
    border: "none",
    padding: "2% 0 2% 0",
    color: "#ffffff",
    borderRadius: 5,
    backgroundColor: "#068AF1",
  },
  message: {
    color: "#F97B64",
  },
});
function AccountSetup(prop) {
  const dispatch = useDispatch();
  const { alert } = useSelector((state) => state);
  const email = localStorage.getItem("useremail");
  function onFormSubmit(e) {
    e.preventDefault();
    if (!e.target.pass.value || !validatePassword(e.target.pass.value)) {
      e.target.pass.style.border = "1px solid #F97B64";
      e.target.Cpass.style.border = "1px solid #000000";
      e.target.gender.style.border = "1px solid #000000";
      e.target.username.style.border = "1px solid #000000";
      document.getElementById("message").innerHTML =
        "Enter a Valid password with minimum 4 characters";
    } else if (e.target.pass.value != e.target.Cpass.value) {
      e.target.pass.style.border = "1px solid #F97B64";
      e.target.Cpass.style.border = "1px solid #F97B64";
      e.target.gender.style.border = "1px solid #000000";
      e.target.username.style.border = "1px solid #000000";
      document.getElementById("message").innerHTML = "Password and Confirm Password doesn't match";
    } else if (!e.target.gender.value) {
      e.target.pass.style.border = "1px solid #000000";
      e.target.Cpass.style.border = "1px solid #000000";
      e.target.gender.style.border = "1px solid #F97B64";
      e.target.username.style.border = "1px solid #000000";
      document.getElementById("message").innerHTML = "select a Gender";
    } else if (!e.target.username.value) {
      e.target.pass.style.border = "1px solid #000000";
      e.target.Cpass.style.border = "1px solid #000000";
      e.target.gender.style.border = "1px solid #000000";
      e.target.username.style.border = "1px solid #F97B64";
      document.getElementById("message").innerHTML = "Enter a Username";
    } else {
      dispatch(
        accountsetup({
          email: email,
          username: e.target.username.value,
          password: e.target.pass.value,
          gender: e.target.gender.value,
        })
      );
      if (alert.error) {
        Swal.fire({
          icon: "error",

          text: alert.error,
        });
      }
    }
  }
  const classes = useStyles();
  return (
    <div>
      <Navbar />
      <form
        className={classes.main}
        onSubmit={(e) => {
          onFormSubmit(e);
        }}
      >
        <h2>Finish Login !!</h2>
        <p id="message" className={classes.message}></p>
        <label className={classes.label} for="username">
          Username:
        </label>
        <input
          className={classes.input}
          type="text"
          id="username"
          name="username"
          placeholder="UserName"
        />
        <label className={classes.label} for="pass">
          Password:
        </label>
        <input
          className={classes.input}
          type="password"
          id="pass"
          name="password"
          placeholder="Password"
        />
        <label className={classes.label} for="Cpass">
          Confirm Password:
        </label>
        <input
          className={classes.input}
          type="password"
          id="Cpass"
          name="password"
          placeholder="Confirm Password"
        />
        <label className={classes.label} for="gender">
          Gender:
        </label>
        <select className={classes.input} name="gender" id="gender">
          <option value="">--Select--</option>
          <option value="M">M</option>
          <option value="F">F</option>
        </select>
        <input className={classes.button} type="submit" placeholder="submit" />
      </form>
      <Footer />
    </div>
  );
}

export default AccountSetup;
