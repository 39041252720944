import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import PaytmChecksum from "paytmchecksum";
// import MID_PRO from "../.././Backend";
// import something from "../../../"

import { getDataAPI, postDataAPI } from "../../utils/fetchData";
require("dotenv").config({ path: "../.././.env" });
// const PaytmChecksum = require("./paytmChecksum");
const https = require("https");

export function PaytmButton(orderDetails) {
  const [paymentData, setPaymentData] = useState({
    token: "",
    order: "",
    mid: "",
    amount: "",
  });
  const productAmount = Number(localStorage.getItem("totalAmount"));
  // const devlieryCharge = Number(localStorage.getItem("totalDelivery"));
  const devlieryCharge = 0;
  const amount = productAmount;
  const [loading, setLoading] = useState(false);
  // const newArr = [];
  const [cartValues, setCartValues] = useState([]);
  const [values, setValues] = useState({});
  const { auth } = useSelector((state) => state);

  // const [value, setValue] = useState[[]];
  const orderFrom = localStorage.getItem("orderFrom");
  const history = useHistory();

  const buyOrderDetials = {};

  useEffect(() => {
    initialize();
  }, []);

  const initialize = () => {
    let currDate = new Date();
    let orderId = `ORDER_ID_${currDate.getYear() + 1900}_${
      currDate.getMonth() <= 9 ? "0" + currDate.getMonth() : currDate.getMonth()
    }${currDate.getDate() <= 9 ? "0" + currDate.getDate() : currDate.getDate()}${
      currDate.getHours() <= 9 ? "0" + currDate.getHours() : currDate.getHours()
    }${currDate.getMinutes() <= 9 ? "0" + currDate.getMinutes() : currDate.getMinutes()}${
      currDate.getSeconds() <= 9 ? "0" + currDate.getSeconds() : currDate.getSeconds()
    }${
      currDate.getMilliseconds() <= 9
        ? "0" + currDate.getMilliseconds()
        : currDate.getMilliseconds()
    }`;

    // Sandbox Credentials
    let mid = process.env.REACT_APP_MID_PRO; // Merchant ID
    let mkey = process.env.REACT_APP_Merchant_Key_PRO; // Merhcant Key
    var paytmParams = {};

    paytmParams.body = {
      requestType: "Payment",
      mid: mid,
      websiteName: "DEFAULT",
      orderId: orderId,
      callbackUrl: "https://sarvh.com/success",
      txnAmount: {
        value: amount,
        currency: "INR",
      },
      userInfo: {
        custId: "CUSTID_" + orderId,
      },
    };

    PaytmChecksum.generateSignature(JSON.stringify(paytmParams.body), mkey).then(function (
      checksum
    ) {
      console.log(checksum);
      paytmParams.head = {
        signature: checksum,
      };

      var post_data = JSON.stringify(paytmParams);

      var options = {
        /* for Staging */
        hostname: "securegw.paytm.in",
        /* for Production */

        // hostname: "securegw-stage.paytm.in",

        port: 443,
        path: `/theia/api/v1/initiateTransaction?mid=${mid}&orderId=${orderId}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Content-Length": post_data.length,
        },
      };

      var response = "";
      var post_req = https.request(options, function (post_res) {
        post_res.on("data", function (chunk) {
          response += chunk;
        });
        post_res.on("end", function () {
          console.log("Response: ", response);
          setPaymentData({
            ...paymentData,
            token: JSON.parse(response).body.txnToken,
            order: orderId,
            mid: mid,
            amount: amount,
          });
        });
      });

      post_req.write(post_data);
      post_req.end();
    });
  };

  useEffect(async () => {
    const token = localStorage.getItem("firstLogin");

    if (token) {
      if (!auth.token) {
        history.push("/login");
      }
    } else {
      history.push("/login");
    }
    // let orderFrom = localStorage.getItem("orderFrom");

    let user = localStorage.getItem("user");
    let id = localStorage.getItem("ID");
    if (orderFrom === "buyProduct") {
      try {
        const res = await getDataAPI(`byproductid/${user}/${id}`, null);
        if (res.data.product) {
          setValues(res.data.product);
          console.log(values, "values");
          // setLoading(true);
        }
      } catch (err) {
        // setLoading(false);
        Swal.fire({
          icon: "error",
          text: err,
        });
        console.log(err, "err");
      }
    }
    if (orderFrom === "cart") {
      if (auth.user.cart) {
        console.log(auth.user.cart);
        setCartValues(auth.user.cart);
        console.log(cartValues.length, "cartvalues");
        for (let i = 0; i < cartValues.length; i++) {
          const element = cartValues[i];
          console.log(element);
        }
      }
    }
  }, [auth, auth.user.cart, cartValues]);

  // useEffect(() => {

  //   console.log(buyOrderDetials, "buyOrderDetials");
  // }, [values, cartValues, loading]);

  const makePayment = () => {
    if (
      !auth.user.address &&
      !auth.user.city &&
      !auth.user.state &&
      !auth.user.mobile &&
      !auth.user.pincode
    ) {
      Swal.fire({
        icon: "error",
        title: "Check Address Detials",
        text: "please check your address detials then try again",
      });
    } else {
      console.log(auth);
      console.log(values);
      console.log(process.env, "env");
      setLoading(true);
      var config = {
        root: "",
        flow: "DEFAULT",
        // style: {
        //   bodyBackgroundColor: "#fafafb",
        //   bodyColor: "",
        //   themeBackgroundColor: "#0FB8C9",
        //   themeColor: "#ffffff",
        //   headerBackgroundColor: "#284055",
        //   headerColor: "#ffffff",
        //   errorColor: "",
        //   successColor: "",
        //   card: {
        //     padding: "",
        //     backgroundColor: "",
        //   },
        // },
        data: {
          orderId: paymentData.order,
          token: paymentData.token,
          tokenType: "TXN_TOKEN",
          amount: paymentData.amount /* update amount */,
        },
        payMode: {
          labels: {},
          filter: {
            exclude: [],
          },
          order: ["CC", "DC", "NB", "UPI", "PPBL", "PPI", "BALANCE"],
        },
        website: "DEFAULT",

        merchant: {
          mid: paymentData.mid,
          redirect: false,
        },
        handler: {
          transactionStatus: async function transactionStatus(paymentStatus) {
            console.log("paymentStatus => ", paymentStatus);
            if (paymentStatus.STATUS === "TXN_SUCCESS") {
              if (orderFrom === "buyProduct") {
                const quantity = localStorage.getItem("quantity");
                const color = localStorage.getItem("color");
                const size = localStorage.getItem("size");
                const price = localStorage.getItem("totalAmount");
                console.log(values);
                buyOrderDetials["productId"] = values.productId;
                buyOrderDetials["color"] = color;
                buyOrderDetials["size"] = size;
                buyOrderDetials["orderFrom"] = "buyProduct";
                buyOrderDetials["quantity"] = quantity;
                buyOrderDetials["price"] = price;
                buyOrderDetials["mrp"] = values.mrp;
                buyOrderDetials["productName"] = values.productName;
                buyOrderDetials["order"] = { product: values };
                buyOrderDetials["seller"] = values.seller;
                buyOrderDetials["consumerUsername"] = auth.user.username;
                buyOrderDetials["address"] = auth.user.address;
                buyOrderDetials["city"] = auth.user.city;
                buyOrderDetials["gender"] = auth.user.gender;
                buyOrderDetials["mobile"] = auth.user.mobile;
                buyOrderDetials["email"] = auth.user.email;
                buyOrderDetials["fullname"] = auth.user.fullname;
                buyOrderDetials["pincode"] = auth.user.pincode;
                buyOrderDetials["state"] = auth.user.state;
              }
              if (orderFrom === "cart") {
                buyOrderDetials["cart"] = cartValues;
                buyOrderDetials["orderFrom"] = "cart";
                buyOrderDetials["consumerUsername"] = auth.user.username;
                buyOrderDetials["address"] = auth.user.address;
                buyOrderDetials["gender"] = auth.user.gender;
                buyOrderDetials["mobile"] = auth.user.mobile;
                buyOrderDetials["email"] = auth.user.email;
                buyOrderDetials["fullname"] = auth.user.fullname;
                buyOrderDetials["pincode"] = auth.user.pincode;
                buyOrderDetials["state"] = auth.user.state;
                buyOrderDetials["city"] = auth.user.city;
              }
              console.log("payment is successfull");
              console.log(buyOrderDetials, "after success");

              history.replace("/success");
              window.location.reload();

              const res = await postDataAPI(
                "paymentconfirm",
                { buyOrderDetials, paymentStatus },
                auth.token
              );
              console.log(res);
            } else {
              console.log("payment is not successfull");
            }
            console.log(paymentStatus.STATUS, "STATUS");
            setLoading(false);
          },
          notifyMerchant: function notifyMerchant(eventName, data) {
            console.log("Closed");
            setLoading(false);
          },
        },
      };

      if (window.Paytm && window.Paytm.CheckoutJS) {
        // if (window.Paytm.CheckoutJS.init(config) === undefined) {
        //   Swal.fire({
        //     icon: "error",
        //     text: "please refresh the page and then checkout",
        //   });
        //   window.location.reload();
        // } else {
        // initialze configuration using init method
        window.Paytm.CheckoutJS.init(config)
          .then(function onSuccess() {
            console.log("Before JS Checkout invoke");
            // after successfully update configuration invoke checkoutjs
            window.Paytm.CheckoutJS.invoke();
          })
          .catch(function onError(error) {
            console.log("Error => ", error);
            Swal.fire({
              icon: "error",
              text: "please refresh the page and then checkout",
            });
          });
        // }
      }
    }
  };

  return (
    <div>
      {loading ? (
        <img src="https://res.cloudinary.com/ayshubham/image/upload/v1672785031/Iphone-spinner-2_doxtbb.gif" />
      ) : (
        <button
          onClick={makePayment}
          style={{
            width: "100%",
            border: "none",
            backgroundColor: "black",
            color: "white",
            padding: "3px",
            fontSize: "1rem",
            fontWeight: "600",
          }}
        >
          Pay Now
        </button>
      )}
    </div>
  );
}
