export const palette = [
  { name: "Black", color: "#000000" },
  { name: "Charcoal", color: "#333333" },
  { name: "Dark gray", color: "#4D4D4D" },
  { name: "White", color: "#FFFFFF" },
  { name: "Light gray", color: "#999999" },
  { name: "Gray ", color: "#808080" },
  { name: "Medium dark gray", color: "#666666" },
  { name: "Dark Silver", color: "#B3B3B3" },
  { name: "Silver", color: "#CCCCCC" },
  { name: "Red", color: "#F44E3B" },
  { name: "Rusty red", color: "#D33115" },
  { name: "Burgundy", color: "#9F0500" },
  { name: "Orange", color: "#FE9200" },
  { name: "Dark orange", color: "#E27300" },
  { name: "Amber", color: "#C45100" },
  { name: "Yellow", color: "#FCDC00" },
  { name: "Golden", color: "#FCC400" },
  { name: "Dark gold", color: "#FB9E00" },
  { name: "Lime", color: "#DBDF00" },
  { name: "Dark lime", color: "#B0BC00" },
  { name: "Olive ", color: "#808900" },
  { name: "Light green", color: "#A4DD00" },
  { name: "Green", color: "#68BC00" },
  { name: "Dark green", color: "#194D33" },
  { name: "Sea green", color: "#68CCCA" },
  { name: "Lavender", color: "#E6E6FA" },
  { name: "Emerald", color: "#0C797D" },
  { name: "Sky blue", color: "#73D8FF" },
  { name: "Blue", color: "#009CE0" },
  { name: "Dark blue", color: "#0062B1" },
  { name: "Light purple", color: "#AEA1FF" },
  { name: "Purple", color: "#7B64FF" },
  { name: "Dark purple", color: "#653294" },
  { name: "Light pink", color: "#FDA1FF" },
  { name: "Pink", color: "#FA28FF" },
  { name: "Dark pink", color: "#AB149E" },
];
