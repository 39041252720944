// Infinte scroll code in these comments -----------------------------

// import React, { useState, useEffect } from "react";
// import { useSelector, useDispatch } from "react-redux";

// import { Card, CardActionArea, CardContent } from "@material-ui/core";
// import { Carousel } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import {
//   Button,
//   Divider,
//   Drawer,
//   FormControlLabel,
//   List,
//   makeStyles,
//   Radio,
//   RadioGroup,
//   Typography,
// } from "@material-ui/core";
// import WcIcon from "@material-ui/icons/Wc";
// import GradeIcon from "@material-ui/icons/Grade";
// import LocalMallIcon from "@material-ui/icons/LocalMall";
// import RotateLeftIcon from "@material-ui/icons/RotateLeft";
// import { getAllProducts } from "../../redux/actions/productAction";
// import {
//   useInfiniteQuery,
//   useMutation,
//   useQueryClient,
//   QueryClient,
//   QueryClientProvider,
//   useQuery,
// } from "react-query";
// import { getDataAPI } from "../../utils/fetchData";
// const drawerWidth = 240;
// const useStyles = makeStyles({
//   filterHeading: {
//     marginTop: 75,
//   },
//   drawer: {
//     width: drawerWidth,
//     flexShrink: 0,
//   },
//   drawerPaper: {
//     width: drawerWidth,
//     padding: "1%",
//     backgroundColor: "#F4F9F9",
//   },
//   margin: {
//     marginTop: 50,
//   },
//   container: {
//     display: "flex",
//     height: "fit-content",
//     marginTop: 40,
//     justifyContent: "space-evenly",
//     width: 140,
//   },
//   drawerHeader: {
//     display: "flex",
//     justifyContent: "space-between",
//   },
//   starIcon: {
//     color: "#FFD523",
//   },
//   ProductIcon: {
//     color: "#FB9300",
//   },
//   gendersIcon: {
//     color: "#005A8D",
//   },
//   media: {
//     height: 300,
//     width: 300,
//   },
//   cardContainer: {
//     width: "100%",
//     padding: "3%",
//   },
//   mainContainer: {
//     display: "grid",
//     gridTemplateColumns: "1fr 1fr 1fr 1fr",
//     gap: "20px 10px",
//     marginLeft: 300,
//     marginBottom: "5%",
//     marginTop: 90,
//   },
//   strikeThrough: {
//     textDecorationLine: "line-through",
//   },
// });

// const ProductDisplayComponent = () => {
//   const fetchInfiniteProducts = async ({ pageParam = 1 }) => {
//     console.log("in fetchInfiniteProducts");
//     try {
//       const res = getDataAPI(`/allproducts?page=${pageParam}&limit=9`);

//       return res;
//     } catch (error) {
//       console.group(error);
//     }
//   };
//   const { data, isLoading, isFetching, fetchNextPage, hasNextPage } =
//     useInfiniteQuery("products", fetchInfiniteProducts, {
//       getNextPageParam: (lastPage, pages) => {
//         if (lastPage.data.next) return lastPage.data.next.page;
//         return false;
//       },
//     });
//   if (isLoading) return <p>Loading ...</p>;
//   return (
//     <div>
//       {data && (
//         <>
//           {data.pages.map(item =>
//             item.data.results.map(values => <img src={values.images[0]} />)
//           )}
//           {isFetching && <p>Loading ...</p>}
//           {hasNextPage && <button onClick={fetchNextPage}>Load More</button>}
//         </>
//       )}
//     </div>
//   );
// };

// infinite scroll code ends here -----------------------------------------------------------------

//category page
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import CardsSmallimg from "../CardsSmallimg";
import {
  Button,
  Divider,
  Drawer,
  FormControlLabel,
  List,
  makeStyles,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import WcIcon from "@material-ui/icons/Wc";
import GradeIcon from "@material-ui/icons/Grade";
import categories from "./categories.json";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { AiOutlineCloseCircle } from "react-icons/ai";
import {
  getAllProducts,
  getProductsByCategories,
  getProductsBySearch,
  getUserFeed,
} from "../../redux/actions/productAction";
import { Category, NoEncryption, TramRounded } from "@material-ui/icons";
import CardSkeleton from "../ProfilePage/CardSkeleton";
const drawerWidth = 240;
const useStyles = makeStyles({
  filterHeading: {
    marginTop: 128,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    zIndex: 1,

    "@media (max-width:500px)": {
      width: 210,
    },
    "@media (max-width:610px)": {
      transform: (props) => (props.displayfilter ? "translateX(0%)" : "translateX(-100%)"),
    },
    padding: "1%",
    backgroundColor: "white",
    transition: "transform 0.5s ease-out",
    zIndex: 4,
  },
  margin: {
    marginTop: 128,
  },
  container: {
    display: "flex",
    height: "fit-content",
    marginTop: 40,
    justifyContent: "space-evenly",
    width: 140,
  },
  drawerHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  filtertxt: {
    fontFamily: "Poppins, sans-serif;",
    fontWeight: "500",
    fontSize: "1.2rem",
    "@media (max-width:500px)": {
      fontSize: "1rem",
    },
    "@media (max-width:230px)": {
      fontSize: "0.9rem",
    },
  },
  starIcon: {
    color: "#FFD523",
  },
  ProductIcon: {
    color: "#FB9300",
  },
  gendersIcon: {
    color: "#005A8D",
  },
  media: {
    height: 300,
    width: 300,
  },
  cardContainer: {
    width: "100%",
    padding: "3%",
  },
  mainContainer: {
    position: "sticky",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    "@media (max-width:1080px)": {
      gridTemplateColumns: "1fr 1fr 1fr",
    },
    "@media (max-width:760px)": {
      gridTemplateColumns: "1fr 1fr",
    },
    "@media (max-width:380px)": {
      gridTemplateColumns: "1fr",
    },
    "@media (max-width:610px)": {
      marginLeft: 0,
    },
    gap: "20px 10px",
    marginLeft: 300,
    // marginBottom: "5%",
    marginTop: 64,
    "@media (max-width:660px)": {
      marginTop: 128,
    },
    paddingBottom: "70px",
  },
  mainContainerLoading: {
    display: "grid",

    height: "900px",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    "@media (max-width:1080px)": {
      gridTemplateColumns: "1fr 1fr 1fr",
      height: "1200px",
    },
    "@media (max-width:760px)": {
      gridTemplateColumns: "1fr 1fr",
      height: "1500px",
    },
    "@media (max-width:380px)": {
      gridTemplateColumns: "1fr",
      height: "4000px",
      // justifyContent: "center"
      paddingLeft: "20px",
    },
    "@media (max-width:610px)": {
      marginLeft: 0,
    },
    gap: "20px 10px",
    marginBottom: "5%",
    marginTop: 64,
    marginLeft: "300px",
    "@media (max-width:660px)": {
      marginTop: 128,
    },
  },
  strikeThrough: {
    textDecorationLine: "line-through",
  },
  resetbtn: {
    border: "2px solid #C9BFFE",
    padding: "4px",
    backgroundColor: "white",
    color: "#C9BFFE",
  },
  subCategoryTxt: {
    fontFamily: "Poppins, sans-serif",
    "@media (max-width:510px)": {
      fontSize: "0.9rem",
    },
  },
  noProduct: {
    width: "calc(100% - 240px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    // position: "absolute",
    // right: "0px",
  },

  categoryLabelFilter: {
    fontFamily: "Poppins, sans-serif;",
    fontSize: "0.9rem",
    textTransform: "capitalize",
    "@media (max-width:510px)": {
      fontSize: "0.8rem",
    },
  },

  filterBtn: {
    position: "fixed",
    top: "100px",
    left: "98%",
    transform: "translateX(-100%) translateY(-100%)",
    width: 120,
    padding: "1%",
    border: "none",
    // borderRadius: 15,
    backgroundColor: "#1d19197d",
    zIndex: 100,
    fontSize: "1rem",
    display: "none",
    "@media (max-width:610px)": {
      display: "block",
    },
    color: "white",
    fontFamily: "poppins, sans-serif",
    // letterSpacing: "1px",
  },

  btnMain: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    position: "absolute",
    bottom: "0",
    right: "20px",
    width: "20%",
    // paddingTop: "100px",
    gap: "20px",
    minWidth: "200px",
    // marginTOp: "150vh"
  },
  nextBtn: {
    backgroundColor: "#444857",
    color: "white",
    border: "none",
    width: "40%",
    padding: "10px",
  },

  filterCBtn: {
    position: "relative",
    left: "95%",
    transform: "translateX(-100%)",
    marginBottom: "4%",
    fontSize: "1.5rem",
    fontWeight: "600",
    "@media (min-width:610px)": {
      display: "none",
    },
  },
});

const ProductDisplayComponent = (props) => {
  const { product, auth } = useSelector((state) => state);
  const dispatch = useDispatch();

  const [productArray, setProductArray] = useState([]);
  const [availability, setAvailability] = useState(true);
  const [prevPageArr, setPrevPageArr] = useState([]);
  // const [Gendervalue, ] = React.useState("");
  const [RatingValue, setRatingValue] = React.useState("");
  const [ProductValue, setProductsValue] = React.useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [lastEval, setlastEval] = useState();
  const [displayfilter, setDisplayFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isNextBtn, setNextBtn] = useState(TramRounded);
  const [isPrevBtn, setPrevBtn] = useState(true);
  const [prevPage, setPrevPage] = useState();
  const classes = useStyles({ displayfilter });
  const handleReset = () => {
    setCategory("");
    setSubCategory("");
  };

  const urlParams = new URLSearchParams(window.location.search);
  let categoryParam = 0;
  if (urlParams.get("category")) {
    categoryParam = categories.categories.findIndex(
      (category) => category.name == urlParams.get("category")
    );
    if (categoryParam == -1) {
      categoryParam = 0;
    }
  } else {
    categoryParam = 0;
  }

  let categoryIndex = 0;

  const handleSubCategoryChange = (event) => {
    setSubCategory(event.target.value);
  };
  const handleCategoryChange = (event) => {
    setSubCategory(0);
    setCategory(event.target.value);
  };
  const handleProductChange = (event) => {
    setProductsValue(event.target.value);
  };
  const handleFilter = () => {
    displayfilter ? setDisplayFilter(false) : setDisplayFilter(true);
    console.log("hey there");
  };
  const handleCloseFilter = () => {
    displayfilter ? setDisplayFilter(false) : setDisplayFilter(true);
  };
  useEffect(() => {
    if (urlParams.get("q")) {
      dispatch(getProductsBySearch({ q: urlParams.get("q") }));
    } else {
      dispatch(
        getProductsByCategories({
          category: category
            ? categories.categories[category].name
            : categories.categories[categoryParam].name,
          subCategory:
            subCategory && category
              ? categories.categories[category].subCategories[subCategory].name
              : subCategory
              ? categories.categories[categoryParam].subCategories[subCategory].name
              : "",
        })
      );
    }
    const newArr = [];
    setPrevPageArr(newArr);
  }, [category, subCategory]);
  // useEffect(() => {
  //     dispatch(getUserFeed(auth));
  // },[auth])

  useEffect(() => {
    console.log(product.allproducts);

    try {
      if (product.allproducts) {
        setProductArray(product.allproducts.Items);
        setlastEval(product.allproducts.LastEvaluatedKey);
        // if(lastEval.result === "NP" && lastEval.result1 ==="NP") {
        //   setNextBtn(false);
        // }
      }
      if (product.allproducts.loading === false) {
        setIsLoading(false);
        console.log("ho gaya false");
      }
      if (product.allproducts.Items.length === 0 && product.allproducts.loading === false) {
        console.log("no products");
        setAvailability(false);
      }
    } catch (error) {
      console.log(error);
    }
  }, [
    product.allproducts.Items,
    product.allproducts.LastEvaluatedKey,
    lastEval,
    subCategory,
    category,
  ]);

  useEffect(() => {
    if (props.category) {
      categories.categories.map((category1, i) => {
        if (category1.name == category || category1.name == props.category) {
          categoryIndex = i;
        }
      });
    }
    setIsLoading(true);
    setAvailability(true);
  }, [category, categoryParam, subCategory]);
  // const prevPageBtn12 = () => {
  //   console.log(lastEval, "function called")
  // }

  const nextPage = () => {
    if (!prevPageArr.includes(lastEval)) {
      console.log("true");
      console.log(prevPageArr);
    }
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });

    setIsLoading(true);

    // for loop prePageArr.lenght - 1
    setPrevPageArr([...prevPageArr, lastEval]);
    console.log(prevPageArr, lastEval, "prevPageArr");
    if (urlParams.get("q")) {
      dispatch(getProductsBySearch({ q: urlParams.get("q"), LastEvalVal: lastEval }));
    } else {
      dispatch(
        getProductsByCategories({
          category: category
            ? categories.categories[category].name
            : categories.categories[categoryParam].name,
          subCategory:
            subCategory && category
              ? categories.categories[category].subCategories[subCategory].name
              : subCategory
              ? categories.categories[categoryParam].subCategories[subCategory].name
              : "",
          LastEvalVal: lastEval,
        })
      );
    }

    if (lastEval === undefined) {
      setNextBtn(false);
    }
    // console.log(lastEval, "lastEval", product.allproducts.LastEvaluatedKey, "key")
  };
  useEffect(() => {
    if (lastEval) {
      if (lastEval.result === "NP" && lastEval.result1 === "NP") {
        setNextBtn(false);
      } else {
        setNextBtn(true);
      }
      if (lastEval === "NP") {
        setNextBtn(false);
        console.log("next bunnton false ho gaya", isNextBtn);
      }
      if (lastEval === undefined) {
        setNextBtn(false);
      }

      if (prevPageArr.length === 0) {
        setPrevBtn(false);
      } else {
        setPrevBtn(true);
      }
    }
    console.log("asdfd");
  }, [lastEval, category]);
  // const consoleFun = () => {
  //   console.log(product.allproducts);
  //   setProductArray(product);
  // };

  const prevPageBtn = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    const newArr = [];
    setIsLoading(true);
    if (prevPageArr.length === 1) {
      if (urlParams.get("q")) {
        dispatch(getProductsBySearch({ q: urlParams.get("q") }));
      } else {
        dispatch(
          getProductsByCategories({
            category: category
              ? categories.categories[category].name
              : categories.categories[categoryParam].name,
            subCategory:
              subCategory && category
                ? categories.categories[category].subCategories[subCategory].name
                : subCategory
                ? categories.categories[categoryParam].subCategories[subCategory].name
                : "",
          })
        );
      }

      newArr.length = 0;
      setPrevPageArr(newArr);
      console.log("pressed");
    } else {
      const newLastEval = prevPageArr.length - 2;
      console.log(newLastEval);

      for (let i = 0; i < prevPageArr.length - 1; i++) {
        newArr.push(prevPageArr[i]);
      }
      console.log(prevPageArr);
      setPrevPageArr(newArr);
      console.log(prevPageArr[newLastEval], "prevPageArr with minus");
      console.log(prevPageArr, newArr, "prevPageArr");

      if (urlParams.get("q")) {
        dispatch(
          getProductsBySearch({ q: urlParams.get("q"), LastEvalVal: prevPageArr[newLastEval] })
        );
      } else {
        dispatch(
          getProductsByCategories({
            category: category
              ? categories.categories[category].name
              : categories.categories[categoryParam].name,
            subCategory:
              subCategory && category
                ? categories.categories[category].subCategories[subCategory].name
                : subCategory
                ? categories.categories[categoryParam].subCategories[subCategory].name
                : "",
            LastEvalVal: prevPageArr[newLastEval],
          })
        );
      }
    }
    setNextBtn(true);
  };

  const consoleBtn = () => {
    console.log(lastEval, prevPageArr);
  };

  return (
    <div style={{ position: "sticky" }}>
      {/* <button
        style={{ margin: "100px" }}
        // onClick={() => {
        //   consoleFun();
        // }}
      >
        conosle
      </button> */}
      <div>
        <div>
          <Drawer
            variant="permanent"
            anchor="left"
            style={{ zIndex: 1 }}
            className={classes.drawer}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <List className={classes.filterHeading}>
              <AiOutlineCloseCircle
                className={classes.filterCBtn}
                onClick={() => {
                  handleCloseFilter();
                }}
              />

              <div className={classes.drawerHeader}>
                <div className={classes.filtertxt}>Filter</div>
                <button className={classes.resetbtn} onClick={handleReset}>
                  <RotateLeftIcon />
                  Reset
                </button>
              </div>
              <Divider />
              <div className={classes.container}>
                {/* <WcIcon className={classes.gendersIcon} /> */}
                <div className={classes.subCategoryTxt}>Category -</div>
              </div>
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={category ? parseInt(category) : categoryParam ? parseInt(categoryParam) : 0}
                onChange={handleCategoryChange}
              >
                {categories.categories.map((category, i) => {
                  return (
                    <FormControlLabel
                      className={classes.subCategoryTxt}
                      style={{ fontFamily: "'Poppins', sans-serif" }}
                      value={i}
                      control={<Radio color="default" />}
                      label={<div className={classes.categoryLabelFilter}>{category.name}</div>}
                      labelPlacement="start"
                    />
                  );
                })}
              </RadioGroup>
              <div className={classes.container}>
                {/* <GradeIcon className={classes.starIcon} /> */}
                <div className={classes.subCategoryTxt}>Sub-Category -</div>
              </div>
              <RadioGroup
                aria-label="rating"
                name="rating"
                value={subCategory ? parseInt(subCategory) : ""}
                onChange={handleSubCategoryChange}
              >
                {categories.categories[
                  category ? parseInt(category) : categoryParam ? parseInt(categoryParam) : 0
                ].subCategories.map((subcategory, i) => {
                  return (
                    <FormControlLabel
                      value={i}
                      control={<Radio />}
                      label={<div className={classes.categoryLabelFilter}>{subcategory.name}</div>}
                      labelPlacement="start"
                    />
                  );
                })}
                {/*    <FormControlLabel
                value="Watch"
                control={<Radio />}
                label="Watch"
                labelPlacement="Watch"
              /> */}
              </RadioGroup>
              {/*  <div className={classes.container}>
              <LocalMallIcon className={classes.ProductIcon} />
              <Typography>Products -</Typography>
            </div>
            <RadioGroup
              aria-label="products"
              name="products"
              value={ProductValue}
              onChange={handleProductChange}
            >
              <FormControlLabel
                value="Shoes"
                control={<Radio />}
                label="Shoes"
                labelPlacement="start"
              />
              <FormControlLabel
                value="Watches"
                control={<Radio />}
                label="Watches"
                labelPlacement="start"
              />
              <FormControlLabel
                value="Shirt"
                control={<Radio />}
                label="Shirt"
                labelPlacement="start"
              />
            </RadioGroup> */}
            </List>
          </Drawer>
        </div>

        {isLoading ? (
          <div className={classes.mainContainerLoading}>
            <CardSkeleton /> <CardSkeleton /> <CardSkeleton /> <CardSkeleton /> <CardSkeleton />
            <CardSkeleton /> <CardSkeleton /> <CardSkeleton />
          </div>
        ) : (
          <div>
            {availability ? (
              <div className={classes.mainContainer}>
                {productArray.map(
                  (item, i) => {
                    return (
                      <CardsSmallimg
                        to={"/buyproduct/" + item.user.username + "/" + item.productId + "/"}
                        username={item.user.username}
                        avatar={item.user.avatar}
                        data={item}
                        key={item.productId}
                        carouselImgs={{
                          display: true,
                          arrows: false,
                          dots: true,
                          swipe: true,
                          autoSlide: false,
                        }}
                        post={true}
                      />
                    );
                  }

                  // <Card className={classes.cardContainer} key={i}>
                  //   <CardActionArea>
                  //     {/* <CardMedia
                  //       component="img"
                  //       className={classes.media}
                  //       image={item.images[0]}
                  //       title={item.productName}
                  //     /> */}
                  //     <Carousel>
                  //       {item.images.map(image => (
                  //         <Carousel.Item>
                  //           <Link
                  //             to={"/profile/" + item.user.username}
                  //             style={{ textDecoration: "none" }}
                  //           >
                  //             <img
                  //               src={image}
                  //               className={classes.media}
                  //               alt={"whatsnew"}
                  //             ></img>
                  //           </Link>
                  //         </Carousel.Item>
                  //       ))}
                  //     </Carousel>
                  //     <CardContent>
                  //       <Typography variant="body2" color="textSecondary" component="p">
                  //         Rs. {item.price}
                  //       </Typography>
                  //       <Typography
                  //         className={classes.strikeThrough}
                  //         variant="body2"
                  //         color="textSecondary"
                  //         component="p"
                  //       >
                  //         Rs. {item.mrp}
                  //       </Typography>
                  //     </CardContent>
                  //   </CardActionArea>
                  // </Card>
                )}
              </div>
            ) : (
              <div className={classes.noProduct}>
                <div>no product</div>
              </div>
            )}
            {/* {console.log(productArray, "productArray")} */}
            {/* <button>prev button</button>
        { isNextBtn ? (<button  onClick={() => {
          nextPage();
        }}>next products</button>) :(<div></div>)  
      } */}
          </div>
        )}

        {isLoading ? (
          <div></div>
        ) : (
          <div className={classes.btnMain}>
            {isPrevBtn ? (
              <button
                className={classes.nextBtn}
                onClick={() => {
                  prevPageBtn();
                }}
              >
                {" "}
                Back
              </button>
            ) : (
              <div></div>
            )}
            {isNextBtn ? (
              <button
                className={classes.nextBtn}
                onClick={() => {
                  nextPage();
                }}
              >
                Next
              </button>
            ) : (
              <div></div>
            )}
            {/* 
            <button
              className={classes.nextBtn}
              onClick={() => {
                consoleBtn();
              }}
            >
              {" "}
              console
            </button> */}
          </div>
        )}

        <button
          className={classes.filterBtn}
          onClick={() => {
            handleFilter();
          }}
        >
          Filter
        </button>
      </div>
    </div>
  );
};

export default ProductDisplayComponent;
