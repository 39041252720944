import React, { useState, useRef } from "react";

import { makeStyles, useTheme } from "@material-ui/core";

import { useHistory } from "react-router-dom";
import ReactGA from "react-ga";

const useStyles = makeStyles((theme) => ({
  card1: {
    width: "536.67px",
    height: "603px",
    border: "12px solid black",
    backgroundColor: "#BC96E6",
    position: "absolute",
    marginLeft: "3%",
    marginTop: "4%",
    zIndex: "80",
    // top: "40px",
    "@media (max-width:1380px)": {
      marginLeft: "3.5%",
      marginTop: "4.3%",
    },
    "@media (max-width:1080px)": {
      marginLeft: "3%",
      marginTop: "3.4%",
    },
    "@media (max-width:960px)": {
      marginLeft: "4%",
      marginTop: "5%",
    },
    "@media (max-width:800px)": {
      marginLeft: "5%",
      marginTop: "6%",
    },
    "@media (max-width:740px)": {
      marginLeft: "6%",
      marginTop: "7%",
    },
    "@media (max-width:550px)": {
      marginLeft: "6.5%",
      marginTop: "7.5%",
    },
    "@media (max-width:410px)": {
      marginLeft: "7.5%",
      marginTop: "8.5%",
    },
    "@media (max-width:340px)": {
      marginLeft: "8%",
      marginTop: "9%",
      border: "10px solid black",
    },
    // "@media (max-width:500px)": {
    //   border: "12px solid black",
    // },
  },
  card2: {
    width: "536.67px",
    height: "603px",
    border: "12px solid black",
    backgroundColor: "#BC96E6",
    position: "absolute",
    marginLeft: "4.5%",
    marginTop: "5.7%",
    zIndex: "70",
    "@media (max-width:1380px)": {
      marginLeft: "5%",
      marginTop: "6.3%",
    },
    "@media (max-width:1080px)": {
      marginLeft: "5%",
      marginTop: "5.5%",
    },
    "@media (max-width:960px)": {
      marginLeft: "7%",
      marginTop: "8%",
    },
    "@media (max-width:800px)": {
      marginLeft: "8%",
      marginTop: "9%",
    },
    "@media (max-width:740px)": {
      marginLeft: "9.6%",
      marginTop: "10.5%",
    },
    "@media (max-width:550px)": {
      marginLeft: "10%",
      marginTop: "11%",
    },
    "@media (max-width:410px)": {
      marginLeft: "12.2%",
      marginTop: "13.2%",
    },
    "@media (max-width:340px)": {
      marginLeft: "13.2%",
      marginTop: "14.2%",
      border: "10px solid black",
    },
  },
  card3: {
    // width: "536.67px",
    // height: "603px",
    width: "60%",
    height: "",
    border: "12px solid black",
    backgroundColor: "#F8C1CA",
    position: "absolute",
    marginLeft: "6%",
    marginTop: "7%",
    zIndex: "60",
    "@media (max-width:1380px)": {
      marginLeft: "6.5%",
      marginTop: "7.5%",
    },
    "@media (max-width:1080px)": {
      marginLeft: "7%",
      marginTop: "7.2%",
    },
    "@media (max-width:960px)": {
      marginLeft: "9%",
      marginTop: "10%",
    },
    "@media (max-width:800px)": {
      marginLeft: "10.2%",
      marginTop: "11.2%",
    },
    "@media (max-width:740px)": {
      marginLeft: "12.5%",
      marginTop: "13.5%",
    },
    "@media (max-width:550px)": {
      marginLeft: "13.5%",
      marginTop: "14.5%",
    },
    "@media (max-width:410px)": {
      marginLeft: "16%",
      marginTop: "17%",
    },
    "@media (max-width:340px)": {
      marginLeft: "17.6%",
      marginTop: "18.6%",
      border: "10px solid black",
    },
  },
  image: {
    position: "absolute",
    zIndex: 100,
    border: "12px solid black",
    width: "37%",
    height: "auto",
    "@media (max-width:800px)": {
      marginTop: "2%",
    },
    "@media (max-width:600px)": {
      marginTop: "1%",
    },
    "@media (max-width:340px)": {
      border: "10px solid black",
    },
  },
  main: {
    backgroundColor: "#9A74BA",
    height: "50vw",
    display: "flex",
    flexDirection: "row",
    "@media (max-width:960px)": {
      height: "55vw",
    },
    "@media (max-width:960px)": {
      height: "60vw",
    },
  },
  secondaryContainer: {
    width: "60%",
    justifyContent: "center",
    alignItems: "center",
    padding: "2%",
  },
  thirdClass: {
    width: "40%",
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-start",
    height: "100%",
  },
  text: {
    fontFamily: "'League Spartan', sans-serif;",
    width: "fit-content",
    margin: "0",
    fontSize: "4.5rem",
    lineHeight: "4rem",
    "@media (max-width:650px)": {
      fontSize: "3.5rem",
      lineHeight: "3rem",
    },
    "@media (max-width:530px)": {
      fontSize: "2.5rem",
      lineHeight: "2rem",
    },
    "@media (max-width:400px)": {
      fontSize: "2rem",
      lineHeight: "1.5rem",
    },
    "@media (max-width:320px)": {
      fontSize: "1.5rem",
      lineHeight: "1.5rem",
    },
    "@media (max-width:210px)": {
      fontSize: "1rem",
      lineHeight: "1rem",
    },
  },
  Btn: {
    backgroundColor: "white",
    padding: "10px",
    width: "200px",
    borderRadius: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "'League Spartan', sans-serif;",
    fontSize: "1.3rem",
    cursor: "pointer",
    border: "none",

    marginTop: "8%",
    "@media (max-width:810px)": {
      fontSize: "1.1rem",
      width: "160px",
      marginTop: "5%",
    },
    "@media (max-width:550px)": {
      fontSize: "1rem",
      width: "120px",
      marginTop: "7%",
    },
    "@media (max-width:500px)": {
      fontSize: "0.7rem",
      width: "100px",
      marginTop: "8%",
    },
    "@media (max-width:500px)": {
      fontSize: "0.6rem",
      width: "80px",
      marginTop: "8%",
      padding: "7px",
    },
    "@media (max-width:380px)": {
      fontSize: "0.5rem",
      width: "70px",
      marginTop: "9%",
      padding: "5px",
    },
  },
}));

const GetTheBasics = () => {
  const [size, setSize] = useState({});
  const imageRef = useRef(null);
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  function viewmore() {
    history.push("/profile/smylco");
    ReactGA.event({ category: "homepage", action: "home page smylco", label: "HomePage" });
  }

  return (
    <div className={classes.main}>
      <div className={classes.secondaryContainer}>
        <img
          src="https://res.cloudinary.com/ayshubham/image/upload/v1675208646/sarvhhomepage/Rectangle_820_1_rrwdrf.jpg"
          alt=""
          className={classes.image}
          ref={imageRef}
          onLoad={(event) => {
            setSize({
              height: event.target.height,
              width: event.target.width,
            });
          }}
        />
        {size.height && size.width && (
          <div>
            <div
              className={classes.card1}
              style={{
                width: `calc(${size.width}px + 12px)`,
                height: `calc(${size.height}px + 12px)`,
              }}
            ></div>
            <div
              className={classes.card2}
              style={{
                width: `calc(${size.width}px + 12px)`,
                height: `calc(${size.height}px + 12px)`,
              }}
            ></div>
            <div
              className={classes.card3}
              style={{
                width: `calc(${size.width}px + 12px)`,
                height: `calc(${size.height}px + 12px)`,
              }}
            ></div>
          </div>
        )}
      </div>
      <div className={classes.thirdClass}>
        <div>
          <div className={classes.text}>GET</div>
          <div className={classes.text}>THE</div>
          <div className={classes.text}>BASICS</div>
          <button className={classes.Btn} onClick={viewmore}>
            VIEW MORE
          </button>
        </div>
      </div>
    </div>
  );
};

export default GetTheBasics;
