//User Feed page
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import CardsSmalling from "../CardsSmallimg";
import {
  Button,
  Divider,
  Drawer,
  FormControlLabel,
  List,
  makeStyles,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import WcIcon from "@material-ui/icons/Wc";
import GradeIcon from "@material-ui/icons/Grade";
import categories from "../UploadProductPage/categories.json";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { getUserFeed } from "../../redux/actions/productAction";
import { Category, NoEncryption } from "@material-ui/icons";

const drawerWidth = 240;
const useStyles = makeStyles({
  filterHeading: {
    marginTop: 128,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    zIndex: 1,

    "@media (max-width:500px)": {
      width: 160,
    },
    "@media (max-width:610px)": {
      transform: (props) => (props.displayfilter ? "translateX(0%)" : "translateX(-100%)"),
    },
    padding: "1%",
    backgroundColor: "#F4F9F9",
    transition: "transform 0.5s ease-out",
    zIndex: 4,
  },
  margin: {
    marginTop: 128,
  },
  container: {
    display: "flex",
    height: "fit-content",
    marginTop: 40,
    justifyContent: "space-evenly",
    width: 140,
  },
  drawerHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  starIcon: {
    color: "#FFD523",
  },
  ProductIcon: {
    color: "#FB9300",
  },
  gendersIcon: {
    color: "#005A8D",
  },
  media: {
    height: 300,
    width: 300,
  },
  cardContainer: {
    width: "100%",
    padding: "3%",
  },
  mainContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    "@media (max-width:1080px)": {
      gridTemplateColumns: "1fr 1fr 1fr",
    },
    "@media (max-width:760px)": {
      gridTemplateColumns: "1fr 1fr",
    },
    "@media (max-width:380px)": {
      gridTemplateColumns: "1fr",
    },
    "@media (max-width:610px)": {
      marginLeft: 0,
    },
    gap: "20px 10px",
    marginLeft: 300,
    marginBottom: "5%",
    marginTop: 64,
    "@media (max-width:660px)": {
      marginTop: 128,
    },
  },
  strikeThrough: {
    textDecorationLine: "line-through",
  },
  filterBtn: {
    position: "fixed",
    top: "98%",
    left: "98%",
    transform: "translateX(-100%) translateY(-100%)",
    width: 120,
    padding: "1%",
    border: "none",
    borderRadius: 15,
    backgroundColor: "#cecece",
    zIndex: 3,
    fontSize: "1rem",
    display: "none",
    "@media (max-width:610px)": {
      display: "block",
    },
  },

  filterCBtn: {
    position: "relative",
    left: "95%",
    transform: "translateX(-100%)",
    marginBottom: "4%",
    fontSize: "1.5rem",
  },
});

const FeedDisplayComponent = (props) => {
  const { product, auth } = useSelector((state) => state);
  const dispatch = useDispatch();

  const [productArray, setProductArray] = useState([]);
  // const [Gendervalue, ] = React.useState("");
  const [RatingValue, setRatingValue] = React.useState("");
  const [ProductValue, setProductsValue] = React.useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [products, setProducts] = React.useState([]);
  const [displayfilter, setDisplayFilter] = useState(false);
  const classes = useStyles({ displayfilter });

  const urlParams = new URLSearchParams(window.location.search);
  let categoryParam = 0;
  if (urlParams.get("category")) {
    categoryParam = categories.categories.findIndex(
      (category) => category.name == urlParams.get("category")
    );
    if (categoryParam == -1) {
      categoryParam = 0;
    }
  } else {
    categoryParam = 0;
  }

  let categoryIndex = 0;

  useEffect(() => {
    dispatch(getUserFeed(auth));
  }, [auth]);

  useEffect(() => {
    console.log(product.allproducts);
    try {
      if (product.allproducts) {
        setProductArray(product.allproducts);
      }
    } catch (error) {
      console.log(error);
    }
  }, [product.allproducts]);

  useEffect(() => {
    if (props.category) {
      categories.categories.map((category1, i) => {
        if (category1.name == category || category1.name == props.category) {
          categoryIndex = i;
        }
      });
    }
  }, [category, categoryParam]);
  return (
    <div>
      <div className={classes.mainContainer}>
        {productArray.map((item, i) => {
          console.log(item, i);
          return (<div>
            <CardsSmalling
              to={"/buyproduct/" + item.user.username + "/" + item.productId + "/"}
              username={item.user.username}
              avatar={item.user.avatar}
              data={item}
              carouselImgs={{
                display: true,
                arrows: false,
                dots: true,
                swipe: true,
                autoSlide: false,
              }}
              post={true}
            />
            {console.log(item.user)}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FeedDisplayComponent;
