import React from "react";
import "./Style.css";

// &nbsp; use this to add space

const Marquee = (props) => {
  return (
    <div>
      <div class="marquee">
        <div style={{ fontSize: props.marqueeSize }}>{props.marqueeText}</div>
      </div>
    </div>
  );
};

export default Marquee;
