import React from "react";
import { makeStyles, useTheme } from "@material-ui/core";
import { width } from "dom-helpers";

import { useHistory } from "react-router-dom";
import ReactGA from "react-ga";
const useStyles = makeStyles((theme) => ({
  image: {
    width: "100%",
    height: "auto",
    cursor: "pointer",
  },
}));

const Projectamaterasu = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  function viewmore() {
    history.push("/profile/projectamaterasu");
    ReactGA.event({
      category: "homepage",
      action: "home page projectamaterasu",
      label: "HomePage",
    });
  }
  return (
    <div onClick={viewmore}>
      <img
        src="https://res.cloudinary.com/ayshubham/image/upload/v1675430852/WHAT_A_DRAG-3-2_dgng2s.png"
        alt=""
        className={classes.image}
      />
    </div>
  );
};

export default Projectamaterasu;
