import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import HomepageCarouselComponent from "../components/homePage/HomepageCaraousel";
import MainContainerNewProducts from "../components/homePage/MainWhatsNew";
import MainContainerCategories from "../components/homePage/MainCategories";
import MainContainerTrendingProducts from "../components/homePage/MainTrending";
import Footer from "../components/footer";
import { Navbar } from "../components/Navbar";
import Card from "../components/cards";
import CategoriesComponent from "../components/homePage/category";
import { width } from "dom-helpers";

import DealsOfTheDay from "../components/homePage/DealsOfTheDay";
import React, { useState, Suspense } from "react";
import Playlist from "../components/WebPlayer/Player";
import Marquee from "../components/marquee/Marquee";
import NewArrivals from "../components/homePage/newArrivals";
import TodayInspo from "../components/homePage/TodayInspo";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
// import { useSelector } from "react-redux";
import ReactGA from "react-ga";
import DiscoverFashion from "../components/homePage/DiscoverFashion";
import StudioOjas from "../components/homePage/StudioOjas";
import EyeCatcher from "../components/homePage/EyeCatcher";
import GetTheBasics from "../components/homePage/GetTheBasics";
import ProdigyCart from "../components/homePage/ProdigyCart";
import MaiaShoes from "../components/homePage/MaiaShoes";
import Projectamaterasu from "../components/homePage/Projectamaterasu";

const SelectGenderComp = React.lazy(() => import("../components/homePage/SelectGender"));
const useStyles = makeStyles({
  marginTop: {
    marginTop: "2%",
  },
  pinkColor: {
    color: "#E53F3F",
  },
  mainDivTop: {
    backgroundColor: "#000000",
  },
  carousel: {
    backgroundColor: "#E53F3F",
  },
  home: {
    width: "100%",
    height: "100%",
    overflow: "scroll",
    position: "absolute",
    top: 0,
    left: 0,
  },
  upperdiv: {
    marginTop: "64px",
  },
});

const offer = new Date();
const day = offer.getDay();

//temperory data array `itemsData` do delete incase you find it
let itemData = [
  "https://sc04.alicdn.com/kf/HTB1DcsXLpXXXXcTXpXXq6xXFXXX8.jpg",
  "https://assets.ajio.com/medias/sys_master/root/20210408/HiMy/606e0787f997dd7b64a6eeb5/-473Wx593H-441119753-mediumblue-MODEL.jpg",
  "https://assets.ajio.com/medias/sys_master/root/20201031/UXvA/5f9c609df997dd8c83800bb8/-473Wx593H-441105682-olive-MODEL.jpg",
  "https://assetscdn1.paytm.com/images/catalog/product/F/FO/FOOSMOKY-TRENDYSMOK381955669A9D8/1622965634045_0..jpg",
];

const Home = () => {
  const classes = useStyles();
  const { auth } = useSelector((state) => state);
  useEffect(() => {
    const token = localStorage.getItem("firstLogin");
    if (token) {
      if (auth.token) {
        ReactGA.pageview({ path: window.location.pathname, trackerNames: auth.user.username });
      } else {
        ReactGA.pageview({ path: window.location.pathname });
      }
    } else {
      ReactGA.pageview({ path: window.location.pathname });
    }
  }, [auth.token]);
  // const [show, setShow] = useState(true);

  return (
    <div className={classes.home}>
      <Helmet>
        <title>SARVH</title>
      </Helmet>
      <div className={classes.upperdiv}></div>
      <Navbar />
      {/* <CategoriesComponent /> */}

      <HomepageCarouselComponent />

      <Marquee marqueeSize="1.5rem" marqueeText="WELCOME TO SARVH" />
      <NewArrivals></NewArrivals>
      {/* <MainContainerNewProducts /> */}
      <Suspense fallback={<div>Loading...</div>}>
        <SelectGenderComp />
      </Suspense>
      <Playlist />
      <DiscoverFashion />
      <StudioOjas />
      <EyeCatcher />
      <br />
      <GetTheBasics />
      <TodayInspo />
      <ProdigyCart />
      <br />
      {/* <DealsOfTheDay /> */}

      <MaiaShoes />
      <br />
      <Projectamaterasu />
      {console.log(process.env.NODE_ENV, "process.env.NODE_ENV")}

      {/* {day === 6 ? <div>firday</div> : <div></div>} */}

      {/* <MainContainerCategories /> */}
      {/* <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            fontFamily: "'Poppins', sans-serif",
            fontSize: "2rem",
            paddingLeft: "10%",
            paddingRight: "10%",
            fontWeight: "600",
          }}
        >
          Hello
        </div>
        <div
          style={{
            fontFamily: "'Poppins', sans-serif",
            fontSize: "2rem",
            paddingLeft: "10%",
            paddingRight: "10%",
            textAlign: "center",
            fontWeight: "600",
          }}
        >
          This website is currently open for seller only, you are free to roam around and give
          feedback to us.
        </div>
      </div> */}

      {/* <div className={classes.homeanddecor}>
        <img
          src="https://res.cloudinary.com/ayshubham/image/upload/v1637927231/sarvh_beta/Group_18-min_obfnyz.png"
          alt=""
          style={{ width: "100%", marginTop: "10px", cursor: "pointer" }}
        />
      </div> */}
      {/* <MainContainerTrendingProducts /> */}

      <Footer />
    </div>
  );
};

export default Home;
