import { makeStyles } from "@material-ui/core";
import React from "react";
import FeedDisplayComponent from "../components/CategoriesPages/userFeedDisplay";
import Footer from "../components/footer";
import { Navbar } from "../components/Navbar";

const useStyles = makeStyles({
  centerAlign: {
    textAlign: "center",
    marginTop: 64,
    "@media (max-width:660px)": {
      marginTop: 128,
    },
  },
});

const UserFeed = () => {
  return (
    <div>
      <Navbar />
      <div>
        <FeedDisplayComponent />
      </div>
    </div>
  );
};

export default UserFeed;
