import React from "react";
import { makeStyles, useTheme } from "@material-ui/core";
import { width } from "dom-helpers";

import { useHistory } from "react-router-dom";
import ReactGA from "react-ga";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "5%",
    flexDirection: "column",
  },
  heading: {
    fontSize: "2rem",
    fontFamily: "poppins,  sans-serif",
    fontWeight: "500",
    letterSpacing: "2.3px",
    wordSpacing: "5px",
    marginBottom: " 2%",
    "@media (max-width:700px)": {
      fontSize: "1.4rem",
    },
    "@media (max-width:350px)": {
      fontSize: "1.1rem",
    },
  },
  productContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-around",
  },
  productCard: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  productName: {
    fontFamily: "poppins, sans-serif",
    fontSize: "1.1rem",
    marginTop: "10px",
    "@media (max-width:1200px)": {
      fontSize: "1rem",
    },
    "@media (max-width:700px)": {
      fontSize: "0.9rem",
    },
    "@media (max-width:700px)": {
      fontSize: "0.7rem",
    },
    "@media (max-width:500px)": {
      fontSize: "0.5rem",
      textAlign: "center",
    },
    "@media (max-width:400px)": {
      fontSize: "8px",
      textAlign: "center",
    },
  },
  productImg: {
    height: "auto",
    width: "100%",
    "@media (max-width:1200px)": {
      width: "80%",
    },
  },
}));

const DiscoverFashion = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  function productclick1() {
    history.push("/buyproduct/smylco/2023-00-26:11:26:10:830/");
    ReactGA.event({
      category: "Home Page product",
      action: "product 1 click",
      label: "HomePage",
    });
  }
  function productclick2() {
    history.push("/buyproduct/vibethehype/2023-01-27:03:58:43:145/");
    ReactGA.event({
      category: "Home Page product",
      action: "product 2 click",
      label: "HomePage",
    });
  }
  function productclick3() {
    history.push("/buyproduct/smylco/2022-10-26:21:22:19:566/");
    ReactGA.event({
      category: "Home Page product",
      action: "product 3 click",
      label: "HomePage",
    });
  }

  return (
    <div className={classes.main}>
      <div className={classes.heading}>DISCOVER FASHION</div>
      <div className={classes.productContainer}>
        <div className={classes.productCard} onClick={productclick1}>
          <img
            src="https://res.cloudinary.com/ayshubham/image/upload/v1674894825/sarvhhomepage/DiscoverFashion1_q0wlrl.jpg"
            alt=""
            className={classes.productImg}
          />
          <div className={classes.productName}>Tie Die Oversize T-Shirt</div>
        </div>
        <div className={classes.productCard} onClick={productclick2}>
          <img
            src="https://res.cloudinary.com/ayshubham/image/upload/v1677497993/sarvhhomepage/Rectangle_812_awm2cs.jpg"
            alt=""
            className={classes.productImg}
          />
          <div className={classes.productName}>Carpe Deime Oversized</div>
        </div>
        <div className={classes.productCard} onClick={productclick3}>
          <img
            src="https://res.cloudinary.com/ayshubham/image/upload/v1674894825/sarvhhomepage/DiscoverFashion3_fsswxo.jpg"
            alt=""
            className={classes.productImg}
          />
          <div className={classes.productName}>Look Mom I Can Fly Hoodie</div>
        </div>
      </div>
    </div>
  );
};

export default DiscoverFashion;
