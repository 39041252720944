import React, { useState, useEffect } from "react";
import "./Login.css";
import Loginimg from "../images/Loginimg.jpg";
import { useHistory, Link } from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { makeStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Swal from "sweetalert2";
import { validateEmail, validatePassword } from "../helper/validator";
import { login, googlelogin, facebooklogin } from "../redux/actions/authAction";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import HomeIcon from "@material-ui/icons/Home";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login";
import { Navbar } from "../components/Navbar";

axios.defaults.withCredentials = true;

const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: 20,
    marginBottom: 20,
    width: "100%",
  },
  mainContainer: {
    width: "50%",
    [theme.breakpoints.down("sm")]: { width: "180%" },
    edit: {
      fontSize: 2,
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "2%",
    height: "80vh",
    justifyContent: "center",
    marginLeft: "20%",

    "@media (max-width:1620px)": {
      marginLeft: "40%",
    },
    "@media (max-width:1070px)": {
      marginLeft: "0",
    },
  },
  btn: {
    marginTop: "5%",
    width: "50%",
    marginLeft: 90,
    "@media (max-width:300px)": {
      width: "100%",
      marginLeft: 0,
    },
  },
  forgotPass: {
    letterSpacing: "0.1rem",
    textDecoration: "none",
    display: "flex",
    justifyContent: "flex-end",
    fontFamily: "'Poppins', sans-serif",
    textDecoration: "none",
    color: "#333333",
  },
  googleButton: {
    width: "100%",
  },
}));

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { alert } = useSelector((state) => state);

  async function loginAPI(e) {
    e.preventDefault();
    if (!validatePassword(password)) {
      // validating password
      Swal.fire({
        icon: "error",
        title: "Invalid Password..",
        text: "Password should be minimum 4 characters long",
      });
    } else {
      const userData = {
        email: validateEmail(email) ? email : "",
        username: validateEmail(email) ? "" : email,
        password: password,
      };
      dispatch(login(userData));
    }
  }
  const responseGoogle = (response) => {
    dispatch(googlelogin(response));
  };
  const responseFacebook = (response) => {
    dispatch(facebooklogin(response));
  };
  useEffect(() => {
    if (alert.error) {
      Swal.fire({
        icon: "error",
        title: alert.error,
      });
    }
  }, [alert.error]);

  return (
    <div>
      <div className="main" id="left-sidebar">
        <div className="imgbg">
          <img
            src={Loginimg}
            alt=""
            className="imghere"
            style={{ width: "auto", height: "100%" }}
          />
        </div>
        <div className="mainlog">
          <Navbar />

          <button
            type="submit"
            className="signupBtn"
            onClick={() => {
              window.location.href = "/signup";
            }}
          >
            Sign up
          </button>

          <div className={classes.mainContainer}>
            <div className="welTxt">WELCOME BACK</div>
            <form noValidate autoComplete="off" onSubmit={loginAPI}>
              <input
                className="testing"
                placeholder="Username or Email"
                type="email"
                required
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                className="testing"
                placeholder="Password"
                type="password"
                colors="primary"
                required
                onChange={(e) => setPassword(e.target.value)}
              />
              <Link to={"/forgotpassword"} style={{ textDecoration: "none" }}>
                <div className={classes.forgotPass}>Forgot Password ?</div>
              </Link>

              <button type="submit" className="SubmitBtn">
                Submit
              </button>
              <div
                style={{
                  whiteSpace: "nowrap",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ width: "45%" }}>
                  <div
                    style={{
                      borderBottom: "1px solid black",
                      width: "100%",
                      borderStyle: "dashed",
                    }}
                  ></div>
                  <div></div>
                </div>
                <div>or</div>
                <div style={{ width: "45%" }}>
                  <div
                    style={{
                      borderBottom: "1px solid black",
                      width: "100%",
                      borderStyle: "dashed",
                    }}
                  ></div>
                  <div></div>
                </div>
              </div>
              <GoogleLogin
                clientId="240650313405-8u85vv2auaabjq44cle3126gbnm3mi12.apps.googleusercontent.com"
                buttonText="Google"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={"single_host_origin"}
                className={classes.googleButton}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
