import React, { useState, useEffect } from "react";
import { Avatar, Button, makeStyles, Typography } from "@material-ui/core";
import StarOutlinedIcon from "@material-ui/icons/StarOutlined";
import { useSelector, useDispatch } from "react-redux";
import Followbtn from "./Followbtn";
import { Link } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import { IoMdStar } from "react-icons/io";
import Ratings from "./Ratings";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  profileContainer: {
    position: "relative",
    top: 0,
    left: 0,
    width: "100%",
    height: "fit-content",
  },
  userNameTop: {
    display: "none",
    // "@media (max-width:480px)": {
    //   // display: "f",
    //   display: "flex",
    //   justifyContent: "center",
    //   alignItems: "center",
    //   borderTop: "1px solid #BEBEBE",
    //   borderBottom: "1px solid #BEBEBE",
    //   fontFamily: "GT-America-Bold",
    //   width: "100%",
    //   color: "#333333",
    // },
  },
  avatarContainer: {
    marginTop: "4%",
    paddingBottom: "4%",
    position: "relative",
    left: 0,
    top: 0,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "space-around",
  },
  profileFollowCont: {
    position: "relative",
    top: 0,
    left: 0,
    width: "100%",
    height: "fit-content",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    "@media (max-width:550px)": {
      flexDirection: "column",
    },
    justifyContent: "space-between",
  },
  lineH: {
    width: "1px",
    // height: "100%",
    // color: "black",
    backgroundColor: "rgba(51, 51, 51, 0.63);",
    "@media (max-width:550px)": {
      display: "none",
    },
  },
  left: {
    position: "relative",
    top: 0,
    left: 0,
    width: "35%",
    "@media (max-width:550px)": {
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    height: "fit-content",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  profilePic: {
    position: "relative",
    top: 0,
    left: 0,
    width: "25%",
    maxWidth: "104px",
    alignSelf: "center",
    // justifySelf: "center",
    "@media (max-width:400px)": {
      width: "70%",
      // font-family: 'Poppins', sans-serif;
    },

    "&:after": {
      display: "block",
      content: '" "',
      width: "100%",
      paddingTop: "100%",
    },
  },
  large: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  userInfo: {
    position: "relative",
    top: 0,
    left: 0,
    width: "65%",
    "@media (max-width:550px)": {
      width: "fit-content",
    },
    height: "fit-content",
    display: "flex",
    flexDirection: "column",
    marginLeft: "4%",
    justifyContent: "flex-start",
  },
  userName: {
    width: "100%",
    maxWidth: "100%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    fontFamily: "'Poppins', sans-serif;",
    fontSize: "0.9rem",
    marginTop: "3%",
    marginBottom: "3%",
    "@media (max-width:550px)": {
      width: "fit-content",
      display: "flex",
      justifyContent: "center",
      width: "100%",
    },
  },

  right: {
    position: "relative",
    top: 0,
    left: 0,
    width: "40%",
    fontFamily: "'Poppins', sans-serif;",
    "@media (max-width:550px)": {
      width: "100%",
      marginTop: "4%",
    },
    height: "fit-content",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  right2: {
    position: "relative",
    top: 0,
    left: 0,
    width: "100%",
    height: "fit-content",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    // backgroundColor: "black",
  },
  followersDiv: {
    position: "relative",
    top: 0,
    left: 0,
    width: "fit-content",
    height: "fit-content",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: "10%",
    // fontSize: "GT-America-Extended-Regular",
  },
  displayDiv: {
    padding: "2%",
  },
  toggleButtonGroup: {
    marginTop: 100,
    width: "70%",
    position: "relative",
    left: "50%",
    transform: "translateX(-50%)",
  },
  btn: {
    // width: "100%",
    width: "100%",
    marginTop: "5%",

    // paddingTop: "3%",
    all: "unset",
    border: "1px solid #7F37EF",
    // justifyContent: "center",
    display: "flex",
    justifyContent: "center",
    // alignItems: "center",
    // alignContent: "center",
    alignItems: "center",
    padding: "1.5%",
    borderRadius: "7px",
    color: "black",
    fontFamily: "'Poppins', sans-serif",
  },
  headings: {
    fontSize: "1.1rem",
    fontWeight: "500",
    fontFamily: "'Poppins', sans-serif",

    // text-transform: uppercase;
    textTransform: "uppercase",
    "@media (max-width:660px)": {
      fontSize: "1rem",
    },
    "@media (max-width:550px)": {
      display: "flex",
      justifyContent: "center",
      // fontSize: "0.8rem",
    },
  },
  subHeadings: {
    fontSize: "1rem",
    fontFamily: "Poppins', sans-serif",
    "@media (max-width:660px)": {
      fontSize: "0.9rem",
    },
    "@media (max-width:540px)": {
      fontSize: "0.7rem",
    },
  },
  bioContainer: {
    position: "relative",
    top: 0,
    left: 0,
    width: "100%",
    height: "fit-content",
    marginTop: "2%",
    fontFamily: "'Poppins', sans-serif",
  },

  paper: {
    position: "absolute",
    width: "40%",
    height: "30%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));
const Info = ({ id: id, setDisplayList: setDisplayList, setdataList: setdataList }) => {
  const { auth, profile } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(2);
  const classes = useStyles();
  const [userData, setUserData] = useState([]);

  function isEmptyObject(obj) {
    return JSON.stringify(obj) === "{}";
  }

  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [followersOpen, setFollowersOpen] = useState(false);
  const [followingOpen, setFollowingOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (isEmptyObject(auth)) {
      try {
        const newData = profile.users;
        setUserData(newData);
      } catch (err) {
        /*  console.log(err); */
      }
    } else {
      if (auth.user.username === id) {
        setUserData(auth.user);
      } else {
        try {
          const newData = profile.users;
          setUserData(newData);
        } catch (err) {
          /*  console.log(err); */
        }
      }
    }
  }, [auth, profile.users, id]);
  function body(values) {
    return (
      <div style={modalStyle} className={classes.paper}>
        {values.length &&
          values.map((item, i) => (
            <div key={i}>
              <span>
                <Avatar src={item.avatar} alt="profile image" className={classes.large} />
              </span>
              <span>{item.fullname}</span>
              <Link to={"profile/" + item.username} style={{ textDecoration: "none" }}>
                <span> {item.username}</span>
              </Link>
            </div>
          ))}
      </div>
    );
  }
  return (
    <div className={classes.profileContainer}>
      {userData.username ? (
        <div className={classes.userNameTop}>@{userData.username}</div>
      ) : (
        <div></div>
      )}

      {userData && userData.followers && userData.following && (
        <div className={classes.avatarContainer}>
          <div className={classes.profileFollowCont}>
            <div className={classes.left}>
              <div className={classes.profilePic}>
                <Avatar src={userData.avatar} alt="profile image" className={classes.large} />
              </div>
              <div className={classes.userInfo}>
                <div className={classes.headings}>{userData.fullname}</div>
                <div className={classes.userName}>@{userData.username}</div>

                {/* add rattings over here. responsiveness will be handled */}
                <Ratings />
              </div>
            </div>
            <div className={classes.lineH}></div>
            <div className={classes.right}>
              <div className={classes.right2}>
                <div
                  onClick={() => {
                    setdataList({ name: "Followers", userData: userData.followers });
                    setDisplayList(true);
                  }}
                  className={classes.followersDiv}
                >
                  <Typography className={classes.subHeadings}>
                    {userData.followers.length}
                  </Typography>

                  <Button className={classes.subHeadings}>Followers</Button>
                  <Modal
                    className={classes.modaldiv}
                    open={followersOpen}
                    onClose={() => {
                      setFollowersOpen(false);
                    }}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                  >
                    {body(userData.followers)}
                  </Modal>
                </div>
                <div
                  onClick={() => {
                    setdataList({ name: "Following", userData: userData.following });
                    setDisplayList(true);
                  }}
                  className={classes.followersDiv}
                >
                  <Typography className={classes.subHeadings}>
                    {userData.following.length}
                  </Typography>
                  <Button className={classes.subHeadings}>Following</Button>
                  <Modal
                    open={followingOpen}
                    onClose={() => {
                      setFollowingOpen(false);
                    }}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                  >
                    {body(userData.following)}
                  </Modal>
                </div>
              </div>
              {/* logged in  */}
              {!isEmptyObject(auth) && (
                <>
                  {userData.username === auth.user.username ? (
                    <Link to={"/editprofile"} style={{ textDecoration: "none" }}>
                      <button
                        // size="small"
                        // color="primary"
                        // variant="contained"
                        className={` ${classes.btn}`}
                      >
                        Edit Profile
                      </button>
                    </Link>
                  ) : (
                    <Followbtn user={userData}></Followbtn>
                  )}
                </>
              )}
              {isEmptyObject(auth) && <div></div>}
            </div>
          </div>
          <div className={`${classes.bioContainer} ${classes.subHeadings}`}>
            <p>{userData.bio}</p>
          </div>
        </div>
      )}
    </div>
  );
};
export default Info;
