import { getDataAPI, patchDataAPI, postDataAPI, deleteDataAPI } from "../../utils/fetchData";
import { GLOBALTYPES, DeleteData } from "./globalTypes";

export const PROFILE_TYPES = {
  LOADING: "LOADING",
  GET_USER: "GET_USER",
  FOLLOW: "FOLLOW",
  UNFOLLOW: "UNFOLLOW",
  GETPRODUCT: "GETPRODUCT",
  GET_ORDER: "GET_ORDER",
};
export const getProfileUsers =
  ({ id, auth }) =>
  async (dispatch) => {
    try {
      dispatch({ type: PROFILE_TYPES.LOADING, payload: true });
      const res = await getDataAPI(`user/${id}`, auth.token);
      const res2 = await getDataAPI(`product/${id}`, auth.token);
      dispatch({
        type: PROFILE_TYPES.GET_USER,
        payload: res.data,
      });
      dispatch({
        type: PROFILE_TYPES.GETPRODUCT,
        payload: res2.data,
      });
      dispatch({ type: PROFILE_TYPES.LOADING, payload: false });
    } catch (err) {
      console.log(err);
      dispatch({
        type: GLOBALTYPES.ALERT,
        payload: { error: err.response ? err.response.data.message : err },
      });
    }
  };
export const getProfileOrders =
  ({ id, auth }) =>
  async (dispatch) => {
    try {
      dispatch({ type: PROFILE_TYPES.LOADING, payload: true });
      const res = await getDataAPI(`myorders/${id}`, auth.token);
      dispatch({
        type: PROFILE_TYPES.GET_ORDER,
        payload: res.data,
      });
      dispatch({ type: PROFILE_TYPES.LOADING, payload: false });
    } catch (err) {
      console.log(err);
      dispatch({
        type: GLOBALTYPES.ALERT,
        payload: { error: err.response ? err.response.data.message : err },
      });
    }
  };

export const follow =
  ({ users, user, auth }) =>
  async (dispatch) => {
    var newUser = { ...users, followers: [...user.followers, auth.user] };
    dispatch({
      type: PROFILE_TYPES.FOLLOW,
      payload: newUser,
    });
    dispatch({
      type: GLOBALTYPES.AUTH,
      payload: {
        ...auth,
        user: {
          ...auth.user,
          following: [...auth.user.following, newUser],
        },
      },
    });
    try {
      const res = await patchDataAPI(`user/${user.username}/follow`, null, auth.token);
      /*       console.log(res);
       */
    } catch (err) {
      dispatch({
        type: GLOBALTYPES.ALERT,
        payload: {
          error: err.response ? err.response.data.message : err,
        },
      });
    }
  };

export const unfollow =
  ({ users, user, auth }) =>
  async (dispatch) => {
    var newUser = {
      ...user,
      followers: DeleteData(user.followers, auth.user.username),
    };

    dispatch({ type: PROFILE_TYPES.UNFOLLOW, payload: newUser });
    dispatch({
      type: GLOBALTYPES.AUTH,
      payload: {
        ...auth,
        user: {
          ...auth.user,
          following: DeleteData(auth.user.following, newUser.username),
        },
      },
    });
    try {
      await patchDataAPI(`/user/${user.username}/unfollow`, null, auth.token);
    } catch (err) {
      dispatch({
        type: GLOBALTYPES.ALERT,
        payload: {
          error: err.response ? err.response.data.message : err,
        },
      });
    }
  };

export const rating =
  ({ users, user, auth, rate, id }) =>
  async (dispatch) => {
    var newUser = { ...user, rating: [...user.rating, auth.user] };
    dispatch({
      type: PROFILE_TYPES.RATING,
      payload: newUser,
    });
    dispatch({
      type: GLOBALTYPES.AUTH,
      payload: {
        ...auth,
        user: {
          ...auth.user,
          rating: [...auth.user.rating, newUser],
        },
      },
    });
    try {
      const res = await patchDataAPI(`/user/rating/${id}/${rate}`, null, auth.token);
      /*       console.log(res);
       */
    } catch (err) {
      dispatch({
        type: GLOBALTYPES.ALERT,
        payload: {
          error: err.response ? err.response.data.message : err,
        },
      });
    }
  };

export const updateQuantity =
  ({ data, user, id, quantity, auth, size }) =>
  async (dispatch) => {
    for (var i = 0; i < data.length; i++) {
      if (data[i].product.productId === id) {
        data[i].quantity = parseInt(quantity);
      }
    }
    dispatch({
      type: GLOBALTYPES.AUTH,
      payload: {
        ...auth,
        user: {
          ...auth.user,
          cart: data,
        },
      },
    });
    try {
      // const res = await postDataAPI(`/cart/update/${id}/${quantity}/${size}`, null, auth.token);
      const res = await postDataAPI(
        `cart/update/${user}/${id}/${quantity}/${size}`,
        null,
        auth.token
      );
    } catch (error) {
      console.log(error);
    }
  };

export const deleteQuantity =
  ({ data, user, id, auth, size, color }) =>
  async (dispatch) => {
    var newData = data.filter(function (item) {
      // return item.product._id !== id;
      return item.product.productId !== id;
    });
    dispatch({
      type: GLOBALTYPES.AUTH,
      payload: {
        ...auth,
        user: {
          ...auth.user,
          cart: newData,
        },
      },
    });
    try {
      // const res = await postDataAPI(`/cart/update/${id}/0/${size}`, null, auth.token);
      const res = await postDataAPI(
        `cart/update/${user}/${id}/0/${size}/${color}`,
        null,
        auth.token
      );
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

export const deleteUnreadNotifications =
  ({ auth }) =>
  async (dispatch) => {
    try {
      const res = await deleteDataAPI(`user/deleteunreadnotifications`, auth.token);
    } catch (error) {
      console.log(error);
    }
  };

export const addToCart =
  ({ size, user, id, quantity, auth, color }) =>
  async (dispatch) => {
    try {
      // const result = await postDataAPI(`/cart/update/${id}/${quantity}/${size}`, null, auth.token);
      const result = await postDataAPI(
        `/cart/update/${user}/${id}/${quantity}/${size}/${color}`,
        null,
        auth.token
      );

      dispatch({
        type: GLOBALTYPES.AUTH,
        payload: {
          ...auth,
          user: {
            ...auth.user,
            cart: result.data.cart,
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

export const addToWishlist =
  ({ size, user, id, quantity, auth }) =>
  async (dispatch) => {
    try {
      const result = await postDataAPI(
        // `/wishlist/update/${id}/${quantity}/${size}`,
        `/wishlist/update/${user}/${id}/${quantity}/${size}`,
        null,
        auth.token
      );
      console.log("wishlist", result);
      dispatch({
        type: GLOBALTYPES.AUTH,
        payload: {
          ...auth,
          user: {
            ...auth.user,
            wishlist: result.data.wishlist,
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

export const updateRating =
  ({ rating, auth }) =>
  async (dispatch) => {
    /*     console.log(rating);
     */
  };
export const deleteProduct =
  ({ products, authP, _id }) =>
  async (dispatch) => {
    products = products.filter((item) => item.productId != _id);
    dispatch({
      type: PROFILE_TYPES.GETPRODUCT,
      payload: {
        product: products,
      },
    });
    try {
      const res = await deleteDataAPI(`product/${_id}`, authP.token);
    } catch (error) {
      console.log(error);
    }
  };
