import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { Navbar } from "../components/Navbar";
import { getDataAPI } from "../utils/fetchData";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Skeleton from "react-loading-skeleton";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    justifyContent: "center",
    // alignItems: "center",
    // marginRight: "15%",
    height: "100%",
    padding: "3% 15% 0%",
    marginTop: "64px",
    flexDirection: "column",
    "@media (max-width:600px)": {
      padding: "3% 2% 0%",
    },
  },
  line: {
    borderBottom: "1px solid black",
  },
  Myorders: {
    fontSize: "1.3rem",
  },
  Datawrapper: {
    border: "1px solid black",
    marginTop: "3%",
    display: "flex",
    flexDirection: "row",
    padding: "5%",
    gap: "3%",
  },
  Transist: {
    marginTop: "5%",
    display: "flex",
    flexDirection: "row",
    gap: "2%",
  },
  OrderImage: {
    maxHeight: "200px",
    cursor: "pointer",
  },
  Progress: {
    color: "#49C06B",
  },

  OrderData: {
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center",
    gap: "1.5%",
  },
  btn: {
    width: "100%",
    // display: "flex",
    // flexDirection: "column",
    // display: "flex",
    // flexDirection: "column",
    borderBottom: "1px solid black",
    borderRight: "1px solid black",
    borderLeft: "1px solid black",
    all: "unset",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0.5%",
  },
  btnwrapper: {
    display: "flex",
    flexDirection: "row",
  },
  noOrderMain: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    height: "80vh",
  },
  firstTxt: {
    fontFamily: "poppins, sans-serif",
    // "@media (max-width:600px)": {
    //   fontSize: "12px",
    // },
    "@media (max-width:446px)": {
      fontSize: "12px",
    },
    "@media (max-width:320px)": {
      fontSize: "10px",
    },
    "@media (max-width:270px)": {
      fontSize: "8px",
    },
  },
  secondTxt: {
    fontFamily: "poppins, sans-serif",
    "@media (max-width:446px)": {
      fontSize: "12px",
    },
    "@media (max-width:320px)": {
      fontSize: "10px",
    },
    "@media (max-width:270px)": {
      fontSize: "8px",
    },
  },
}));
const OrderHistory = ({ id: id }) => {
  const classes = useStyles();
  const { auth, profile } = useSelector((state) => state);
  const [orders, setOrders] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [noOrders, setNoOrders] = useState(true);
  const history = useHistory();

  function isEmptyObject(obj) {
    return JSON.stringify(obj) === "{}";
  }
  useEffect(() => {
    if (isEmptyObject(auth)) {
      try {
        const newData = profile.users;
        setUserData(newData);
      } catch (err) {
        console.log(err);
      }
    } else {
      if (auth.user.username === id) {
      } else {
        try {
          setUserData(auth.user);
          // setUserData(newData);
          // console.log("ok report2");
        } catch (err) {
          console.log(err);
        }
      }
    }
  }, [auth, profile.users]);
  //

  useEffect(() => {
    async function fetchData() {
      const newArr = [];
      // setNoOrders(false);

      if (auth.user) {
        const res = await getDataAPI(`myorders/${userData.username}`, auth.token);
        console.log(res.data.orders, "res data, 166");

        if (res.data.orders.loading === false) {
          setLoading(false);
        }

        for (let i = 0; i < res.data.orders.Count; i++) {
          newArr.push(res.data.orders.Items[i]);
          var old_Date = res.data.orders.Items[i].orderData;
          // console.log(old_Date, "old_Date:");
          var old_rev_Date = old_Date.split("/").reverse().join("-");
          // console.log(old_rev_Date, "old_Rev_Date:");

          function addDays(date, days) {
            var result = new Date(date);
            result.setDate(result.getDate() + days);
            return result;
          }
          // const oldDate = "11/02/2013";
          const something = addDays(old_rev_Date, 5);
          // console.log(something, "something");
        }
        console.log(newArr);
        setOrders(newArr);
      }
    }
    fetchData();

    // if (auth.user) {
    //   if (orders) {
    //   }

    // var old_rev_date = date.split("/").reverse().join("-");
    // console.log(old_rev_date, "rev_Date:");
    // }

    var date = "03/11/2014";
    var newdate = date.split("/").reverse().join("-");
    console.log(newdate, "newdate reversed");
    // console.log(orders[1].order, "orders");
    // function addDays(myDate, days) {
    //   return new Date(myDate.getTime() + days * 24 * 60 * 60 * 1000);
    // }

    // var myDate = new Date("2013-02-11");

    // var newDate = addDays(myDate, 5);
    // console.log(newDate, "newDate");

    // Function call
    // if (orders) {
    //   const oldDate = orders[1].orderData;
    //   console.log(oldDate, "oldDate");
    //   console.log(ReverseString(oldDate));
    // }
  }, [userData, profile.users, loading]);

  useEffect(() => {
    console.log(orders.length, "orders at 210");
    if (orders.length === 0) {
      setNoOrders(false);
    }
  }, [orders.length]);

  const needhelp = () => {
    history.push("/contactus");
    Swal.fire("Please raise your query here");
  };

  return (
    <div className={classes.wrapper}>
      <Navbar />
      <div>
        {console.log(orders, "orders")}
        <div className={classes.Myorders}>My Orders</div>
        <div className={classes.line}></div>
      </div>
      {/* {noOrders ? (
        <div className={classes.noOrderMain}>
          <div className={classes.firstTxt}>You have not ordered anything yet we are eager to</div>
          <div className={classes.secondTxt}>sarvh you</div>
        </div>
      ) : ( */}

      <div>
        {loading ? (
          <div>
            <div>
              <Skeleton style={{ height: "60px" }} />
            </div>
            <div>
              <Skeleton style={{ height: "300px" }} />
            </div>
            <br />
            <br />
            <div>
              <Skeleton style={{ height: "60px" }} />
            </div>
            <div>
              <Skeleton style={{ height: "300px" }} />
            </div>
          </div>
        ) : (
          <div>
            {console.log(orders, "orders at 260")}
            {orders.map((item, i) => (
              <div>
                {JSON.stringify(console.log(item.order.product))}
                <div className={classes.Transist}>
                  <div>
                    <svg
                      width="49"
                      height="49"
                      viewBox="0 0 49 49"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="24.5" cy="24.5" r="24.5" fill="#49C06B" />
                      <path
                        d="M37.0385 18.157C37.0385 18.157 37.0385 18.157 37.0385 18.0468L36.9536 17.8401C36.924 17.7963 36.8909 17.7548 36.8545 17.7161C36.8166 17.6578 36.774 17.6025 36.7271 17.5508L36.5996 17.4544L36.3731 17.3442L25.756 10.9655C25.531 10.8287 25.271 10.7561 25.0057 10.7561C24.7404 10.7561 24.4804 10.8287 24.2554 10.9655L13.7232 17.3442L13.5958 17.4544L13.4684 17.5508C13.4214 17.6025 13.3788 17.6578 13.341 17.7161C13.3046 17.7548 13.2714 17.7963 13.2419 17.8401L13.1569 18.0468C13.1569 18.0468 13.1569 18.0468 13.1569 18.157C13.143 18.276 13.143 18.3962 13.1569 18.5152V30.5561C13.1564 30.7903 13.2173 31.0206 13.3337 31.2255C13.4501 31.4304 13.6183 31.6031 13.8223 31.7271L24.4394 38.1058C24.5048 38.1451 24.5766 38.173 24.6518 38.1885C24.6518 38.1885 24.7226 38.1885 24.765 38.1885C25.0045 38.2624 25.2617 38.2624 25.5012 38.1885C25.5012 38.1885 25.5719 38.1885 25.6144 38.1885C25.6896 38.173 25.7614 38.1451 25.8268 38.1058L36.3731 31.7271C36.5772 31.6031 36.7453 31.4304 36.8617 31.2255C36.9781 31.0206 37.039 30.7903 37.0385 30.5561V18.5152C37.0524 18.3962 37.0524 18.276 37.0385 18.157ZM23.6325 34.4687L15.8466 29.7846V20.995L23.6325 25.6654V34.4687ZM25.0482 23.282L17.1207 18.5152L25.0482 13.7622L32.9757 18.5152L25.0482 23.282ZM34.2497 29.7846L26.4638 34.4687V25.6654L34.2497 20.995V29.7846Z"
                        fill="white"
                      />
                      <line
                        x1="20.8464"
                        y1="15.8837"
                        x2="30.4074"
                        y2="21.8593"
                        stroke="white"
                        stroke-width="2"
                      />
                    </svg>
                  </div>
                  <div>
                    <div className={classes.Progress}>In Transist</div>

                    <div>Product will arrive at 4-5 days of order</div>
                  </div>
                </div>
                <div className={classes.Datawrapper}>
                  {item.order.product.Image_small ? (
                    <div>
                      <img
                        className={classes.OrderImage}
                        src={item.order.product.Image_small[0]}
                        alt=""
                        onClick={(e) => {
                          history.push(
                            `/buyproduct/${item.order.product.seller}/${item.order.product.productId}`
                          );
                        }}
                      />
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <div className={classes.OrderData}>
                    {console.log(item, "item at 302")}
                    {console.log(item.order, "item order at 302")}
                    <div> {item.order.product.productName}</div>
                    <div>{item.username}</div>
                    <div>{item.order.mrp} </div>
                    <div>Size : {item.order.size} </div>
                    <div>Qty : {item.order.product.quantity}</div>
                    <div>Order Id : {item.order.product.orderId}</div>
                  </div>
                </div>
                <div className={classes.btnwrapper}>
                  <button className={classes.btn} onClick={needhelp}>
                    Need Help ?
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* )} */}
    </div>
  );
};

export default OrderHistory;
