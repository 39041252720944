import React from 'react'
import { makeStyles } from "@material-ui/core";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const CardSkeleton  = () => {

  return (
    <div
    style={{             
      display: "flex",
      flexDirection: "column", 
      gap: "4.3%",
    }}
  >
    <div style={{ width: "95%", height: "76%", padding: "0" }}>
      <Skeleton style={{ margin: "0", padding: "0", width: "100%", height: "100%" }} />
    </div>
    <div style={{ width: "95%" }}>
      <div style={{ width: "100%" }}>
        <Skeleton style={{ margin: "0", padding: "0", width: "100%", height: "100%" }} />
      </div>

      <div style={{ width: "100%" }}>
        <Skeleton style={{ margin: "0", padding: "0", width: "100%", height: "100%" }} />
      </div>
    </div>
  </div>
  )
}

export default CardSkeleton