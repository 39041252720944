import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import TimelineIcon from "@material-ui/icons/Timeline";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
// import { getDataAPI } from "../utils/fetchData";
import { getDataAPI } from "../../utils/fetchData";
import SellersOrderPage from "./SellersOrderPage";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-evenly",
    "@media (max-width:660px)": {
      flexDirection: "column",
      gap: "10px",
    },
  },
  common: {
    display: "flex",
    backgroundColor: "rgba(240,217,255,0.5)",
    padding: "2%",
    width: "30%",
    height: 100,
    // backgroundColor: "rgba(255, 255, 255, 1)",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 14,
  },
  marginLeft: {
    marginLeft: "10%",
  },
  second: {
    backgroundColor: "rgba(205, 240, 234, 0.5)",
    "@media (max-width:660px)": {
      width: "100%",
    },
  },
  boxOne: {
    "@media (max-width:660px)": {
      width: "100%",
    },
  },
  third: {
    backgroundColor: "rgba(239, 187, 207, 0.5)",
    "@media (max-width:660px)": {
      width: "100%",
    },
  },
  large: {
    width: theme.spacing(5.5),
    height: theme.spacing(5.5),
  },
  bold: {
    fontWeight: "bold",
  },
  light: {
    fontWeight: "light",
  },
  purple: {
    color: "#C490E4",
  },
  blue: {
    color: "#78DEC7",
  },
  pink: {
    color: "#FF94CC",
  },
  viewmore: {
    color: "white",
    fontSize: "12px",
    cursor: "pointer",
    backgroundColor: "black",
    padding: "5px",
    border: "none",
    borderRadius: "5px",
  },
}));

const InformationComponent = ({ authP }) => {
  const classes = useStyles();
  const [orders, setOrders] = useState([]);
  const { auth } = useSelector((state) => state);
  const [totalOrders, setTotalOrders] = useState();
  const [totalSales, setTotalSales] = useState();
  const history = useHistory();

  useEffect(() => {
    async function fetchData() {
      const newArr = [];
      let sum = 0;
      if (auth.user && auth.user.isSeller === true) {
        console.log("consoled info.js");
        try {
          const res = await getDataAPI(`sellerorders/${auth.user.username}`, auth.token);
          console.log(res, "res");
          console.log(res.data.orders.Items, "data of orderd product");
          for (let i = 0; i < res.data.orders.Count; i++) {
            newArr.push(res.data.orders.Items[i]);
            sum += Number(res.data.orders.Items[i].order.product.price);
            console.log(res, "res");
          }
          setOrders(newArr);
          setTotalOrders(res.data.orders.Count);
          console.log(totalOrders, sum);
          setTotalSales(sum);

          var newArray = newArr.filter(function (el) {
            return el.delivered === "y";
          });
          console.log(newArray, "sorted array");
        } catch (error) {
          console.log(error);
        }
      }
    }
    fetchData();
  }, [auth.token, auth.user]);
  // useEffect(() => {
  //   async function fetchData() {
  //     const newArr = [];
  //     if (auth.user && auth.user.isSeller === true) {
  //       const res = await getDataAPI(`myorders/${auth.user.username}`, auth.token);
  //       let sum = 0;
  //       console.log(res.data.orders.Items, "data of orderd product");
  //       for (let i = 0; i < res.data.orders.Count; i++) {
  //         newArr.push(res.data.orders.Items[i].order.product.price);

  //       }

  //       console.log(newArr, sum)
  //       // setOrders(newArr);
  //     }
  //   }
  //   fetchData();
  // }, [auth.token, auth.user]);
  console.log(auth);
  console.log(orders);
  function viewmore() {
    history.push("/sellersorder");
  }

  return (
    <div className={classes.container}>
      <div className={`${classes.common} ${classes.boxOne}`}>
        <MonetizationOnIcon className={`${classes.large} ${classes.purple}`} />
        <div className={classes.marginLeft}>
          <Typography className={classes.light}>Total Sales</Typography>
          <Typography variant="h5" className={classes.bold}>
            ₹ {totalSales}
          </Typography>
          <button className={classes.viewmore} onClick={viewmore}>
            view more
          </button>
        </div>
      </div>
      <div className={`${classes.common} ${classes.second}`}>
        <TimelineIcon className={`${classes.large} ${classes.blue}`} />
        <div className={classes.marginLeft}>
          <Typography className={classes.light}>Total Visitors</Typography>
          <Typography variant="h5" className={classes.bold}>
            {auth.user.profilevisitors.length}
          </Typography>
        </div>
      </div>
      <div className={`${classes.common} ${classes.third}`}>
        <LocalMallIcon className={`${classes.large} ${classes.pink}`} />
        <div className={classes.marginLeft}>
          <Typography className={classes.light}>Total Orders</Typography>
          <Typography variant="h5" className={classes.bold}>
            {totalOrders}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default InformationComponent;
