import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router";
import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";
import CardsSmallimg from "../CardsSmallimg";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import CardSkeleton from "./CardSkeleton";

const useStyles = makeStyles((theme) => ({
  displayDiv: {
    position: "relative",
    top: 0,
    left: 0,
    width: "100%",
    height: "fit-content",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    padding: "2%",
  },
  categoriesContainer: {
    position: "relative",
    top: 0,
    left: 0,
    width: "100%",
    height: "fit-content",
    borderBottom: "1px solid rgba(51, 51, 51, 0.36)",
  },
  inputsContainer: {
    position: "relative",
    top: 0,
    left: 0,
    width: "40%",
    height: "fit-content",
    display: "flex",
    flexDirection: "row",
  },
  categoryInputAll: {
    position: "relative",
    top: 0,
    left: 0,
    width: "fit-content",
    height: "fit-content",
    alignItems: "center",
    textAlign: "center",
    fontSize: "0.73rem",
    border: "none",
    cursor: "pointer",
    fontFamily: " 'Poppins', sans-serif;",
    textTransform: "capitalize",

    "&:focus": {
      outline: "none",
    },
    "@media (max-width:500px)": {
      fontSize: "0.8rem",
      width: "25px",
    },
  },

  categoryInputMen: {
    position: "relative",
    top: 0,
    left: 0,
    width: "fit-content",
    height: "fit-content",
    alignItems: "center",
    textAlign: "center",
    fontSize: "0.73rem",
    border: "none",
    cursor: "pointer",
    fontFamily: " 'Poppins', sans-serif;",
    textTransform: "capitalize",

    "&:focus": {
      outline: "none",
    },
    "@media (max-width:500px)": {
      fontSize: "0.8rem",
      width: "55px",
    },

    // "@media (max-width:500px)": {
    //   fontSize: "0.5rem",
    //   fontWeight: "500",
    // },
  },
  categoryInputWomen: {
    position: "relative",
    top: 0,
    left: 0,
    width: "fit-content",
    height: "fit-content",
    alignItems: "center",
    textAlign: "center",
    fontSize: "0.73rem",
    border: "none",
    cursor: "pointer",
    fontFamily: " 'Poppins', sans-serif;",
    textTransform: "capitalize",

    "&:focus": {
      outline: "none",
    },
    "@media (max-width:500px)": {
      fontSize: "0.8rem",
      width: "55px",
    },
  },
  toggleButtonGroup: {
    position: "relative",
    top: 0,
    left: 0,
    width: "100%",
    height: "fit-content",
    position: "relative",
    left: "50%",
    transform: "translateX(-50%)",
  },
  subHeadings: {
    fontSize: "1rem",
    "@media (max-width:660px)": {
      fontSize: "0.9rem",
    },
    "@media (max-width:540px)": {
      fontSize: "0.7rem",
    },
    "& input": {
      fontSize: "1rem",
      "@media (max-width:660px)": {
        fontSize: "0.9rem",
      },
      "@media (max-width:540px)": {
        fontSize: "0.7rem",
      },
    },
  },
  mainContainer: {
    position: "relative",
    top: 0,
    left: 0,
    display: "grid",
    gridTemplateColumns: "32% 32% 32%",
    "@media (min-width:1024px)": {
      fontSize: "0.9rem",
      gridTemplateColumns: "24% 24% 24% 24%",
    },
    "@media (max-width:500px)": {
      fontSize: "0.9rem",
      gridTemplateColumns: "50% 50%",
    },
    gap: "0.7% 1%",
    "@media (max-width:700px)": {
      gap: "0 0",
    },
    marginTop: "2%",
    justifyContent: "center",
  },
  media: {
    height: 350,
  },
  cardContainer: {
    width: "100%",
    padding: "3%",
  },

  strikeThrough: {
    textDecorationLine: "line-through",
  },
  itemContainer: {
    textAlign: "center",
    height: 300,
  },
  productImage: {
    width: "100%",
    height: "100%",
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  avatarContainer: {
    marginTop: 100,
    position: "relative",
    left: "50%",
    transform: "translateX(-50%)",
    display: "flex",
    width: "50%",
    justifyContent: "space-around",
  },
  left: {
    display: "flex",
    width: "30%",
    justifyContent: "space-around",
  },
  fontSize: {
    fontSize: "1rem",
  },
  bold: {
    fontWeight: "bold",
  },
  followersDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  right: {
    width: "30%",
    display: "flex",
    flexDirection: "column",
    alignItems: "space-between",
  },
  right2: {
    display: "flex",
    width: "100%",
    justifyContent: "space-around",
  },
  nonSellerCont: {
    position: "relative",
    top: 0,
    left: 0,
    width: "100%",
    // height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "1.5rem",
    backgroundColor: "black",
  },
}));
const Posts = () => {
  const [button, setButton] = useState("");
  const { auth, profile, alert } = useSelector((state) => state);
  const dispatch = useDispatch();

  const [filterData, setFilterData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSeller, setIsSeller] = useState(true);
  const [isFalse, setFalse] = useState(true);
  const { id } = useParams();
  const [focus, setfocus] = useState(true);

  useEffect(() => {
    try {
      if (profile.loading === false) {
        setFilterData(profile.product);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  }, [auth, id, profile.product, profile.loading]);

  // function handleFormat(event, newFormats) {
  //   if (event === "All") {
  //     setFilterData(userData);
  //   } else {
  //     const newData = userData.filter(temp => temp.category === event);

  //     setFilterData(newData);
  //   }
  // }

  useEffect(() => {
    if (alert && alert.error === "the user is not seller") {
      setIsSeller(false);
    }
  }, [alert.error]);

  function handleInputChange(e) {
    setfocus(false);

    e.target.offsetParent.childNodes.forEach((ele) => {
      ele.style.borderBottom = "none";
    });
    e.target.style.borderBottom = "3px solid #B3B2B6";

    if (e.target.defaultValue === "All") {
      setFilterData(profile.product);
    } else {
      const newData = profile.product.filter((temp) => temp.category === e.target.defaultValue);

      setFilterData(newData);
    }
  }
  const classes = useStyles({ focus });
  return profile.users && JSON.stringify(profile.users) != "{}" ? (
    // profile.users.isSeller ? (
    <div className={classes.displayDiv}>
      {console.log(profile.loading)}
      <div className={classes.categoriesContainer}>
        <div className={classes.inputsContainer}>
          <input
            style={focus ? { borderBottom: "3px solid #B3B2B6" } : { borderBottom: "none" }}
            className={classes.categoryInputAll}
            onClick={(e) => {
              handleInputChange(e);
            }}
            value="All"
            readonly="readonly"
          ></input>
          <input
            className={classes.categoryInputMen}
            // style={{ width: "80px" }}
            onClick={(e) => {
              handleInputChange(e);
            }}
            value="men"
            readonly="readonly"
          />
          <input
            className={classes.categoryInputWomen}
            // style={{ width: "100px" }}
            onClick={(e) => {
              handleInputChange(e);
            }}
            value="women"
            readonly="readonly"
          />
          {/* <input
            className={classes.categoryInput}
            onClick={(e) => {
              handleInputChange(e);
            }}
            value="accessories"
            readonly="readonly"
          /> */}
        </div>
        {/* <ToggleButtonGroup
            className={classes.toggleButtonGroup}
            type="radio"
            name="options"
            value={button}
            radioDisplay={false}
            onChange={handleFormat}
          >
            <ToggleButton className={classes.subHeadings} id="tbg-radio-1" value="All">
              ALL
            </ToggleButton>
            <ToggleButton className={classes.subHeadings} id="tbg-radio-2" value="Men">
              MEN
            </ToggleButton>
            <ToggleButton className={classes.subHeadings} id="tbg-radio-3" value="Women">
              WOMEN
            </ToggleButton>
            <ToggleButton className={classes.subHeadings} id="tbg-radio-4" value="Accessories">
              ACCESSORIES
            </ToggleButton>
          </ToggleButtonGroup> */}
      </div>

      {isLoading ? (
        <div className={classes.mainContainer} style={{ height: "900px" }}>
          <CardSkeleton /> <CardSkeleton /> <CardSkeleton /> <CardSkeleton /> <CardSkeleton />{" "}
          <CardSkeleton /> <CardSkeleton /> <CardSkeleton />
        </div>
      ) : (
        <div className={classes.mainContainer}>
          {filterData.map((data) => (
            <div className={classes.postCards}>
              <CardsSmallimg
                to={"/buyproduct/" + data.user.username + "/" + data.productId + "/"}
                username={profile.users.fullname}
                avatar={profile.users.avatar}
                data={data}
                carouselImgs={{
                  display: true,
                  arrows: false,
                  dots: true,
                  swipe: true,
                  autoSlide: false,
                }}
                post={true}
              />
              {/* <Carousel className={classes.carouselContainer}>
                {user.images.map(image => (
                  <Carousel.Item className={classes.itemContainer}>
                    <Link
                      to={"/buyproduct/" + user._id}
                      style={{ textDecoration: "none" }}
                    >
                      <img
                        className={classes.productImage}
                        src={image}
                        alt={"productimage"}
                      ></img>
                    </Link>
                  </Carousel.Item>
                ))}
              </Carousel>

              <div>Product Name: {user.productName}</div>
              <div>Price: {user.price}</div>
              <div>Description: {user.productDescription}</div>
              <div>Features: {user.productFeatures}</div> */}
            </div>
          ))}
        </div>
      )}
    </div>
  ) : (
    // ) : (
    //   <div className={classes.nonSellerCont}>
    //     <div className={classes.marquee}>marquee here</div>
    //   </div>
    // )
    <p></p>
  );
};

export default Posts;
