import { GLOBALTYPES } from "./globalTypes";
import { putDataAPI, postDataAPI, getDataAPI } from "../../utils/fetchData";
export const PRODUCT_TYPES = {
  WHATSNEW: "WHATSNEW",
  ALLPRODUCTS: "ALLPRODUCTS",
  BUYPRODUCT: "BUYPRODUCT",
  AVAILABLEPRODUCT: "AVAILABLEPRODUCT",
  FEEDPRODUCT: "FEEDPRODUCT",
};
export const uploadProduct = (auth, data) => async (dispatch) => {
  try {
    console.log(data);
    dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
    const res = await putDataAPI(`product`, data, auth.token);
    dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
  } catch (err) {
    dispatch({
      type: GLOBALTYPES.ALERT,
      payload: {
        error: err.response.data.msg,
      },
    });
  }
};
export const likeProduct =
  ({ user, id, auth }) =>
  async (dispatch) => {
    try {
      console.log(auth);
      const res = await postDataAPI(`like/${user}/${id}`, null, auth.token);
      console(`${user}`);
      /*       console.log(res);
      
       */
    } catch (error) {
      console.log(error);
    }
  };
export const unlikeProduct =
  ({ user, id, auth }) =>
  async (dispatch) => {
    try {
      const res = await postDataAPI(`unlike/${user}/${id}`, null, auth.token);
      console(`${user}`);
    } catch (error) {
      console.log(error);
    }
  };
export const checkPincode = (pin) => async (dispatch) => {
  try {
    dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
    console.log(pin);
    const res = await getDataAPI(`availability/${pin}`);
    // console.log(`availability/${pin}`);
    dispatch({
      type: PRODUCT_TYPES.AVAILABLEPRODUCT,
      payload: res.data.delivery_codes,
    });
    console.log("will get no response abhi");
    dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
  } catch (error) {
    console.log(error);
  }
};

export const getProduct = (data) => async (dispatch) => {
  try {
    dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });

    const res = await getDataAPI(`topProducts`);
    /*     console.log(res);
     */ dispatch({
      type: PRODUCT_TYPES.WHATSNEW,
      payload: res.data.product,
    });
    dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
  } catch (error) {
    console.log(error);
  }
};
// export const getAllProducts = (data) => async (dispatch) => {
//   try {
//     dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
//     const res = await getDataAPI(`allproducts`);
//     dispatch({
//       type: PRODUCT_TYPES.ALLPRODUCTS,
//       payload: res.data.result,
//     });
//     dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
//   } catch (error) {
//     console.log(error);
//   }
// };
export const getProductsByCategories = (data) => async (dispatch) => {
  try {
    console.log(data);
    dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
    const res = await getDataAPI(`bycategories`, null, {
      category: data.category,
      subCategory: data.subCategory,
      LastEvalVal: data.LastEvalVal,
    });
    dispatch({
      type: PRODUCT_TYPES.ALLPRODUCTS,
      payload: res.data,
    });
    dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
  } catch (error) {
    console.log(error);
  }
};
export const getUserFeed = (auth) => async (dispatch) => {
  try {
    console.log("auth", auth);
    dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
    const res = await getDataAPI(`feed`, auth.token);
    dispatch({
      type: PRODUCT_TYPES.ALLPRODUCTS,
      payload: res.data,
    });
    dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
  } catch (error) {
    console.log(error);
  }
};
export const getProductsBySearch = (data) => async (dispatch) => {
  try {
    dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
    const res = await getDataAPI(`productsearch`, null, {
      q: data.q,
      LastEvalVal: data.LastEvalVal,
    });

    dispatch({
      type: PRODUCT_TYPES.ALLPRODUCTS,
      payload: res.data,
    });
    dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
  } catch (error) {
    console.log(error);
  }
};
export const byProductId = (data) => async (dispatch) => {
  try {
    dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
    const res = await getDataAPI(`byproductid/${data}`);
    dispatch({
      type: PRODUCT_TYPES.BUYPRODUCT,
      payload: res.data.product,
    });
    dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
  } catch (error) {
    console.log(error);
  }
};
