import React from "react";

import "./Style.css";

const Playlist = () => {
  function sweaterClick() {
    window.open("/player", "_blank", "width=500,height=800");
  }
  return (
    <div className="maindiv" onClick={sweaterClick}>
      <div
        className="secondary_div"
        style={{ width: "60px", backgroundColor: "rgba(255, 255, 255, 0.5)" }}
      >
        <div className="container">
          <div className="everlib-logo">
            <i className="everlib-logo-first-bar"></i>
            <i className="everlib-logo-second-bar"></i>
            <i className="everlib-logo-third-bar"></i>
            <i className="everlib-logo-fourth-bar"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Playlist;
