import React, { useState, useEffect } from "react";
import { Button, makeStyles } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { follow, unfollow } from "../../redux/actions/profileAction";

const useStyles = makeStyles((theme) => ({
  btn: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  subHeadings: {
    fontSize: "1rem",
    "@media (max-width:660px)": {
      fontSize: "0.9rem",
    },
    "@media (max-width:540px)": {
      fontSize: "0.7rem",
    },
  },
  btnDesign: {
    width: "100%",
    marginTop: "5%",
    // paddingTop: "3%",
    all: "unset",
    // border: "1px solid #7F37EF",
    backgroundColor: "#C9BFFE",
    // justifyContent: "center",
    display: "flex",
    justifyContent: "center",
    // alignItems: "center",
    // alignContent: "center",
    alignItems: "center",
    padding: "1.5%",
    borderRadius: "7px",
    fontFamily: "'Poppins', sans-serif",
    color: "white",
    fontWeight: "600",
    "@media (max-width:480px)": {
      // display: "f",
      width: "90%",
    },
  },
}));

const Followbtn = ({ user }) => {
  const classes = useStyles();
  const [followed, setFollowed] = useState(false);
  const { auth, profile } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      auth.user &&
      auth.user.following.length &&
      auth.user.following.find((item) => item.username === user.username)
    ) {
      setFollowed(true);
    }
  }, [auth.user, profile.users]);
  const handlefollow = () => {
    if (auth.user) {
      setFollowed(true);
      dispatch(follow({ users: profile.users, user, auth }));
    }
  };
  const handleUnfollow = () => {
    if (auth.user) {
      setFollowed(false);
      dispatch(unfollow({ users: profile.users, user, auth }));
    }
  };

  return (
    <div className={`${classes.subHeadings} ${classes.btn}`}>
      {followed ? (
        <button
          // size="small"
          // color="primary"
          // variant="contained"
          // style={{ width: "100%" }}
          className={classes.btnDesign}
          onClick={handleUnfollow}
        >
          UNFOLLOW
        </button>
      ) : (
        <button
          // size="small"
          // color="black"
          // variant="outlined"
          // style={{ width: "100%", border: "1px solid #7F37EF" }}
          className={classes.btnDesign}
          onClick={handlefollow}
        >
          FOLLOW
        </button>
      )}
    </div>
  );
};

export default Followbtn;
