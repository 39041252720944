import React from "react";

const Toast = ({ msg, handleShow, bgColor }) => {
  return (
    <div>{console.log(msg.body)}</div>
    // <div className={`toast show position-fixed text-light ${bgColor}`}
    // style={{top: '5px', right: '5px', minWidth: '200px', zIndex: 1500}}>
    //     <div className={`toast-header text-light ${bgColor}`}>
    //         <strong className="mr-auto text-light">{msg.title}</strong>
    //         <button className="ml-2 mb-1 close text-light"
    //         data-dismiss="toast" style={{outline: 'black', background: 'black', boxShadow: "none", border: "none", padding: "2px", margin: "2px" }}
    //         onClick={handleShow}>
    //             &times;
    //         </button>
    //     </div>
    //     <div className="toast-body">
    //         {msg.body}
    //     </div>

    // </div>
  );
};

export default Toast;
