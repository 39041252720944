import React from "react";
import { makeStyles, useTheme } from "@material-ui/core";
import { width } from "dom-helpers";

import { useHistory } from "react-router-dom";
import ReactGA from "react-ga";

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundImage:
      "linear-gradient(to right bottom, #4458be, #3c4cb0, #343fa3, #2c3395, #232788)",
    height: "50vw",
    marginTop: "7%",
    display: "flex",
    justifyContent: "space-evenly",
  },
  mainTxt: {
    color: "#fffb00",
    textShadow: "5px 6px 10px  black",
    fontFamily: "'League Spartan', sans-serif;",
    fontSize: "8rem",
    lineHeight: "7rem",

    "@media (max-width:1000px)": {
      fontSize: "6.5rem",
      lineHeight: "5.5rem",
    },
    "@media (max-width:810px)": {
      fontSize: "5.5rem",
      lineHeight: "4.5rem",
    },
    "@media (max-width:700px)": {
      fontSize: "4.5rem",
      lineHeight: "3.5rem",
    },
    "@media (max-width:550px)": {
      fontSize: "3.5rem",
      lineHeight: "2.5rem",
    },
    "@media (max-width:500px)": {
      fontSize: "2.5rem",
      lineHeight: "2rem",
      textShadow: "3px 3px 5px  black",
    },
    "@media (max-width:380px)": {
      fontSize: "2rem",
      lineHeight: "1.7rem",
    },
    "@media (max-width:380px)": {
      fontSize: "1.5rem",
      lineHeight: "1.2rem",
    },
  },

  txtContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    transform: "translateX(20%)",
  },
  Btn: {
    backgroundColor: "white",
    padding: "10px",
    width: "200px",
    borderRadius: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "'League Spartan', sans-serif;",
    fontSize: "1.3rem",
    cursor: "pointer",

    marginTop: "8%",
    "@media (max-width:810px)": {
      fontSize: "1.1rem",
      width: "160px",
      marginTop: "5%",
    },
    "@media (max-width:550px)": {
      fontSize: "1rem",
      width: "120px",
      marginTop: "7%",
    },
    "@media (max-width:500px)": {
      fontSize: "0.7rem",
      width: "100px",
      marginTop: "8%",
    },
    "@media (max-width:500px)": {
      fontSize: "0.6rem",
      width: "80px",
      marginTop: "8%",
      padding: "7px",
    },
    "@media (max-width:380px)": {
      fontSize: "0.5rem",
      width: "70px",
      marginTop: "9%",
      padding: "5px",
    },
  },
  image: {
    height: "100%",
    width: "auto",
    // marginLeft: "20%",
  },
}));

const StudioOjas = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  function viewmore() {
    history.push("/profile/studio_ojs");
    ReactGA.event({ category: "homepage", action: "home page studio_ojs", label: "HomePage" });
  }
  return (
    <div className={classes.main}>
      <img
        src="https://res.cloudinary.com/ayshubham/image/upload/v1674912326/sarvhhomepage/WHAT_A_DRAG-2_zo44hi.png"
        alt=""
        className={classes.image}
      />

      <div className={classes.txtContainer}>
        <div className={classes.mainTxt}>bold,</div>
        <div className={classes.mainTxt}>smooth</div>
        <div className={classes.mainTxt}>& classy.</div>
        <div className={classes.Btn} onClick={viewmore}>
          view more
        </div>
      </div>
    </div>
  );
};

export default StudioOjas;
