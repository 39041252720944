import React from "react";
import { Button, makeStyles, Typography } from "@material-ui/core";
import {
  AiFillInstagram,
  AiFillFacebook,
  AiFillTwitterCircle,
  AiFillLinkedin,
} from "react-icons/ai";

import { Link, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  fotter: {
    backgroundColor: "rgb(255 124 124)",
    color: "white",
    marginTop: "20px",
    display: "grid",
    width: "100%",
    justifyContent: "space-around",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    padding: "3%",
    "@media (max-width:600px)": {
      gridTemplateColumns: "1fr 1fr",
      gap: "50px",
    },
    "@media (max-width:300px)": {
      gridTemplateColumns: "1fr",
      padding: "0",
      paddingLeft: "8%",
      paddingTop: "20px",
      paddingBottom: "20px",
      marginTop: "10px",
    },
  },
  icons: {
    color: "black",
    fontSize: "1.3rem",
    cursor: "pointer",
    "@media (max-width:750px)": {
      fontSize: "1.2rem",
    },
    "@media (max-width:450px)": {
      fontSize: "1.2rem",
    },
  },
  // footerMenu: {
  //   display: "flex",
  //   justifyContent: "space-between",
  //   padding: "1%",
  //   letterSpacing: "1px",
  //   // widows: "80%",
  //   width: "95%",
  //   fontFamily: "'Poppins', sans-serif;",
  //   "@media (max-width : 1080px)": {
  //     fontSize: "0.7rem",
  //   },
  //   "@media (max-width : 750px)": {
  //     fontSize: "0.7rem",
  //     width: "90%",
  //   },
  //   "@media (max-width : 600px)": {
  //     fontSize: "0.5rem",
  //     width: "100%",
  //   },
  //   "@media (max-width : 450px)": {
  //     fontSize: "0.4rem",
  //     width: "100%",
  //     // flexDirection: "column",
  //   },
  //   "@media (max-width : 410px)": {
  //     fontSize: "5px",
  //     width: "100%",
  //     // flexDirection: "column",
  //   },
  // },
  // footerMenuLi: {
  //   fontFamily: "'Poppins', sans-serif;",
  // },
  mainText: {
    color: "black",
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "600",
    paddingBottom: "10px",
    paddingTop: "10px",
    "@media (max-width:750px)": {
      fontSize: ".9rem",
    },
    "@media (max-width:450px)": {
      fontSize: ".7rem",
    },
    "@media (max-width:300px)": {
      fontSize: "1rem",
      paddingTop: "0px",
    },
  },
  subText: {
    color: "black",
    fontFamily: "'Poppins', sans-serif;",
    marginTop: "10px",
    marginBottom: "5px",
    fontWeight: "400",
    cursor: "pointer",
    "@media (max-width:750px)": {
      fontSize: ".9rem",
    },
    "@media (max-width:450px)": {
      fontSize: ".7rem",
    },
    "@media (max-width:300px)": {
      fontSize: ".9rem",
      marginBottom: "0px",
      marginTop: "5px",
    },
  },
  socialDiv: {
    display: "flex",
    justifyContent: "space-between",
    width: "90%",
  },
}));

const Footer = () => {
  const classes = useStyles();
  const history = useHistory();

  function aboutus() {
    window.location.href = "https://sarvh.netlify.app/#about";
  }
  function blog() {
    window.location.href = "https://sarvh.netlify.app/#blog";
  }

  function contactus() {
    history.push("/contactus");
  }

  function privacy() {
    window.location.href = "https://sarvh.netlify.app/registration/form/terms";
  }

  function sell() {
    window.location.href = "https://sarvh.netlify.app/registration";
  }
  function registration() {
    window.location.href = "https://sarvh.netlify.app/registration/form";
  }
  function instagram() {
    window.location.href = "https://www.instagram.com/sarvhofficial/";
  }
  function facebook() {
    window.location.href = "https://twitter.com/sarvhofficial";
  }
  function twitter() {
    window.location.href = "https://www.facebook.com/sarvhofficial";
  }
  function linkedin() {
    window.location.href = "https://www.linkedin.com/company/sarvhofficial/";
  }

  return (
    <div className={classes.fotter}>
      <div>
        <div className={classes.mainText}>COMPANY</div>
        <div className={classes.subText} onClick={aboutus}>
          ABOUT US
        </div>
        <div className={classes.subText} onClick={blog}>
          BLOG
        </div>
      </div>
      <div>
        <div className={classes.mainText}>HELP</div>
        <div className={classes.subText} onClick={contactus}>
          CONTACT US
        </div>
        <div className={classes.subText} onClick={privacy}>
          PRIVACY POLICY
        </div>
        <div className={classes.subText} onClick={privacy}>
          SHIPPING & RETURN POLICY
        </div>
        <div className={classes.subText} onClick={privacy}>
          TERMS AND CONDITION
        </div>
      </div>
      <div>
        <div className={classes.mainText}>SELL WITH US</div>
        <div className={classes.subText} onClick={sell}>
          SELL ON SARVH
        </div>
        <div className={classes.subText} onClick={registration}>
          REGISTRATION
        </div>
      </div>
      <div>
        <div className={classes.mainText}>CONNECT WITH US</div>
        <div className={classes.socialDiv}>
          <div className={classes.icons} onClick={instagram}>
            <AiFillInstagram />
          </div>
          <div className={classes.icons} onClick={twitter}>
            <AiFillTwitterCircle />
          </div>
          <div className={classes.icons} onClick={facebook}>
            <AiFillFacebook />
          </div>
          <div className={classes.icons} onClick={linkedin}>
            <AiFillLinkedin />
          </div>
        </div>
      </div>

      {/* <div className={classes.footerMenu}>
        <div className={classes.footerMenuLi}>About</div>
        <div className={classes.footerMenuLi}>Blog </div>
        <div className={classes.footerMenuLi}>Sell on Sarvh</div>
        <div className={classes.footerMenuLi}>Registration</div>
        <div className={classes.footerMenuLi}>Contact Us</div>
        <div className={classes.footerMenuLi}>Terms</div>
        <div className={classes.footerMenuLi}>Privacy</div>
        <div className={classes.footerMenuLi}>Shopping & Return</div>
        <div className={classes.footerMenuLi}>Connect with us</div>
      </div> */}
      {/* <div></div> */}
    </div>
  );
};

export default Footer;
