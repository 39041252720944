import React from "react";
import { makeStyles, useTheme } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  main: {
    // marginLeft: "5%",
    marginTop: "2%",
  },
  newMain: {
    display: "flex",
    gap: "5px",
    // width: "fit-content",
  },
  new: {
    fontFamily: " 'Poppins', sans-serif",
    fontSize: "2rem",
    fontWeight: "500",
    letterSpacing: "1.5px",
    // width: "fit-content",
    "@media (max-width:600px)": {
      fontSize: "1.2rem",
    },
    "@media (max-width:450px)": {
      fontSize: "0.7rem",
    },
  },
  newborder: {
    width: "50px",
    borderBottom: "2px solid black",
    height: "50%",
  },
  newArrivalsPostMain: {
    display: "flex",
    justifyContent: "space-between",
    paddingRight: "5%",
  },
  newArrivalImage: {
    width: "250px",
    "@media (max-width:1140px)": {
      width: "200px",
    },
    "@media (max-width:930px)": {
      width: "150px",
    },
    "@media (max-width:700px)": {
      width: "120px",
    },
    "@media (max-width:600px)": {
      width: "100px",
    },
    "@media (max-width:500px)": {
      width: "90px",
    },
    "@media (max-width:430px)": {
      width: "80px",
    },
    "@media (max-width:400px)": {
      width: "70px",
    },
    "@media (max-width:310px)": {
      width: "50px",
    },
  },
  mainHeading: {
    paddingBottom: "5%",
    paddingTop: "5%",
  },
  mainContainer: {
    backgroundColor: " rgba(255, 0, 0, 0.49)",
    padding: "5%",
    "@media (max-width:450px)": {
      padding: "3%",
    },
  },
}));

const NewArrivals = () => {
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.main}>
      {/* <div className={classes.mainHeading}>
        <div className={classes.newMain}>
          <div className={classes.new}> New</div>
          <div>
            <div className={classes.newborder}></div>
          </div>
        </div>
        <div className={classes.new}>Arrivals</div>
      </div> */}
      <div className={classes.mainContainer}>
        <div className={classes.newArrivalsPostMain}>
          <div className={classes.mainHeading}>
            <div className={classes.newMain}>
              <div className={classes.new}> JUST</div>
              {/* <div>
                <div className={classes.newborder}></div>
              </div> */}
            </div>
            <div className={classes.new}>CAME IN</div>
          </div>
          {/* <div
            onClick={() => {
              history.push(`/buyproduct/studio_ojs/2022-10-15:14:08:02:999/`);
            }}
            style={{ cursor: "pointer" }}
          >
            <img
              src="https://aws-sarvh-product-images-production.s3.ap-south-1.amazonaws.com/productImages_small/d559a864-44ad-43a4-86bb-0f35c8c0e4e0-j1.jpg"
              alt=""
              className={classes.newArrivalImage}

         
            />
          </div> */}
          <div
            onClick={() => {
              history.push(`/buyproduct/vibethehype/2023-01-27:03:48:02:470/`);
            }}
            style={{ cursor: "pointer" }}
          >
            <img
              src="https://res.cloudinary.com/ayshubham/image/upload/v1677496824/sarvhhomepage/Rectangle_369_pjrx4d.png"
              alt=""
              className={classes.newArrivalImage}
              // onClick={() => {
              //   history.push("/buyproduct/ayshubham/1660111595843/");
              // }}
            />
          </div>
          <div
            onClick={() => {
              history.push(`/buyproduct/smylco/2022-10-25:21:39:33:575/`);
            }}
            style={{ cursor: "pointer" }}
          >
            <img
              src="https://res.cloudinary.com/ayshubham/image/upload/v1674837268/sarvhhomepage/justCameIn2_v80wqm.png"
              alt=""
              className={classes.newArrivalImage}
              // onClick={() => {
              //   history.push("/buyproduct/ayshubham/1660111595843/");
              // }}
            />
          </div>

          <div
            onClick={() => {
              history.push(`/buyproduct/studio_ojs/2022-10-15:14:07:31:975/`);
            }}
            style={{ cursor: "pointer" }}
          >
            <img
              src="https://res.cloudinary.com/ayshubham/image/upload/v1674837272/sarvhhomepage/justCameIn3_ww2kbk.jpg"
              alt=""
              className={classes.newArrivalImage}
              // onClick={() => {
              //   history.push("/buyproduct/ayshubham/1660111595843/");
              // }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewArrivals;
