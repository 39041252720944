import React, { useState, useEffect } from "react";
import SendIcon from "@material-ui/icons/Send";
import { FaShareAlt } from "react-icons/fa";

export default function Linkshare() {
  const [copied, setCopied] = useState(false);

  function copy() {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
  }

  useEffect(() => {
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  }, [copied]);

  return (
    <div style={styles.div}>
      {!copied ? <SendIcon onClick={copy} /> : <div></div>}

      <div
        style={{
          fontFamily: "poppins, sens-serif",
          fontSize: "12px",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {!copied ? "" : "Copied!"}
      </div>
    </div>
  );
}

const styles = {
  button: {},
  div: {
    display: "flex",
    gap: "0.3em",
  },
};
