import { makeStyles } from "@material-ui/core";
import React from "react";
import ProductDisplayComponent from "../components/CategoriesPages/productDisplay";
import Footer from "../components/footer";
import { Navbar } from "../components/Navbar";
import ReactGA from "react-ga";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

const useStyles = makeStyles({
  centerAlign: {
    textAlign: "center",
    marginTop: 64,
    "@media (max-width:660px)": {
      marginTop: 128,
    },
  },
});

const CategoriesProduct = () => {
  const { auth } = useSelector((state) => state);

  useEffect(() => {
    const token = localStorage.getItem("firstLogin");
    if (token) {
      if (auth.token) {
        ReactGA.pageview({ path: "category-page", trackerNames: auth.user.username });
      } else {
        ReactGA.pageview({ path: "category-page" });
      }
    } else {
      ReactGA.pageview({ path: "category-page" });
    }
  }, [auth.token]);
  return (
    <div>
      <Navbar />
      <div>
        <Helmet>
          <title>SARVH</title>
        </Helmet>
        {/* <DrawerComponent /> */}
        <div>
          {/* <Typography className={classes.centerAlign} variant="h4">
            MEN
          </Typography> */}
        </div>
        <ProductDisplayComponent />
      </div>
    </div>
  );
};

export default CategoriesProduct;
