import { colors, MenuItem, TextField } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import imageCompression from "browser-image-compression";
import Swal from "sweetalert2";
import { uploadProduct } from "../../redux/actions/productAction";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import CroppingDisplay from "./CroppingDisplay";
import { palette } from "./ColorPalette";
import ColorPicker from "./ColorPicker";
import CategoriesComponent from "./CategoriesComponent";
const categoriesData = require("./categories.json");
const useStyles = makeStyles({
  root: {
    position: "relative",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    "@media (max-width:500px)": {
      flexDirection: "column",
    },
  },
  rootImage: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    "@media (max-width:500px)": {
      flexDirection: "column",
    },
  },
  formRoot: {
    padding: "5%",
  },
  deleteConformation: {
    display: (props) => (props.deleteD ? "block" : "none"),
    position: "absolute",
    top: "10%",
    left: "50%",
    transform: "translateX(-50%) translateY(-50%)",
    flexDirection: "column",
    width: "40%",
    "@media (max-width:500px)": {
      width: "100%",
    },
    height: "fit-content",
    zIndex: "6",
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    alignItems: "center",
    "& p": {
      textAlign: "center",
    },
  },
  deleteButtons: {
    position: "relative",
    top: 0,
    left: 0,
    width: "100%",
    height: "fit-content",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    "& button": {
      paddingLeft: "4%",
      paddingRight: "4%",
      backgroundColor: "#ffffff",
    },
  },
  background: {
    display: (props) => (props.deleteD ? "block" : "none"),
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "#999999",
    opacity: "50%",
    zIndex: "5",
  },

  defaultCropperContainer: {
    position: "relative",
    top: 0,
    left: 0,
    width: "400px",
    "@media (max-width:500px)": {
      width: "90%",
      left: "50%",
      transform: "translateX(-50%)",
    },
    padding: "5%",
    height: "450px",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #999999",
    "& h4": {
      color: "#999999",
    },
  },
  defaultImgCOntainer: {
    position: "relative",
    top: 0,
    left: 0,
    width: "60%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      width: "100%",
    },
  },
  croppingDisplay: {
    position: "relative",
    top: 0,
    left: 0,
    height: "450px",
    overflow: "hidden",
    width: "400px",
    marginRight: "5%",
  },
  uploadInfoContainer: {
    position: "relative",
    top: 0,
    left: 0,
    height: "100%",
    width: "50%",
    "@media (max-width:500px)": {
      width: "100%",
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  //styles for image display in the form side
  uploadInfoOrganize: {
    position: "relative",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    "@media (max-width:500px)": {
      width: "100%",
      flexDirection: "column-reverse",
    },
    justifyContent: "flex-start",
  },
  Heading: {
    fontFamily: "'Poppins', sans-serif",
    fontWeight: "600",
    fontSize: "1.2rem",
    marginTop: "5%",
    marginBottom: "1%",
    padding: "1%",
  },
  PDdivchild: {
    fontFamily: "'Poppins', sans-serif",
    fontSize: ".8rem",
    letterSpacing: "1px",
    color: "#508EF8",
  },
  line: {
    width: "100%",
    borderBottom: "1px solid black",
    marginTop: "5px",
  },
  inputBox: {
    width: "100%",
    border: "1px solid #333333",
    borderRadius: "5px",
    margin: "5px",
    minHeight: "25px",
    "&::placeholder": {
      padding: "5px",
    },
    padding: "5px",
  },
  imgContainer: {
    position: "relative",
    top: 0,
    left: "50%",
    transform: "translateX(-50%)",
    width: "60%",
    "@media (max-width:500px)": {
      width: "100%",
    },
    height: "fit-content",
    // "&:after":{
    //   display:'block',
    //   content:'" "',
    //   width:'100%',
    //   paddingTop:'100%'
    // }
  },

  imgsContainer: {
    position: "relative",
    top: 0,
    left: 0,
    width: "100%",
    height: "fit-content",
    display: "flex",
    flexDirection: "row",
    "@media (max-width:500px)": {
      flexDirection: "column",
      width: "100%",
    },
    justifyContent: "space-between",
  },
  subImages: {
    position: "relative",
    top: 0,
    left: 0,
    width: "30%",
    height: "fit-content",
    display: "flex",
    flexDirection: "column",
    "@media (max-width:500px)": {
      flexDirection: "row",
      width: "100%",
    },
    alignItems: "center",
    justifyContent: "space-around",
    border: 0,
    "& img": {
      width: "70%",
      padding: "5%",
      "@media (max-width:500px)": {
        width: "25%",
        padding: "3%",
      },
      height: "auto",
      "&:focus": {
        border: "1px solid black",
      },
      "&:hover": {
        border: "1px solid black",
      },
    },
  },
  mainImage: {
    position: "relative",
    top: 0,
    left: "50%",
    paddingTop: "10%",
    transform: "translateX(-50%) ",
    width: "70%",
    height: "fit-content",
    "& img": {
      position: "relative",
      top: 0,
      left: 0,
      width: "100%",
      height: "auto",
    },
  },

  // upload and edit button style starts here
  inputHeading: {
    fontFamily: "'Poppins', sans-serif",
    marginTop: "20px",
    marginLeft: "10px",
    fontWeight: "500",
  },
  buttonGroup: {
    position: "relative",
    top: 0,
    left: "50%",
    transform: "translateX(-50%)",
    marginTop: "1%",
    marginBottom: "1%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    height: "fit-content",
    alignItems: "center",
  },
  buttonTwoGroup: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
  },
  button: {
    position: "relative",
    top: 0,
    width: "fit-content",
    height: "fit-content",

    // border: "1px solid black",

    // padding: "2%",
    margin: "1%",
    "& input": {
      display: "none",
    },
    backgroundColor: "#C9BFFE",
    color: "black",
    fontFamily: "'Poppins', sans-serif",

    minHeight: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "2%",
    minWidth: "150px",
    fontWeight: "5Product Description00",
  },
  input: {
    position: "relative",
    width: "80%",
    left: "50%",
    transform: "translateX(-50%)",
    marginTop: "1%",
  },
  headings: {
    position: "relative",
    width: "fit-content",
    left: "50%",
    transform: "translateX(-50%)",
    marginTop: "3%",
  },
  marginTop: {
    marginTop: "3%",
  },
  saveButton: {
    position: "relative",
    left: "50%",
    transform: "translateX(-50%)",
    marginTop: "3%",
    width: "30%",
  },

  smallImagesContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gap: "10px",
    marginTop: "2%",
  },
  smallImages: {
    width: "100%",
  },

  inputContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  twoInputs: {
    width: "45%",
  },
  selectCategories: {
    width: "45%",
  },
  choosePhotos: {
    width: "50%",
  },
  choosingContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  selectedColor: {
    marginBottom: "30px",
    border: "1px solid",
    margin: "10px",
    width: "30px",
    height: "30px",
    borderRadius: "20px",
  },
});

const UploadInfoComponent = () => {
  const { auth } = useSelector((state) => state);
  const dispatch = useDispatch();
  const history = useHistory();

  const options = {
    maxSizeMB: 8,
    maxWidthOrHeight: 800,
    useWebWorker: true,
  };
  const options1 = {
    maxSizeMB: 1,
    maxWidthOrHeight: 400,
    useWebWorker: true,
  };
  const [values, setValues] = useState({
    productName: "",
    price: "",
    mrp: "",
    productDescription: "",
    productFeatures: "",
    category: "",
    subCategory: "",
    selectedFile: "",
    file: [],
    Image_small: [],
    stock: [],
  });

  let colorValue = [];

  /**
   * {
   *  "red": {
   *     xl: "12",
   *      L: "12",
   *
   *  },
   *
   *  "green": {
   *     xl: "12",
   *      L: "12",
   *
   *  },
   * }
   */

  const [cropperDisplay, setCropperDisplay] = useState(true);
  const [deleteD, setDeleteD] = useState(false);

  const classes = useStyles({ cropperDisplay, deleteD });

  const [images, setImages] = useState([]);

  const [images1, setImages1] = useState([]);
  const [currentImage, setCurrentImage] = useState({ src: "", index: "" });
  const [wearType, setWearType] = useState("");
  const [croppedImages, setCroppedImages] = useState([]);
  const [currentcroppedImage, setCurrentCroppedImage] = useState({
    src: croppedImages[0] ? croppedImages[0] : "",
    index: "",
  });
  const [currentImageIndex, setCurrentImageIndex] = useState("");

  useEffect(() => {
    categoriesData.categories.map((category) => {
      if (values.category == category.name) {
        category.subCategories.map((subCategory) => {
          if (values.subCategory == subCategory.name) {
            console.log(subCategory.commision);
          }
        });
      }
    });
  }, [values.category, values.subCategory]);

  useEffect(() => {
    let tempValues = values;
    tempValues.stock = tempValues.stock.map((item) => {
      item.stock = {};
      return item;
    });

    setValues(tempValues);
  }, [wearType]);

  useEffect(() => {
    let tempCroppedImages = [...croppedImages];
    if (currentcroppedImage.src) {
      if (currentImageIndex >= 0 && tempCroppedImages[currentImageIndex]) {
        // console.log(currentcroppedImage)
        tempCroppedImages[currentImageIndex] = {
          src: currentcroppedImage.src,
          fileName: currentcroppedImage.fileName,
          mimeType: currentcroppedImage.mimeType,
        };
      } else if (currentImageIndex >= 0) {
        tempCroppedImages.push({
          src: currentcroppedImage.src,
          fileName: currentcroppedImage.fileName,
          mimeType: currentcroppedImage.mimeType,
        });
      }
    }
    setCroppedImages(tempCroppedImages);
    /*  console.log(currentImageIndex); */
  }, [currentcroppedImage]);
  const HandleWearType = (e) => {
    setWearType(e.target.value);
  };
  const handleFormSubmit = async (e) => {
    // console.log(values.price, "fsdfs", values.mrp);
    e.preventDefault();
    values.file = croppedImages;
    var smallImages = [];
    for (let i = 0; i < values.file.length; i++) {
      // const imageFile = values.file[i]

      try {
        const TempBlobFile = await imageCompression.getFilefromDataUrl(
          values.file[i].src,
          values.file[i].fileName
        );
        console.log(TempBlobFile instanceof Blob, TempBlobFile, values.file[i]);
        const compressedFile1 = await imageCompression(TempBlobFile, options1);
        console.log(compressedFile1);
        console.log(compressedFile1 instanceof Blob);
        // smallImages.src.push(await imageCompression.getDataUrlFromFile(compressedFile1));
        // smallImages.push(values.file[i].fileName)
        await smallImages.push({
          src: await imageCompression.getDataUrlFromFile(compressedFile1),
          fileName: values.file[i].fileName,
        });
      } catch (error) {
        console.log(error);
      }
    }
    console.log(smallImages);
    values.Image_small = smallImages;

    if (values.file.length === 0) {
      Swal.fire({
        icon: "error",
        text: "Please select a file...",
      });
    } else if (values.file.length > 4) {
      Swal.fire({
        icon: "error",
        text: "You can select upto 4 files only...",
      });
    } else if (parseInt(values.mrp) < parseInt(values.price)) {
      Swal.fire({
        icon: "error",
        text: "You Price Should be less than MRP",
      });
    } else if (
      !values.productName ||
      !values.price ||
      !values.mrp ||
      !values.productDescription ||
      !values.productFeatures ||
      !values.category ||
      !values.subCategory
    ) {
      Swal.fire({
        icon: "error",
        text: "Please enter all the values",
      });
    } else {
      console.log(values);
      dispatch(uploadProduct(auth, values));
      history.push("/profile/" + auth.user.username);
    }
  };

  const handleFileUPload = async (e) => {
    var imagesArr = [...images];
    console.log(e.target.files[0], "handlefileupload1");
    if (e.target.files[0]) {
      var compressedFile;
      if (e.target.files[0].size > 300000) {
        compressedFile = await imageCompression(e.target.files[0], options);
        console.log("compressedFile: " + compressedFile);
      }

      const reader = new FileReader();
      if (e.target.files[0].size > 300000) {
        reader.readAsDataURL(compressedFile);
      } else {
        reader.readAsDataURL(e.target.files[0]);
      }

      reader.onloadend = async () => {
        console.log("reader.result: " + reader.result);
        await imagesArr.push({
          src: reader.result,
          fileName: e.target.files[0].name,
          mimeType: e.target.files[0].type,
        });

        await setImages(imagesArr);
        setCurrentImageIndex(imagesArr.length - 1);
        setCurrentImage({
          src: imagesArr[imagesArr.length - 1].src,
          index: imagesArr.length - 1,
          fileName: imagesArr[imagesArr.length - 1].fileName,
          mimeType: imagesArr[imagesArr.length - 1].mimeType,
        });
        setCropperDisplay(false);
        e.target.value = "";
      };
    }
  };
  const handleImageClick = (e, index) => {
    console.log("yha dekho", images, croppedImages);
    setCurrentImageIndex(index);
    setCropperDisplay(true);
    // setCurrentImage({src:images[index],index:index});
    setCurrentCroppedImage({
      src: croppedImages[index].src,
      index: index,
      fileName: croppedImages[index].fileName,
      mimeType: croppedImages[index].mimeType,
    });
  };
  const handleEditButton = () => {
    console.log(
      "here",
      images[currentImageIndex],
      currentImageIndex,
      images[currentImageIndex].fileName,
      images[currentImageIndex].mimeType
    );
    setCropperDisplay(false);
    setCurrentImage({
      src: images[currentImageIndex].src,
      index: currentImageIndex,
      fileName: images[currentImageIndex].fileName,
      mimeType: images[currentImageIndex].mimeType,
    });
  };
  const handleDeleteButton = () => {
    setDeleteD(true);
  };
  const handleDeleteYButton = () => {
    setDeleteD(false);
    if (images.length && croppedImages.length) {
      setCropperDisplay(true);
      setCroppedImages(croppedImages.filter((item, index) => index != currentImageIndex));
      setImages(images.filter((item, index) => index != currentImageIndex));
      setCurrentImage({ src: "", index: "", fileName: "", mimeType: "" });
      setCurrentImageIndex("");
      setCurrentCroppedImage({ src: "", index: "", fileName: "", mimeType: "" });
      console.log(images, croppedImages);
    }
  };
  const handleDeleteNButton = () => {
    setDeleteD(false);
  };

  let tempStock = values.stock;

  let obj = {
    color: [],
    quantity: "",
    colorCode: "",
  };
  let found;

  const quantityInputChange = (e, size, color) => {
    let tempValues = values;
    let found = false;
    tempValues.stock = tempValues.stock.map((item) => {
      if (item.color === color) {
        found = true;
        if (wearType === "Topwear") {
          let tempObj = {
            ...item.stock,
          };
          tempObj[size] = e.target.value;
          // setSizeQuantityTop(tempObj);
          item.stock = tempObj;
        }
        if (wearType === "Bottomwear") {
          let tempObj = {
            ...item.stock,
          };
          tempObj[size] = e.target.value;
          // setSizeQuantityBottom(tempObj);
          item.stock = tempObj;
        }
        if (wearType === "Footwear") {
          let tempObj = {
            ...item.stock,
          };
          tempObj[size] = e.target.value;
          // setSizeQuantityFoot(tempObj)
          item.stock = tempObj;
        }
        // return false;
      }
      return item;
    });

    // tempValues.stock = values.stock.map((item) => {
    //   if (item.color === color) {

    //   }
    //   return item;
    // });
    setValues(tempValues);
    console.log(tempValues);
  };
  // const addQuantity = (e, item) => {
  //   obj = {
  //     color: [item, colorValue],
  //     quantity: e.target.value,
  //     colorCode: colorValue,
  //   };
  //   found = tempStock.some(
  //     (itm) => itm.color.toString().toUpperCase() === item.toString().toUpperCase()
  //   );
  //   if (found) {
  //     tempStock.forEach((itm) => {
  //       if (itm.color.toString().toUpperCase() === item.toString().toUpperCase()) {
  //         itm.quantity = e.target.value;
  //       }
  //     });
  //   }
  //   setValues((values) => ({ ...values, stock: tempStock, colorValue }));
  // };

  return (
    <div className={classes.root}>
      <div className={classes.rootImage}>
        <div className={classes.background}></div>
        <div className={classes.deleteConformation}>
          <p> Are you sure you want to delete the image </p>
          <div className={classes.deleteButtons}>
            <button onClick={handleDeleteYButton} className={classes.button}>
              Yes
            </button>
            <button onClick={handleDeleteNButton} className={classes.button}>
              No
            </button>
          </div>
        </div>
        {console.log(croppedImages)}
        {cropperDisplay ? (
          <div className={classes.defaultCropperContainer}>
            <h4>Crop Section</h4>
            <div className={classes.defaultImgCOntainer}>
              {/* <img src="default_img.svg" alt="default IMage Icon" /> */}
            </div>
          </div>
        ) : (
          <CroppingDisplay
            cropperDisplay={cropperDisplay}
            currentImageIndex={currentImageIndex}
            setCurrentCroppedImage={setCurrentCroppedImage}
            image={currentImage.src}
            fileName={currentImage.fileName}
            mimeType={currentImage.mimeType}
            className={classes.croppingDisplay}
          />
        )}
        <div className={classes.uploadInfoContainer}>
          <div className={classes.uploadInfoOrganize}>
            <div className={classes.imgContainer}>
              <div className={classes.imgsContainer}>
                <div className={classes.subImages}>
                  {croppedImages.length == 0 ? (
                    <div className={classes.defaultImgList}>
                      <img src="default_min_img.svg" alt="default IMage Icon" />
                      <img src="default_min_img.svg" alt="default IMage Icon" />
                      <img src="default_min_img.svg" alt="default IMage Icon" />
                      <img src="default_min_img.svg" alt="default IMage Icon" />
                    </div>
                  ) : (
                    croppedImages.map((img, index) => (
                      <img src={img.src} onClick={(e) => handleImageClick(e, index)} />
                    ))
                  )}
                </div>
                <div className={classes.mainImage}>
                  {console.log(currentcroppedImage)}
                  {!currentcroppedImage.src ? (
                    <div style={{ width: "100%" }} className={classes.defaultImgCOntainer}>
                      <img src="default_img.svg" alt="default IMage Icon" />
                    </div>
                  ) : (
                    <img src={currentcroppedImage.src} />
                  )}
                </div>
              </div>
            </div>
            <div className={classes.buttonGroup}>
              <div className={classes.buttonTwoGroup}>
                <label className={classes.button}>
                  <input
                    type="file"
                    onChange={(e) =>
                      images.length <= 3
                        ? handleFileUPload(e)
                        : Swal.fire({ icon: "error", text: "You can select upto 4 files only..." })
                    }
                  />
                  Add an image
                </label>
                <label className={classes.button}>
                  <input type="button" onClick={() => handleEditButton()} />
                  Edit image
                </label>
              </div>

              <label className={classes.button}>
                <input type="button" onClick={() => handleDeleteButton()} />
                Delete image
              </label>
            </div>
          </div>
          {/* {console.log(values.price, values.mrp)} */}
        </div>
      </div>
      <form onSubmit={handleFormSubmit} className={classes.formRoot}>
        <div className={classes.Heading}>Product Detials</div>
        <div className={classes.PDdivchild}>
          ( Any column or feature not realted to your product just leave a dash “ --- “ or leave it
          blank. )
        </div>
        <div className={classes.line}></div>
        <div className={classes.inputHeading}>Product Name</div>
        <input
          className={classes.inputBox}
          required
          variant="filled"
          // placeholder="Write your product name here..."
          fullWidth
          label="Product Name"
          onChange={(e) => setValues({ ...values, productName: e.target.value })}
        />
        <div className={classes.choosingContainer}>
          <CategoriesComponent values={values} setValues={setValues} />
        </div>

        {/* <div className={classes.Heading} variant="h6">
          Pricing
        </div> */}
        <div className={classes.inputContainer}>
          <div style={{ width: "40%" }}>
            <div className={classes.Heading}>your price</div>
            <input
              className={classes.inputBox}
              required
              variant="filled"
              halfWidth
              // type="number"
              label="Your Price"
              onChange={(e) => setValues({ ...values, price: e.target.value })}
            />
          </div>
          <div style={{ width: "40%" }}>
            <div className={classes.Heading}>mrp</div>
            <input
              className={classes.inputBox}
              required
              variant="filled"
              halfWidth
              // type="number"
              label="MRP of the Product"
              onChange={(e) => setValues({ ...values, mrp: e.target.value })}
            />
          </div>
        </div>
        <Typography
          className={classes.headings}
          variant="h6"
          style={{ fontFamily: "'Poppins', sans-serif" }}
        >
          Product Information
        </Typography>
        <div>
          <div className={classes.Heading}>Product Description</div>
          <textarea
            className={classes.inputBox}
            style={{ fontFamily: " 'Poppins', sans-serif", padding: "1%" }}
            onChange={(e) => setValues({ ...values, productDescription: e.target.value })}
          />
        </div>

        <div>
          <div className={classes.Heading}>Product Features</div>
          <input
            className={classes.inputBox}
            required
            label="Product Features"
            multiline
            fullWidth
            rows={3}
            variant="filled"
            onChange={(e) => setValues({ ...values, productFeatures: e.target.value })}
          />
        </div>
        <Typography
          className={classes.headings}
          variant="h6"
          style={{ fontFamily: "'Poppins', sans-serif" }}
        >
          Stock Management
        </Typography>
        <div style={{ display: "flex", justifyContent: "center", padding: "3%" }}>
          <select
            onChange={(e) => {
              HandleWearType(e);
            }}
            style={{
              fontFamily: "'Poppins', sans-serif",
              width: "20%",
              display: "flex",
              justifySelf: "center",
            }}
          >
            <option value="Select" style={{ fontFamily: "'Poppins', sans-serif" }}>
              Select
            </option>
            <option value="Topwear" style={{ fontFamily: "'Poppins', sans-serif" }}>
              Topwear
            </option>
            <option value="Footwear" style={{ fontFamily: "'Poppins', sans-serif" }}>
              Footwear
            </option>
            <option value="Bottomwear" style={{ fontFamily: "'Poppins', sans-serif" }}>
              Bottomwear
            </option>
          </select>
        </div>
        <div style={{ justifyContent: "center" }}>
          <div className={classes.input} style={{ marginTop: "10px", marginBottom: "10px" }}>
            <ColorPicker val={values} setVal={setValues} />
          </div>
          {console.log(values)}

          <div
            className={classes.input}
            style={{ display: "flex", flexDirection: "column", flexWrap: "wrap" }}
          >
            {values.stock.map((item) => {
              palette.filter((itm) => {
                if (itm.name === item.color) {
                  colorValue = itm.color;
                }
              });
              return (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    className={classes.selectedColor}
                    style={{ backgroundColor: colorValue }}
                  ></div>

                  {console.log(wearType === "Topwear")}
                  {/* <input
                    className={classes.inputBox}
                    required
                    style={{ width: "75px", height: "5px" }}
                    label="Quantity"
                    onChange={(e) => {
                      addQuantity(e, item.color);
                    }}
                  /> */}
                  {wearType === "Topwear" ? (
                    <table>
                      <tr>
                        <th style={{ fontFamily: "'Poppins', sans-serif", padding: "10px" }}>
                          Sizes
                        </th>
                        <th style={{ fontFamily: "'Poppins', sans-serif" }}>quantity</th>
                      </tr>
                      <tr>
                        <td>XS</td>
                        <td>
                          <input
                            type="number"
                            onInput={(e) => {
                              quantityInputChange(e, "XS", item.color);
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>S</td>
                        <td>
                          <input
                            type="number"
                            onInput={(e) => {
                              quantityInputChange(e, "S", item.color);
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>L</td>
                        <td>
                          <input
                            type="number"
                            onInput={(e) => {
                              quantityInputChange(e, "L", item.color);
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>M</td>
                        <td>
                          <input
                            type="number"
                            onInput={(e) => {
                              quantityInputChange(e, "M", item.color);
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>XL</td>
                        <td>
                          <input
                            type="number"
                            onInput={(e) => {
                              quantityInputChange(e, "XL", item.color);
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>XXL</td>
                        <td>
                          <input
                            type="number"
                            onInput={(e) => {
                              quantityInputChange(e, "XXL", item.color);
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>XXXL</td>
                        <td>
                          <input
                            type="number"
                            onInput={(e) => {
                              quantityInputChange(e, "XXXL", item.colorL);
                            }}
                          />
                        </td>
                      </tr>
                    </table>
                  ) : (
                    <div></div>
                  )}

                  {wearType === "Bottomwear" ? (
                    <table>
                      <tr>
                        <tr>
                          <th style={{ fontFamily: "'Poppins', sans-serif", padding: "10px" }}>
                            Sizes
                          </th>
                          <th style={{ fontFamily: "'Poppins', sans-serif" }}>quantity</th>
                        </tr>
                      </tr>
                      <tr>
                        <td>26</td>
                        <td>
                          <input
                            type="number"
                            onInput={(e) => {
                              quantityInputChange(e, "26", item.color, item.color);
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>28</td>
                        <td>
                          <input
                            type="number"
                            onInput={(e) => {
                              quantityInputChange(e, "28", item.color);
                            }}
                          />
                        </td>
                      </tr>

                      <tr>
                        <td>30</td>
                        <td>
                          <input
                            type="number"
                            onInput={(e) => {
                              quantityInputChange(e, "30", item.color);
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>32</td>
                        <td>
                          <input
                            type="number"
                            onInput={(e) => {
                              quantityInputChange(e, "32", item.color);
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>34</td>
                        <td>
                          <input
                            type="number"
                            onInput={(e) => {
                              quantityInputChange(e, "34", item.color);
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>36</td>
                        <td>
                          <input
                            type="number"
                            onInput={(e) => {
                              quantityInputChange(e, "36", item.color);
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>38</td>
                        <td>
                          <input
                            type="number"
                            onInput={(e) => {
                              quantityInputChange(e, "38", item.color);
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>40</td>
                        <td>
                          <input
                            type="number"
                            onInput={(e) => {
                              quantityInputChange(e, "40", item.color);
                            }}
                          />
                        </td>
                      </tr>
                    </table>
                  ) : (
                    <div></div>
                  )}

                  {wearType === "Footwear" ? (
                    <table>
                      <tr>
                        <tr>
                          <th style={{ fontFamily: "'Poppins', sans-serif", padding: "10px" }}>
                            Sizes
                          </th>
                          <th style={{ fontFamily: "'Poppins', sans-serif" }}>quantity</th>
                        </tr>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>
                          <input
                            type="number"
                            onInput={(e) => {
                              quantityInputChange(e, "4", item.color);
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td>
                          <input
                            type="number"
                            onInput={(e) => {
                              quantityInputChange(e, "5", item.color);
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>6</td>
                        <td>
                          <input
                            type="number"
                            onInput={(e) => {
                              quantityInputChange(e, "6", item.color);
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>7</td>
                        <td>
                          <input
                            type="number"
                            onInput={(e) => {
                              quantityInputChange(e, "7", item.color);
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>8</td>
                        <td>
                          <input
                            type="number"
                            onInput={(e) => {
                              quantityInputChange(e, "8", item.color);
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>9</td>
                        <td>
                          <input
                            type="number"
                            onInput={(e) => {
                              quantityInputChange(e, "9", item.color);
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>10</td>
                        <td>
                          <input
                            type="number"
                            onInput={(e) => {
                              quantityInputChange(e, "10", item.color);
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>11</td>
                        <td>
                          <input
                            type="number"
                            onInput={(e) => {
                              quantityInputChange(e, "11", item.color);
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>12</td>
                        <td>
                          <input
                            type="number"
                            onInput={(e) => {
                              quantityInputChange(e, "12", item.color);
                            }}
                          />
                        </td>
                      </tr>
                    </table>
                  ) : (
                    <div></div>
                  )}
                </div>
              );
            })}
          </div>
        </div>

        <Button
          type="submit"
          className={`${classes.marginTop} ${classes.saveButton}`}
          variant="secondary"
        >
          Submit
        </Button>
      </form>
    </div>
  );
};

export default UploadInfoComponent;
