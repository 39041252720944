import React from "react";
import Address from "./Address";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navbar } from "../Navbar";
import { makeStyles } from "@material-ui/core";
import { PaytmButton } from "../paytm-button/paytmButton";
import { useHistory } from "react-router-dom";
import PaymentPage from "./PaymentPage";
import { Helmet } from "react-helmet";
import { getDataAPI } from "../../utils/fetchData";
const useStyles = makeStyles((theme) => ({
  rootmain: {
    height: "100vh",
    marginTop: "59px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "@media (max-width:550px)": {
      marginTop: "20px",
    },
  },
  main: {
    // marginTop: "1rem",
    height: "100%",
    width: "75%",
    "@media (max-width:550px)": {
      width: "100%",
    },
  },

  mainLogin: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    // borderRight: "1px solid black",
    justifyContent: "flex-end",
    "@media (max-width:550px)": {
      flexDirection: "column-reverse",
      width: "100vw",
      marginTop: "50px",
    },
  },

  paymentBtnM: {
    position: "fixed",
    width: "100vw",
    bottom: "0",
    left: "0",
    display: "none",
    "@media (max-width:550px)": {
      display: "block",
    },
  },
  address: {
    // width: "75%",
    height: "100%",
    marginTop: "1.4rem",
  },

  payment: {
    // borderLeft: "1px solid black",
    height: "100%",
    display: "flex",
    justifySelf: "center",
    position: "fixed",
    backgroundColor: "white",
    width: "25%",
    // width: "100vw",
    // justifyContent: "flex-end",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transform: "translateX(100%)",
    "@media (max-width:550px)": {
      display: "none",
    },
  },
  right: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },

  loginsignup: {
    width: "75%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    // height: "20vh",
  },
  random: {
    width: "70%",
    "@media (max-width:550px)": {
      width: "100%",
    },
  },
  circle: {
    backgroundColor: "#A771FF",
    borderRadius: "50%",
    padding: "5px",
    fontSize: "1.2rem",
    width: "3vw",
    height: "3vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  line: {
    borderBottom: "3px solid #252525",
    width: "75%",
    marginTop: "1.4rem",
  },
  logout_main: {
    width: "100vw",
  },
  logout_div: {
    backgroundColor: "white",
    height: "90vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "5%",
  },
  logout_btn: {
    backgroundColor: "white",
    border: "2px #A771FF solid",
    padding: "0.5rem",
    borderRadius: "0.5rem",
  },
  logout_text: {
    fontSize: "2rem",
  },
  container: {
    border: "1px solid black",
    height: "180px",
    width: "200px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "10px",
    padding: "10px",
    alignItems: "space-around",
  },
  containerM: {
    border: "1px solid black",
    height: "180px",
    width: "200px",
    display: "none",
    flexDirection: "column",
    justifyContent: "center",
    gap: "10px",
    padding: "10px",
    alignItems: "space-around",
    "@media (max-width:550px)": {
      display: "flex",
      width: "100vw",
    },
  },

  coupounMain: {
    backgroundColor: "black",
    width: "100%",
    display: "none",
    color: "white",
    "@media (max-width:550px)": {
      display: "flex",
      justifyContent: "center",
      zIndex: "12",
      position: "-webkit-sticky",
      top: "64px",
    },
  },
  coupounInputTxt: {
    zIndex: "100000",
    position: "-webkit-sticky",
  },
  mainContainer: {
    "@media (max-width:800px)": {
      marginRight: "65px",
    },
    "@media (max-width:550px)": {
      marginRight: 0,
    },
  },
  containerTxtDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  gotCuppon: {
    backgroundColor: "black",
    width: "100%",
  },
}));

const Checkout = () => {
  const { auth, alert } = useSelector((state) => state);
  const classes = useStyles();
  const history = useHistory();

  const deliveryPrice = localStorage.getItem("totalDelivery");
  const seller = localStorage.getItem("user");
  const [userData, setUserData] = useState([]);
  const [couponInput, setCuouponInput] = useState();
  const [price, setPrice] = useState();
  useEffect(() => {
    const token = localStorage.getItem("firstLogin");
    if (!token) {
      history.push("/");
    }
    console.log(alert, "137");
    const priceLocal = localStorage.getItem("totalAmount");
    setPrice(priceLocal);
  }, [auth, auth.token, alert]);

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await getDataAPI(`user/${seller}`, auth.token);
        setUserData(res.data.user);
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();

    setPrice(price - 1);
  }, [seller]);

  const handleCoupon = (e) => {
    setCuouponInput(e.target.value);
  };

  const handleCouponSubmit = () => {
    if (couponInput === "UANDSARVH4EVA") {
      console.log(userData);
      if (userData.coupon.discount.all) {
        console.log(userData);
      } else {
        // console.log(userData);
        if (price < 1000) {
          const discount = userData.coupon.discount.zero;
          console.log(typeof Number(price));
          const discountedPrice = (Number(price) * Number(discount)) / 100;
          console.log(discountedPrice);
          setPrice(price - discountedPrice);
          localStorage.removeItem("totalAmount");
          localStorage.setItem("totalAmount", price);

          // console.log(userData.coupon.discount.zero, "discount price");
          // setPrice(price - (price * discount) / 100);
        }
      }
    }
  };

  return (
    <div className={classes.rootmain}>
      <div>
        <Navbar></Navbar>
        <Helmet>
          <title>SARVH : PAYMENT</title>
        </Helmet>
      </div>

      {auth && JSON.stringify(auth) != "{}" ? (
        <div className={classes.main}>
          {auth.token ? (
            <div className={classes.mainLogin}>
              <div className={classes.right}>
                {/* <div className={classes.coupounMain}>
                  {userData.coupon.msg ? (
                    <div> Got cuppons ?{userData.coupon.msg}</div>
                  ) : (
                    <div>Got cuppons ?</div>
                  )}

                  <div className={classes.coupounInputTxt}>
                    <input type="text" onChange={handleCoupon} />
                    <button onClick={handleCouponSubmit}>redeme</button>
                  </div>
                </div> */}
                <div className={classes.containerM}>
                  <div className={classes.containerTxtDiv}>
                    <div style={{ fontFamily: "poppins, sans-serif" }}>Price</div>
                    <div style={{ fontFamily: "poppins, sans-serif" }}>₹ {price}</div>
                  </div>
                  <div className={classes.containerTxtDiv}>
                    <div style={{ fontFamily: "poppins, sans-serif" }}>Delivery Price</div>
                    <div style={{}}>
                      <div
                        style={{
                          color: "red",
                          fontFamily: "poppins, sans-serif",
                          fontWeight: "600",
                        }}
                      >
                        Free
                      </div>
                      <div
                        style={{
                          fontFamily: "poppins, sans-serif",
                          textDecoration: "line-through",
                        }}
                      >
                        {" "}
                        ₹ {deliveryPrice}
                      </div>
                    </div>
                  </div>
                  <div className={classes.containerTxtDiv}>
                    <div style={{ fontFamily: "poppins, sans-serif" }}>Total Price</div>
                    <div style={{ fontFamily: "poppins, sans-serif" }}>₹ {price}</div>
                    {/* <div style={{ fontFamily: "poppins, sans-serif" }}>
                      ₹ {Number(price) + Number(deliveryPrice)}
                    </div> */}
                  </div>
                </div>
                <div className={classes.random}>
                  <div className={classes.address}>
                    <div className={classes.loginsignup} style={{ width: "25%" }}></div>
                    <Address></Address>
                  </div>
                </div>

                <div className={classes.paymentBtnM}>
                  <PaytmButton price={price}></PaytmButton>
                </div>
              </div>

              <div className={classes.payment}>
                {/* <PaymentPage></PaymentPage> */}
                <div className={classes.mainContainer}>
                  <div className={classes.container}>
                    <div className={classes.containerTxtDiv}>
                      <div style={{ fontFamily: "poppins, sans-serif" }}>Price</div>
                      <div style={{ fontFamily: "poppins, sans-serif" }}>₹ {price}</div>
                    </div>
                    <div className={classes.containerTxtDiv}>
                      <div style={{ fontFamily: "poppins, sans-serif" }}>Delivery Price</div>
                      <div style={{}}>
                        <div
                          style={{
                            color: "red",
                            fontFamily: "poppins, sans-serif",
                            fontWeight: "600",
                          }}
                        >
                          Free
                        </div>
                        <div
                          style={{
                            fontFamily: "poppins, sans-serif",
                            textDecoration: "line-through",
                          }}
                        >
                          {" "}
                          ₹ {deliveryPrice}
                        </div>
                      </div>
                    </div>
                    <div className={classes.containerTxtDiv}>
                      <div style={{ fontFamily: "poppins, sans-serif" }}>Total Price</div>
                      <div style={{ fontFamily: "poppins, sans-serif" }}>₹ {price}</div>
                      {/* <div style={{ fontFamily: "poppins, sans-serif" }}>
                        ₹ {Number(price) + Number(deliveryPrice)}
                      </div> */}
                    </div>
                  </div>
                  <PaytmButton></PaytmButton>
                </div>
              </div>
            </div>
          ) : (
            <div className={classes.logout_main}>
              <div className={classes.logout_div}>
                <div className={classes.logout_text}>You Need To Login To Continue</div>
                <button
                  className={classes.logout_btn}
                  onClick={() => {
                    window.location.href = "/login";
                  }}
                >
                  Click Here To Login
                </button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default Checkout;
