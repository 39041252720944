import React from "react";
import { makeStyles, useTheme } from "@material-ui/core";
import { width } from "dom-helpers";

import { useHistory } from "react-router-dom";
import ReactGA from "react-ga";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontFamily: "poppins, sans-serif",
    marginLeft: "5%",
    fontSize: "2rem",
    marginTop: "5%",
    marginBottom: "3%",
    fontWeight: "500",
    "@media (max-width:500px)": {
      fontSize: "1.5rem",
    },
  },
  main: {
    backgroundColor: "rgba(254, 254, 6, 0.79)",
    display: "flex",
    flexDirection: "column",
  },
  secondaryContainer: {
    display: "flex",
    justifyContent: "center",
    padding: "1%",
  },
  image: {
    height: "auto",
    width: "30%",
    padding: "1%",
    cursor: "pointer",
  },
  // secondaryContainer: {
  //   display: "flex",
  // },
}));
const EyeCatcher = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  function image1() {
    history.push("/buyproduct/smylco/2023-00-21:15:21:52:961/");
    ReactGA.event({
      category: "Home Page product",
      action: "product 1 click",
      label: "HomePage",
    });
  }
  function image2() {
    history.push("/buyproduct/studio_ojs/2022-10-15:14:16:35:131/");
    ReactGA.event({
      category: "Home Page product",
      action: "product 1 click",
      label: "HomePage",
    });
  }
  function image3() {
    history.push("/buyproduct/projectamaterasu/2023-00-24:19:43:00:734/");
    ReactGA.event({
      category: "Home Page product",
      action: "product 1 click",
      label: "HomePage",
    });
  }
  function image4() {
    history.push("/buyproduct/smylco/2022-10-26:21:35:57:303/");
    ReactGA.event({
      category: "Home Page product",
      action: "product 1 click",
      label: "HomePage",
    });
  }
  function image5() {
    history.push("/buyproduct/smylco/2022-10-26:21:07:10:957/");
    ReactGA.event({
      category: "Home Page product",
      action: "product 1 click",
      label: "HomePage",
    });
  }
  function image6() {
    history.push("/buyproduct/vibethehype/2023-01-27:03:52:20:646/");
    ReactGA.event({
      category: "Home Page product",
      action: "product 1 click",
      label: "HomePage",
    });
  }

  return (
    <div>
      <div className={classes.heading}>EYE CATCHER</div>
      <div className={classes.main}>
        <div className={classes.secondaryContainer}>
          <img
            src="https://res.cloudinary.com/ayshubham/image/upload/v1674985479/sarvhhomepage/theeyecather_ht5ede.jpg"
            alt=""
            className={classes.image}
            onClick={image1}
          />

          <img
            src="https://res.cloudinary.com/ayshubham/image/upload/v1674985453/sarvhhomepage/DSC_1614-copy.jpg-2_uqavj3.webp"
            alt=""
            className={classes.image}
            onClick={image2}
          />

          <img
            src="https://res.cloudinary.com/ayshubham/image/upload/v1675175783/sarvhhomepage/Rectangle_810-2_stkex7.jpg"
            alt=""
            className={classes.image}
            onClick={image3}
          />
        </div>
        <div className={classes.secondaryContainer}>
          <img
            src="https://res.cloudinary.com/ayshubham/image/upload/v1675188602/sarvhhomepage/c39ff00e-74ea-4c6a-8686-c0ab78fb2e75-deadinside3_1_1-2_bcv1sr.png"
            alt=""
            className={classes.image}
            onClick={image4}
          />

          <img
            src="https://res.cloudinary.com/ayshubham/image/upload/v1675188384/sarvhhomepage/24246cf5-19af-4d67-bf82-e2e528e765c6-morning_2001_1-2_pjbydw.jpg"
            alt=""
            className={classes.image}
            onClick={image5}
          />

          <img
            src="https://res.cloudinary.com/ayshubham/image/upload/v1677497026/sarvhhomepage/Rectangle_814_xntiud.png"
            alt=""
            className={classes.image}
            onClick={image6}
          />
        </div>
      </div>
    </div>
  );
};

export default EyeCatcher;
