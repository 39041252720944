import { useTheme } from "@material-ui/styles";
import { useMediaQuery } from "@material-ui/core";
import CarouselComponent from "../caraousel";

function CarouselComponents() {
  const theme = useTheme();
  let match = useMediaQuery(theme.breakpoints.down(500));
  //aspect ratio for pc's and computers should be 80:21
  let items = [
    "https://res.cloudinary.com/ayshubham/image/upload/v1675430257/Discover_independent_brands_and_the_stories_behind_them._Find_your_vibe_your_style_with_us.-2_bnufyk.png",
    // "https://res.cloudinary.com/ayshubham/image/upload/v1646244198/3_yyfxcl.png",
  ];

  //aspect ratio for mobiles should be 90:79
  let items_M = ["https://res.cloudinary.com/ayshubham/image/upload/v1659363862/SARVH_dln5qq.png"];
  return match ? items_M : items;
}

const HomepageCarouselComponent = () => {
  return <CarouselComponent homeCarousel={true} items={CarouselComponents()} />;
};

export default HomepageCarouselComponent;
