import { makeStyles } from "@material-ui/styles";
import { MenuItem, TextField } from "@material-ui/core";
import { useState, useLayoutEffect } from "react";
const categories = require("./categories.json");

const useStyles = makeStyles({
  main: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  selectCategories: {
    width: "45%",
  },
  marginTop: {
    marginTop: "3%",
  },
});
const Commission = () => {
  const classes = useStyles();
  const [subCategories, setSubCategories] = useState([]);
  const [values, setValues] = useState({
    category: "",
    subcategory: "",
  });

  const categoryChange = (e) => {
    setValues({ ...values, category: e.target.value });
    categories.categories.map((category) => {
      if (e.target.value == category.name) {
        setSubCategories(category.subCategories);
      }
    });
  };
  // console.log(props);
  // let setValues = props.setValues;
  // let values = props.values;
  return (
    <div className={`${classes.main}`}>
      <TextField
        required
        halfWidth
        onChange={(e) => {
          categoryChange(e);
        }}
        value={values.category}
        label="Category"
        className={`${classes.selectCategories} ${classes.marginTop}`}
      >
        {categories.categories.map((category) => {
          return <MenuItem value={category.name}>{category.name}</MenuItem>;
        })}
      </TextField>
      <TextField
        required
        halfWidth
        onChange={(e) => setValues({ ...values, subCategory: e.target.value })}
        value={values.subCategory}
        label="Sub Category"
        className={`${classes.selectCategories} ${classes.marginTop}`}
      >
        {" "}
        {console.log(categories.categories[0])}
        {subCategories.map((subcategory) => {
          return <MenuItem value={subcategory.name}>{subcategory.name}</MenuItem>;
        })}
        {/*               <MenuItem value="Watch">Watch</MenuItem>
         */}{" "}
      </TextField>
    </div>
  );
};

export default Commission;
