import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import Signupimg from "../images/Signupimg.jpg";
import { register } from "../redux/actions/authAction";

import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { makeStyles } from "@material-ui/core";

import Swal from "sweetalert2";
import { validateEmail, validatePassword } from "../helper/validator";
import { Navbar } from "../components/Navbar";
import "./Signup.css";

const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: 20,
    marginBottom: 20,
    display: "block",
    width: "100%",
    border: "1px solid #333333",

    // '& > *': {
    //   margin: theme.spacing(1),
    // },
  },
  mainContainer: {
    width: "60%",
    [theme.breakpoints.down("sm")]: { width: "80%" },
    edit: {
      fontSize: 2,
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "2%",
  },
  btn: {
    position: "relative",
    top: "0",
    left: "50%",
    transform: "translateX(-50%)",
    marginTop: "5%",
    fontSize: "1.1rem",
    backgroundColor: "#C9BFFE",
    border: "none",
    width: "130px",
    height: "45px",
    color: "#333333",
    fontFamily: "'Poppins', sans-serif",
    fontWeight: "600",
  },
  imghere: {
    fontSize: "1rem",
    width: "auto",
    height: "100%",
  },
  welcomeTxt: {
    fontFamily: "'Poppins', sans-serif",
    fontWeight: "700",
    fontSize: "2rem",

    "@media (max-width:500px)": {
      fontSize: "1.5rem",
    },
  },
  paraTxt: {
    fontSize: ".8rem",
    fontFamily: "'Poppins', sans-serif",
    "@media (max-width:1080px)": {
      fontSize: ".9rem",
    },
    "@media (max-width:640px)": {
      fontSize: ".6rem",
    },
  },
  /*  inputRoot: {
    fontSize: 30
  }, */
  labelRoot: {
    /*  [theme.breakpoints.down("sm")]:
    {
      fontSize: 30,
    
    }, */
    /* color: "red",
     "&$labelFocused": {
      color: "purple"
    } */
  },
  labelFocused: {},
  mainDiv: {
    "@media (max-width:1080px)": {
      width: "100%",
    },
  },
}));

function Signup(props) {
  const { auth } = useSelector((state) => state);
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const [fullname, setFullname] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [gender, setGender] = useState();
  const [emailError, setEmailError] = useState(false);
  const [fullnameError, setFullnameError] = useState(false);

  useEffect(() => {
    if (auth.token) history.push("/");
  }, [auth.token, history]);
  const signUpForm = (e) => {
    e.preventDefault();
    if (fullname === "") {
      Swal.fire({
        icon: "error",
        title: "Invalid Name",
        text: "Please enter valid name",
      });
      setFullnameError(true);
    } else if (!validateEmail(email)) {
      setEmailError(true);
      Swal.fire({
        icon: "error",
        title: "Invalid Email...",
        text: "Please try again :(",
      });
    } else {
      // checking if password is same or not
      if (password !== confirmPassword) {
        Swal.fire({
          icon: "error",
          title: "Password doesn't match..",
          text: "Please try again :(",
        });
      } else {
        // if password is same
        if (!validatePassword(password)) {
          // validating password
          Swal.fire({
            icon: "error",
            title: "Invalid Password..",
            text: "Password should be minimum 4 characters long",
          });
        } else {
          const userData = {
            fullname,
            username,
            email,
            password,
            gender,
          };
          dispatch(register(userData));
        }
      }
    }
  };

  return (
    <div>
      <div className="main">
        <div className="imgbg">
          <img
            src={Signupimg}
            alt=""
            className="imghere"
            style={{ width: "auto", height: "100%" }}
          />
        </div>
        <div className={`mainlog ${classes.mainDiv}`}>
          <Navbar />
          <div className={classes.mainContainer}>
            <div className={classes.welcomeTxt}>WELCOME TO SARVH</div>
            <div className={classes.paraTxt}>
              Let’s get you all set up so you can DISCOVER.SELL.CONNECT !
            </div>
            <form noValidate autoComplete="off" onSubmit={signUpForm}>
              <div className="DetTxt">Your details</div>
              <input
                className="testing"
                required
                placeholder="Full Name"
                error={fullnameError}
                // helperText={text === "asdf" ? 'Empty field!' : ' '}
                onChange={(e) => setFullname(e.target.value)}
              />
              <input
                className="testing"
                placeholder="Email"
                required
                onChange={(e) => setEmail(e.target.value)}
                error={emailError}
              />
              <select
                className="testing"
                value={gender}
                placeholder="Gender"
                select
                fullWidth
                onChange={(e) => setGender(e.target.value)}
              >
                <option value={"male"}>Male</option>
                <option value={"female"}>Female</option>
                <option value={"Other"}>Other</option>
                {/* <option value={"Transgender"}>Transgender</option> */}
                {/* <option value={"Cisgender"}>Cisgender</option> */}
                {/* <option value={"Non-Binary"}>Non-Binary</option> */}
                {/* <option value={"Gender fluid"}>Gender fluid</option> */}
                {/* <option value={"Gender neutral"}>Gender neutral</option> */}
                <option value={"Prefer not to say"}>Prefer not to say</option>
              </select>

              <div className="DetTxt">Create your username and password</div>
              <input
                className="testing"
                placeholder="Username"
                onChange={(e) => setUsername(e.target.value)}
              />
              <div className="para">Password must have a least 4 characters</div>

              <input
                className="testing"
                placeholder="Password"
                required
                type="password"
                helperText="
                Minimum 8 characters:
                1 capital, 1 small, 1 special character allowed"
                onChange={(e) => setPassword(e.target.value)}
                style={{ marginTop: "0" }}
              />

              <input
                className="testing"
                placeholder="Conf Password"
                required
                type="password"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />

              <button
                type="submit"
                color="primary"
                size="large"
                variant="contained"
                className={classes.btn}
                endIcon={<KeyboardArrowRightIcon />}
              >
                SIGN UP
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
